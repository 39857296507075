import React, { useState, useEffect } from 'react';
// import { Link } from "react-router-dom";
// import "../auth/Auth.css";
import { Tooltip } from 'reactstrap';
import './Home.scss';
import 'react-toastify/dist/ReactToastify.css';
import SectionWrap from '../reuseableComponent/sectionWrap/SectionWrap';
// import { rupiahFormat } from '../../helper/HelperGeneral';
// import ButtonWithLoadingOrIcon from '../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon';
// import CustomSelectOption from '../reuseableComponent/customSelectOption/CustomSelectOption';
import OnBoarding from "./onBoarding/OnBoarding";
import ServiceProfile from '../../services/profile/ServiceProfile';
import ServiceOrganization from '../../services/organization/ServiceOrganization';
// import HelperCustomEncryptor from "../../helper/HelperCustomEncryptor";
import { checkID, doToast } from '../../helper/HelperGeneral';
import HelperCustomEncryptor from "../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../helper/HelperGetLocalStorage";
import HelperDate from '../../helper/HelperDate';
import { setBalanceData } from "../../redux/actions/reduxActionBalance";
import { setAuthActive } from '../../redux/actions/reduxActionAuth';
import FiAlertCircle from '../../assets/img/fi-question.svg';
import iconGlobe from '../../assets/img/icon-globe-home.svg';
import iconAt from '../../assets/img/icon-at.svg';
import iconTier from '../../assets/img/icon-tier-home.svg';
// import iconWallet from '../../assets/img/icon-wallet-home.svg';
import iconMau from '../../assets/img/usage/icon-mau.svg';
import iconTopic from '../../assets/img/usage/icon-topic-usage.svg';
import iconAgents from '../../assets/img/usage/icon-agents.svg';
import iconChannels from '../../assets/img/usage/icon-channels.svg';
// import ContentLoader from 'react-content-loader';
import { connect } from 'react-redux';
import mixpanel from "mixpanel-browser";
// import { FiPlus } from 'react-icons/fi';

// let topicList = [
//   {
//     label: '29 January - 1 February',
//     value: 1,
//   },
//   {
//     label: '29 December - 1 January 2019',
//     value: 2,
//   },
//   {
//     label: '29 November - 1 December 2019',
//     value: 3,
//   },
// ];

const ROLE_CODE = {
  owner: 'owner',
  member: 'member',
  admin: 'admin',
  all: 'all',
  custom: 'custom'
}

let Home = (props) => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);
  let [userData, setUserData] = useState(null);
  let [isShowOnboarding, setIsShowOnboarding] = useState(true);
  // let [datePeriod, setDatePeriod] = useState(topicList[1]);
  let [dataUsageVal, setDataUsageVal] = useState(null);
  let [showTooltipMau, setShowTooltipMau] = useState(false);
  // let [isWaitingProfileData, setIsWaitingProfileData] = letuseState(true);

  let doMount = async () => {
    // setUserData(HelperCustomEncryptor.doDecrypt(props.user.data));

    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      runGetProfile();
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) {
            window.location.href = '/organization/new';
          }

          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/home`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  let toogleTooltipmau = () => {
    setShowTooltipMau(!showTooltipMau);
  }

  useEffect(() => {
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

    if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
      mixpanel.track(
        "[Page] Home",
        {
          userFullName: myAgentData.account.fullName,
          userID: myAgentData.account.id,
          organizationID: props.match.params.orgID.split("-")[0],
        }
      );
    }

    doMount();
  }, [location.pathname]);

  useEffect(() => {
    if (orgID) {
      // let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
      // let memberShips = myAgentData.memberships;
      // console.log("memberShips", memberShips)
      // if(memberShips.length > 0) {
      //   let role = memberShips[memberShips.findIndex(val => val.organization.id === parseInt(orgID.split("-")[0]))].roleCode;

      //   if(role === ROLE_CODE.member) {
      //     setIsShowOnboarding(false);
      //   }
      // }

      runGetBalance();
    }
  }, [orgID]);

  useEffect(() => {
    if (userData) {
      if (getActiveOrganization().roleCode === "admin" || getActiveOrganization().roleCode === "owner" || getActiveOrganization().roleCode === "custom") {
        if (getActiveOrganization().roleCode === "custom") {
          if (props.allowedModule.findIndex(v => v === "usage") !== -1) {
            getCurrentUsage();
          }
        } else {
          getCurrentUsage();
        }
      }
    }
  }, [userData])

  // let setUserDataWithCallback = (data) => {
  //   setUserData(data);
  // }

  let findMyRole = () => {
    let role = "";

    if (userData) {
      role = userData.memberships[userData.memberships.findIndex(val => val.organization.id === parseInt(orgID.split("-")[0]))].roleCode;
    }


    return role;
  }

  let runGetBalance = () => {
    ServiceOrganization.postGetBalance(orgID, (response) => {
      if (response.dataResult.error.message === "") {
        let responseData = response.dataResult.data.balances;
        props.setBalanceData(responseData)

      } else {
        console.log(response.dataResult.error.message);
      }
    })
  }

  let runGetProfile = () => {
    // setIsWaitingProfileData(true);
    // let getUrlOrgID = () => {
    //   let matchParam = props.match.params.orgID;

    //   return matchParam.split("-")[0];
    // }

    ServiceProfile.postGetProfile((response) => {
      // setIsWaitingProfileData(false);

      if (response.dataResult.error.message === '') {
        let resultData = response.dataResult.data;

        // let currentUserData = HelperCustomEncryptor.doDecrypt(props.user.data);

        // let indexActiveOrganization = resultData.memberships.findIndex(value => value.organization.id === parseInt(getUrlOrgID()));

        // currentUserData.account = resultData.account;
        // currentUserData.memberships = resultData.memberships;
        // currentUserData.canCreateOrganization = resultData.canCreateOrganization;
        // currentUserData.activeOrganization = resultData.memberships[indexActiveOrganization].organization;

        // setUserDataWithCallback(currentUserData);
        let memberShips = resultData.memberships;

        if (memberShips.length > 0) {
          let role = memberShips[memberShips.findIndex(val => val.organization.id === parseInt(props.match.params.orgID.split("-")[0]))].roleCode;

          if (role === ROLE_CODE.member) {
            setIsShowOnboarding(false);
          }
        }

        setUserData(resultData);
      } else {
        doToast(response.dataResult.error.message, 'fail');
      }
    });
  };

  let getActiveOrganization = () => {
    let getUrlOrgID = () => {
      let matchParam = props.match.params.orgID;

      return matchParam.split('-')[0];
    };

    let indexActiveOrganization = userData.memberships.findIndex(
      (value) => value.organization.id === parseInt(getUrlOrgID())
    );

    if (indexActiveOrganization === -1) {
      window.location.href = '/home';
    } else {
      return userData.memberships[indexActiveOrganization];
    }
  };

  let getCurrentUsage = () => {

    ServiceOrganization.postGetCurrentUsage(props.match.params.orgID, (res) => {
      let dataRes = res.dataResult;

      if (dataRes.error.message === "") {
        setDataUsageVal(dataRes.data);
      } else {
        doToast(dataRes.error.message, "fail");
      }
    })
  };

  let dataUsage = [
    {
      img: iconMau,
      title: 'MAU',
      desc: '(Monthly Active Users)',
      key: "mau"
    },
    {
      img: iconTopic,
      title: 'Topics',
      key: "topic"
    },
    {
      img: iconAgents,
      title: 'Agents',
      key: "agent"
    },
    {
      img: iconChannels,
      title: 'Channels',
      key: "channel"
    },
  ];

  // let onSelectTopic = (value) => {
  //   setDatePeriod(value);
  // };

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsLoadingUsage(false);
  //   }, 3000);
  // }, [isLoadingUsage]);

  return (
    <>
      <SectionWrap {...props} orgID={orgID} withHeader withSideMenu>
        {isShowOnboarding &&
          <OnBoarding {...props} userData={userData} />
        }

        <div className="sectionWrap section-home">
          {/* {isWaitingProfileData ? */}
          <div className='home-welcome-wrapper'>
            <p className="home-welcome-message">
              <b>
                Hello {userData !== null && userData.account.fullName}, ready to
                serve your customers?
              </b>
            </p>

            <div className="home-info-wrapper">
              <div className="home-info-content">
                <div className="home-info-image">
                  <img src={iconGlobe} alt="" />
                </div>

                <div className="home-info-detail">
                  <b className="home-info-detail-title">ORGANIZATION</b>
                  <br />
                  <p className="home-info-detail-value">
                    <b>{userData !== null && getActiveOrganization().organization.name}</b>
                  </p>
                </div>
              </div>

              <div className="home-info-content">
                <div className="home-info-image">
                  <img src={iconAt} alt="" />
                </div>

                <div className="home-info-detail">
                  <b className="home-info-detail-title">ACCOUNT</b>
                  <br />
                  <p className="home-info-detail-value">
                    <b>{userData !== null && userData.account.email}</b>
                    {userData !== null && getActiveOrganization().memberAlias !== "" ?
                      <>
                        <p className='home-info-alias'>
                          ({getActiveOrganization().memberAlias})
                        </p>
                      </>
                      :
                      ""
                    }
                  </p>
                </div>
              </div>

              {userData !== null &&
                ((getActiveOrganization().roleCode === "admin" || getActiveOrganization().roleCode === "owner") &&
                  <>
                    <div className="home-info-content">
                      <div className="home-info-image">
                        <img src={iconTier} alt="" />
                      </div>

                      <div className="home-info-detail">
                        <b className="home-info-detail-title">TIER</b>
                        <br />
                        <p className="home-info-detail-value">
                          <b>{userData !== null && props.currentTierDetailProps.name}</b>
                        </p>
                      </div>
                    </div>

                    {/* <div className="home-info-content">
                      <div className="home-info-image">
                        <img src={iconWallet} alt="" />
                      </div>

                      <div className="home-info-detail">
                        <b className="home-info-detail-title">CURRENT BALANCE</b>
                        <br />
                        <p className="home-info-detail-value">
                          <b>{userData !== null && (props.balanceData ? (props.balanceData.length === 0 ? "Rp 0" : (props.balanceData[0].amount ? rupiahFormat(props.balanceData[0].amount) : "Rp 0")) : "Rp 0")}</b>
                        </p>

                        <Link to={`/o/${orgID}/organization/top-up`}>
                          <ButtonWithLoadingOrIcon 
                            text="Top Up"
                            icon={{
                              type: "svg",
                              src: FiPlus
                            }}
                            position="left"
                            className="no-fill-button main-button-40"
                          />
                        </Link>
                      </div>
                    </div> */}
                  </>
                )
              }
            </div>
          </div>
        </div>

        {/* <div className="sectionWrap section-member-status">
          <div className='section-member-status-list'>
            <div className='online-status-badge' />
            <b>12 Members</b>

            <p>
              <b>ONLINE</b>
            </p>
          </div>

          <div className='section-member-status-list'>
            <div className='away-status-badge' />
            <b>12 Members</b>

            <p>
              <b>AWAY</b>
            </p>
          </div>

          <div
            className='see-more-status'
            onClick={() => {
              props.history.push({
                pathname: `/o/${orgID}/organization/members`,
                state: {
                  tab: "member_status"
                }
              })
            }}
          >
            <b>See More <FiArrowRight /></b>
          </div>
        </div> */}

        {userData &&
          <>
            {(findMyRole() === "admin" || findMyRole() === "owner" || findMyRole() === "custom") &&
              <>
                {(findMyRole() === "custom" && props.allowedModule.findIndex(v => v === "usage") === -1) ?
                  ""
                  :
                  <div className="sectionWrap section-home">
                    <div className="usage-header">
                      <p className="usage-title">
                        {!dataUsageVal ?
                          <b>Current Usage</b>
                          :
                          (
                            dataUsageVal.currentPeriod.startDate === "" ?
                              <b>Current Usage</b>
                              :
                              <b>Current Usage {!dataUsageVal ? "" : `(${HelperDate.formatToString(new Date(dataUsageVal.currentPeriod.startDate), "d MMM yyyy")} - ${HelperDate.formatToString(new Date(dataUsageVal.currentPeriod.endDate), "d MMM yyyy")})`}</b>
                          )
                        }
                      </p>
                      {/* <CustomSelectOption
                      optionListProps={topicList}
                      valueDropdownProps={datePeriod}
                      placeholderProps={'Select date periode'}
                      onClickDropDownListOptionProps={onSelectTopic}
                      specificClassNameProps="custom-select-telegram-topic"
                    /> */}
                    </div>

                    <div className="usage-info-wrapper">
                      {dataUsage.map((item, id) => {
                        return (
                          <div className="home-usage-wrapper" key={id}>
                            <img className="usage-icon" src={item.img} alt="" />
                            <p className="usage-title">
                              <b>
                                {item.title} <span>{id === 0 ? item.desc : ''} {item.key === "mau" ? <img src={FiAlertCircle} alt="" id="TooltipMau" /> : ""}</span>
                              </b>

                              {/* {item.key === "mau" && */}
                              <Tooltip
                                flip
                                target="TooltipMau"
                                isOpen={showTooltipMau}
                                placement="bottom"
                                toggle={toogleTooltipmau}
                                className="tooltip-mau"
                              >
                                Last updated at {dataUsageVal ? HelperDate.formatToString(new Date(dataUsageVal["mau"].updatedTime), "dd/MM/yyyy HH:mm") : ""}
                              </Tooltip>
                              {/* } */}
                            </p>

                            {(!dataUsageVal) ?
                              <>
                                <p className='data-loading data-loading-1 onetalk-shine'>

                                </p>
                                <p className='data-loading taplive-shine'>

                                </p>
                              </>
                              :
                              <>
                                {dataUsageVal[item.key].isError ?
                                  <>
                                    <p className='data-not-available data-not-available-1'>
                                      COULD NOT
                                    </p>
                                    <p className='data-not-available'>
                                      GET DATA
                                    </p>
                                  </>
                                  :
                                  (dataUsageVal[item.key].hasData ?
                                    <>
                                      <p className="usage-value">
                                        <b>{dataUsageVal[item.key].usage}</b>
                                        {/* {isLoadingUsage ? (
                                    <ContentLoader viewBox="0 0 200 30">
                                      <rect
                                        x="0"
                                        y="0"
                                        rx="4"
                                        ry="4"
                                        width="172"
                                        height="40"
                                      />
                                    </ContentLoader>
                                  ) : (
                                    <b>{item.value}</b>
                                  )} */}
                                      </p>
                                      <p className="usage-per">
                                        {/* {isLoadingUsage ? (
                                    <ContentLoader viewBox="0 0 200 30">
                                      <rect
                                        x="0"
                                        y="0"
                                        rx="4"
                                        ry="4"
                                        width="86"
                                        height="24"
                                      />
                                    </ContentLoader>
                                  ) : (
                                    <b>{item.per}</b>
                                  )} */}
                                        <b>/ {dataUsageVal[item.key].limit === -1 ? "unlimited" : (dataUsageVal[item.key].limit === 0 ? "-" : dataUsageVal[item.key].limit)}</b>
                                      </p>
                                    </>
                                    :
                                    <>
                                      <p className='data-not-available data-not-available-1'>
                                        DATA NOT
                                      </p>
                                      <p className='data-not-available'>
                                        AVAILABLE YET
                                      </p>
                                    </>
                                  )
                                }
                              </>
                            }
                          </div>
                        );
                      })}
                    </div>
                  </div>
                }
              </>
            }
          </>
        }
      </SectionWrap>
    </>
  );
};

let mapStateToProps = (state) => ({
  user: state.user,
  balanceData: state.balanceData,
  allowedModule: state.allowedModule
});

let mapDispatchToProps = {
  setAuthActive,
  setBalanceData
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);