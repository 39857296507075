import BaseService from "../BaseService";
import { API_KEY } from "../../config";

const SlaServices = {
    getSla(idOrTime, callback) {
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Organization-ID": idOrTime
        };

        BaseService.doPostAccessToken(API.get, {}, headers, callback);
    },

    setSla(idOrTime, data, callback) {
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Organization-ID": idOrTime
        };

        BaseService.doPostAccessToken(API.set, data, headers, callback);
    },
};

const API = {
    set: "/organization/setup/sla_configs/set",
    get: "/organization/setup/sla_configs/get",
}


export default SlaServices;
