import React from "react";
import "./SourceDocument.scss";
import { connect } from 'react-redux';
import { FiUpload, FiUploadCloud } from "react-icons/fi";
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";

const SourceDocument = (props) => {

    const onFileSelected = (file) => {
        props.onFileSelected(file);
    }

    const onSelectDocument = (e) => {
        let files = e.target.files;
        if (files && files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                onFileSelected(files[i]);
            }
        }
    }

    const handleDropFile = (e) => {
        e.preventDefault();
        let files = e.dataTransfer.files;
        if (files && files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                onFileSelected(files[i]);
            }
        }
    }

    return (
        <div 
            className="source-document-wrapper"
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => { handleDropFile(e) }}
        >
            <input 
                type="file" 
                className="force-hide" 
                id="source-document-input" 
                multiple={true}
                onChange={(e) => onSelectDocument(e) }
            />
            <div className="source-document-content-wrapper">
                <FiUploadCloud className="icon-cloud" />

                <br />

                <span className="chat-room-input-email-placeholder">
                    Drop your documents here
                    <br />
                    or press Select Documents button below
                </span>

                <ButtonWithLoadingOrIcon
                    className="no-fill-button main-button-40"
                    position="left"
                    icon={{
                        type: "svg",
                        src: FiUpload
                    }}
                    text="Select Documents"
                    onClickAction={() => {
                        let el = document.querySelector("#source-document-input");
                        if (el) {
                            el.click();
                            el.value = null;
                        }
                    }}
                />
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(SourceDocument)
