import React from "react";
import "./PopUpErrorUpload.scss";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import ButtonWithLoadingOrIcon from "../buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { FiAlertCircle, FiFile } from "react-icons/fi";

let PopUpErrorUpload = (props) => {
  let {
    isOpen,
    toggle,
    className,
    title,
    description,
    errorFiles,
    buttonText,
    buttonIcon,
    buttonAction,
    buttonClass,
    secondButtonText,
    secondButtonIcon,
    secondButtonAction,
    secondButtonClass
  } = props;

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className={`modal-error ${className}`}
      >
        <ModalBody>
          <div className="error-icon">
            <FiAlertCircle />
          </div>
          <b className="error-title"> {title}</b>
          <p className="error-description" dangerouslySetInnerHTML={{__html: description}} />

          {
            (errorFiles && errorFiles.length > 0) &&
            <div className="error-list-wrapper">
              {
                errorFiles.map((value) => {
                  const fileNameWithoutExtension = value.name.replace(/\.[^/.]+$/, "");
                  const fileExtension = value.name.includes(".") ? value.name.split(".")[value.name.split(".").length - 1] : "";
                  return (
                    <div className="error-list">
                      <FiFile />
                      <div className="error-list-text-container">
                        <div className="error-list-name">{fileNameWithoutExtension}</div>
                        { 
                          fileExtension &&
                          <div className="error-list-extension">.{fileExtension}</div>
                        }
                      </div>                                             
                    </div>
                  )
                })
              }
            </div>
          }
        </ModalBody>

        {
          (buttonText || secondButtonText) &&
          <ModalFooter>
            {
              buttonText &&
              <ButtonWithLoadingOrIcon
                text={buttonText}
                position="left"
                icon={ buttonIcon ? {
                  type: "svg",
                  src: buttonIcon
                } : null}
                className={buttonClass ? buttonClass : "orange-button main-button-48"}
                onClickAction={buttonAction}
              />
            }
            {
              secondButtonText &&
              <ButtonWithLoadingOrIcon 
                text={secondButtonText}
                position="left"
                icon={ secondButtonIcon ? {
                  type: "svg",
                  src: secondButtonIcon
                } : null}
                className={secondButtonClass ? secondButtonClass : "no-fill-button main-button-48"}
                onClickAction={secondButtonAction}
              />
            }
          </ModalFooter>
        }
      </Modal>
    </div>
  )
}

export default PopUpErrorUpload;
