import React, { useState, useEffect } from 'react';
import './ChatRoomMessageOutWabaTemplateFile.scss';
import { Modal, ModalBody } from "reactstrap";
import CheckMarkDoubleWhite from '../../../../assets/img/chatroom/icon-double-check-white.svg';
import CheckMarkDoubleDark from '../../../../assets/img/chatroom/icon-double-check-dark.svg';
import CheckMarkDark from '../../../../assets/img/chatroom/icon-check-dark.svg';
import AirplaneDark from '../../../../assets/img/chatroom/icon-airplane-dark.svg';
import { MdInsertDriveFile } from 'react-icons/md';
import { FiDownload, FiUpload, FiInfo } from 'react-icons/fi';
import Helper from '../../../../helper/HelperChat';
import { printLastMessageName, isOtherAgent } from '../../../../helper/HelperGeneral';
import { tapCoreChatRoomManager } from '@taptalk.io/web-sdk';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ChatRoomReplyOutMessage from "../chatRoomReplyOutMessage/chatRoomReplyOutMessage/ChatRoomReplyOutMessage";
import { connect } from "react-redux";
import MessageAction from '../../../reuseableComponent/messageAction/MessageAction';

const ROLE_LIST = {
    agent: {
        value: "agent",
        label: "Agent"
    },
    organization: {
        value: "organization",
        label: "Organization"
    },
    chatbot: {
        value: "chatbot",
        label: "Chatbot"
    }
}

var ChatRoomMessageOutWabaTemplateFile = (props) => {
    let [percentageDownload, setPercentageDownload] = useState(0);
    let [bytesDownload, setBytesDownload] = useState(0);
    let [fileSrc, setFileSrc] = useState('');
    let [isFileExistInDB, setIsFileExistInDB] = useState(false);
    let [onFileDownloadProgress, setOnFileDownloadProgress] = useState(false);
    let [fileExtension, setFileExtension] = useState("");
    let [fileFromUrl, setFileFromUrl] = useState(false);
    let [modalInfo, setModalInfo] = useState(false)

    let {
        singleChatDataProps,
        activeUserID,
        status,
        onReplyMessage,
        onForwardMessage,
        caseData
    } = props;

    useEffect(() => {
        let isUnmounted = false;
        let splitFileName = singleChatDataProps.data.fileName ? singleChatDataProps.data.fileName.split(".") : singleChatDataProps.body.split('.');

        if (singleChatDataProps.data.url) {
            setFileFromUrl(true);
            setFileSrc(singleChatDataProps.data.url);
        } else if (singleChatDataProps.data.fileURL) {
            if (singleChatDataProps.data.fileURL !== "") {
                setFileFromUrl(true);
                setFileSrc(singleChatDataProps.data.fileURL);
            } else {
                setFileFromUrl(false);

                if (singleChatDataProps.data.fileID) {
                    tapCoreChatRoomManager.getFileFromDB(singleChatDataProps.data.fileID, function (data) {
                        if (data) {
                            if (!isUnmounted) {
                                setFileSrc(`data:${data.type};base64,${data.file}`);
                                setIsFileExistInDB(true);
                            }
                        } else {
                            if (!isUnmounted) {
                                setIsFileExistInDB(false);
                            }
                        }
                    })
                }
            }
        }

        if (!isUnmounted) {
            setFileExtension(splitFileName[splitFileName.length - 1].toUpperCase());
        }

        return () => {
            isUnmounted = true;
        }
    }, [singleChatDataProps])

    let downloadFile = () => {
        setOnFileDownloadProgress(true);

        tapCoreChatRoomManager.downloadMessageFile(singleChatDataProps, {
            onSuccess: (data) => {
                setOnFileDownloadProgress(false);
                setFileSrc(`data:${data.contentType};base64,${data.base64}`);
                setIsFileExistInDB(true);
            },

            onProgress: (message, percentage, bytes) => {
                setPercentageDownload(percentage);
                setBytesDownload(Helper.bytesToSize(bytes));
            },

            onError: (errorCode, errorMessage) => {
                setOnFileDownloadProgress(false);
                console.log(errorCode, errorMessage);
            }
        })
    }

    // let getFileBase64 = () => {
    //     tapCoreChatRoomManager.getFileFromDB(singleChatDataProps.data.fileID, function(data) {
    //         if(data) {
    //             setFileSrc(`data:${data.type};base64,${data.file}`);
    //             setIsFileExistInDB(true);
    //         }else {
    //             setIsFileExistInDB(false);
    //         }
    //     })
    // }

    // let deleteMessageAction = (message) => {
    //     let messageIDs = [];
    //     messageIDs.push(message.messageID);
    //     tapCoreMessageManager.markMessageAsDeleted(message.room.roomID, messageIDs, true);
    // }

    // let messageActionView = (message) => {
    //     let onClickReply = () => {
    //         onReplyMessage(message)
    //     }

    //     return (
    //         <div 
    //             className={`message-action-wrapper with-forward`}
    //         >
    //             {/* <div className="message-action-button-wrapper" title="Copy to clipboard" onClick={() => Helper.copyToClipBoard(message.body)}>
    //                 <FiCopy />
    //             </div> */}

    //             <div className="message-action-button-wrapper reply-button" title="Reply" onClick={onClickReply}>
    //                 <FaReply />
    //             </div>

    //             <div className="message-action-button-wrapper reply-button forward-button" title="Forward" onClick={() => onForwardMessage(message)}>
    //                 <FaReply />
    //             </div>

    //             {/*<div className="message-action-button-wrapper forward-message" title="Forward">
    //                 <FaReply />
    //             </div> */}


    //             {/* <div className="message-action-button-wrapper" title="Delete" onClick={() => deleteMessageAction(message)}>
    //                 <FiTrash2 />
    //             </div> */}
    //         </div>
    //     )
    // }

    //   let renderChatStatus = (message) => {
    //     let messageStatus;

    //     if(message.isSending) {
    //         messageStatus = "sending";
    //     }

    //     if(!message.isSending && message.isDelivered) {
    //         messageStatus = "sent";
    //     }

    //     if(!message.isSending && message.isDelivered && !message.isRead) {
    //         messageStatus = "receive";
    //     }

    //     if(message.isRead) {
    //         messageStatus = "read";
    //     }

    //     if(!message.isDelivered) {
    //         messageStatus = "not delivered";
    //     }

    //     switch(messageStatus) {
    //         case "sending":
    //             return AirplaneDark;
    //         case "sent":
    //             return CheckMarkDark;
    //         case "receive":
    //             return CheckMarkDoubleDark;
    //         case "read":
    //             return CheckMarkDoubleWhite;
    //         case "not delivered":
    //             return CheckMarkDark;
    //     }
    //   }

    // let downloadFileToStorage = (file, fileName) => {
    //     fetch(file)
    //         .then(resp => resp.blob())
    //         .then(blob => {
    //             const url = window.URL.createObjectURL(blob);
    //             const a = document.createElement('a');
    //             const fileID = `file-${new Date().valueOf()}`;

    //             a.style.display = 'none';
    //             a.href = url;
    //             a.id = fileID;

    //             // the filename you want
    //             a.download = fileName;

    //             document.body.appendChild(a);
    //             a.click();
    //             window.URL.revokeObjectURL(url);
    //             a.remove();
    //         });
    // }

    let clickBubble = () => {
        if (isFileExistInDB || fileFromUrl) {
            window.open(fileSrc, "_blank")
        }
    }

    return (
        <div className="chat-room-message-file-out-wrapper" id={`message-${singleChatDataProps.localID}`}>
            <Modal
                className="modal-templated-message"
                isOpen={modalInfo}
            >
                <ModalBody>
                    <div>
                        <FiInfo />
                        <b>This is a templated message</b>
                    </div>
                    <p>
                        Templated messages are messages that has been saved and approved by WhatsApp officially and cannot be altered.
                    </p>

                    <button
                        className="orange-button main-button-40"
                        onClick={() => setModalInfo(false)}
                    >
                        Dismiss
                    </button>
                </ModalBody>
            </Modal>

            <div className={`message-out-bubble-file ${isOtherAgent(singleChatDataProps.user.xcUserID.replace("agent:", ""), props.myAgentData) ? "message-bubble-blue" : ""} ${singleChatDataProps.user.userRole.code === "chatbot" || singleChatDataProps.user.userRole.code === "organization" ? "message-bubble-organization" : ""}`}>
                <div className="click-area-file" onClick={clickBubble}>
                    {/* <div className="replied-file-message">
                        <div className="file-icon-wrapper">
                            <MdInsertDriveFile />
                        </div>

                        <div className="file-detail-wrapper">
                            <div className="filename-wrapper">
                                <b>file.docx</b>
                            </div>
                            285 KB
                        </div>
                    </div> */}

                    {/* {forwarded &&
                        <div className="forwarded-message">
                            <b>Forwarded</b>
                            <br />
                            From: <b>Keju Manis</b>
                        </div>
                    } */}

                    {/* {singleChatDataProps.replyTo.localID !== "" &&
                        <div className="reply-message reply-file">
                            {singleChatDataProps.quote.fileType !== "" &&  
                                <div className="reply-file-thumbnail">
                                    {singleChatDataProps.quote.fileType === "file" ? 
                                        <MdInsertDriveFile />
                                        :
                                        <img src={''} alt="" />
                                    }
                                </div>
                            }

                            <div className="reply-text-wrapper">
                                <p className="reply-from">
                                    <b> 
                                        {singleChatDataProps.replyTo.userID === taptalk.getTaptalkActiveUser().userID ?
                                            "You"
                                            :
                                            singleChatDataProps.replyTo.fullname
                                        }
                                    </b>
                                </p>
                                <p className="reply-text">{singleChatDataProps.quote.content}</p>
                            </div>
                        </div>
                    }  */}

                    {(isOtherAgent(singleChatDataProps.user.xcUserID.replace("agent:", ""), props.myAgentData) || (singleChatDataProps.user.userRole.code === "chatbot" || singleChatDataProps.user.userRole.code === "organization")) &&
                        <p className="group-sender-name-wrapper">
                            <b>
                                {singleChatDataProps.user.userRole.code === ROLE_LIST.agent.value &&
                                    <span className="agent-span">{ROLE_LIST.agent.label} - </span>
                                }

                                {singleChatDataProps.user.userRole.code === ROLE_LIST.organization.value &&
                                    <span className="agent-span">{ROLE_LIST.organization.label} - </span>
                                }

                                {singleChatDataProps.user.userRole.code === ROLE_LIST.chatbot.value &&
                                    <span className="agent-span">{ROLE_LIST.chatbot.label} - </span>
                                }

                                {printLastMessageName(singleChatDataProps, caseData, true)}
                            </b>
                        </p>
                    }

                    {singleChatDataProps.replyTo.localID !== "" &&
                        <ChatRoomReplyOutMessage
                            message={singleChatDataProps}
                            activeUserID={activeUserID}
                        />
                    }

                    <div className="file-inner-wrapper">
                        <div className="file-icon-wrapper">
                            {/* {status === 'onprogress' ?
                                <div className="onprocess-wrapper">
                                    <MdClose />
                                </div>

                                :

                                status === 'success' ?
                                    <MdInsertDriveFile />
                                    :
                                    <MdRefresh />
                            }   */}

                            {((!isFileExistInDB && !onFileDownloadProgress && !fileFromUrl) && singleChatDataProps.percentageUpload === undefined) &&
                                <div className="icon-status-wrapper">
                                    <FiDownload onClick={() => downloadFile()} />
                                </div>
                            }

                            {(onFileDownloadProgress) &&
                                <div className="icon-status-wrapper">
                                    <div className="onprocess-wrapper">
                                        <CircularProgressbar value={percentageDownload} />
                                        {/* <MdClose onClick={() => console.log('cancel download')} /> */}
                                        <FiDownload />
                                    </div>
                                </div>
                            }

                            {singleChatDataProps.bytesUpload !== undefined &&
                                <div className="icon-status-wrapper">
                                    <div className="onprocess-wrapper">
                                        <CircularProgressbar value={singleChatDataProps.percentageUpload} />
                                        {/* <MdClose onClick={() => console.log('cancel upload')} /> */}
                                        <FiUpload />
                                    </div>
                                </div>
                            }

                            {(isFileExistInDB || fileFromUrl) &&
                                // <a href={fileSrc} target="_blank" download={singleChatDataProps.data.fileName}>
                                // <MdInsertDriveFile onClick={() => downloadFileToStorage(fileFromUrl ? singleChatDataProps.data.url : fileSrc, singleChatDataProps.data.fileName ? singleChatDataProps.data.fileName : singleChatDataProps.body)} /> 

                                // <a href={fileSrc} target="_blank" rel="noopener noreferrer">
                                <MdInsertDriveFile />
                                // </a>
                            }
                        </div>

                        <div className="message-bubble-file-wrapper">
                            <p>
                                <b>
                                    {singleChatDataProps.data.fileName ?
                                        singleChatDataProps.data.fileName.split('.').slice(0, -1).join('.').replace("📎 ", "")
                                        :
                                        singleChatDataProps.body.replace(fileExtension.toLowerCase(), '').replace('.', '').replace("📎 ", "")
                                    }
                                </b>
                            </p>

                            <div className={`file-size-and-extension ${(onFileDownloadProgress || singleChatDataProps.bytesUpload) ? 'file-size-and-extension-download-progress' : ''}`}>
                                {onFileDownloadProgress && `${bytesDownload} / `}

                                {singleChatDataProps.bytesUpload !== undefined && `${Helper.bytesToSize(singleChatDataProps.bytesUpload)} / `}{singleChatDataProps.data.size !== 0 && Helper.bytesToSize(singleChatDataProps.data.size)}{" " + fileExtension}
                            </div>
                        </div>
                    </div>

                    {(singleChatDataProps.data.caption && singleChatDataProps.data.caption !== '') &&
                        <p className="caption-text" dangerouslySetInnerHTML={{ __html: Helper.lineBreakChatRoom(singleChatDataProps.data.caption) }} />
                    }

                    <p className="message-info">
                        {Helper.getDateMonthYear(singleChatDataProps.created)} <span className="centered-dot" /> {Helper.getHourMinute(singleChatDataProps.created)}

                        {(singleChatDataProps.isSending && !singleChatDataProps.isRead) &&
                            <img src={AirplaneDark} alt="" />
                        }

                        {(!singleChatDataProps.isSending && !singleChatDataProps.isDelivered && !singleChatDataProps.isRead) &&
                            <img src={CheckMarkDark} alt="" />
                        }

                        {(!singleChatDataProps.isSending && singleChatDataProps.isDelivered && !singleChatDataProps.isRead) &&
                            <img src={CheckMarkDoubleDark} alt="" />
                        }

                        {singleChatDataProps.isRead &&
                            <img src={CheckMarkDoubleWhite} alt="" />
                        }
                    </p>

                </div>

                {/* {messageActionView(singleChatDataProps)} */}
                <MessageAction
                    singleChatDataProps={singleChatDataProps}
                    _className="message-action-dropdown-right"
                    replyAction={() => onReplyMessage(singleChatDataProps)}
                    forwardAction={() => onForwardMessage(singleChatDataProps)}
                    hideCopy
                />

                {status === 'fail' &&
                    <React.Fragment>
                        <br />
                        <b className="failed-sending-file-warning">Failed to send tap to retry</b>
                    </React.Fragment>
                }


                <div className="message-out-template-banner" onClick={() => { console.log("clicked"); setModalInfo(true) }}>
                    <FiInfo />
                    {/* <div> */}
                    <p>This is a templated message</p>
                    {/* </div> */}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    myAgentData: state.myAgentData
});

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomMessageOutWabaTemplateFile);