import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { NavLink } from 'react-router-dom';
import "./Sidemenu.scss";
import Helper from '../../../helper/HelperChat';
import { SIDEMENU_MAP } from '../../../constants/taplive';
import { doToast } from '../../../helper/HelperGeneral';
import { setParentMenuBlockActive } from '../../../redux/actions/reduxActionSideMenu';
import { setBalanceData } from "../../../redux/actions/reduxActionBalance";
import { setLogout } from "../../../redux/actions/reduxLogout";
import { setAuthActive } from "../../../redux/actions/reduxActionAuth";
import { setMembershipData } from "../../../redux/actions/reduxActionMembership";
import { setAllowedModule } from "../../../redux/actions/reduxActionAllowedModule";
import { connect } from 'react-redux';
// import ServiceOrganization from "../../../services/sorganization/ServiceOrganization";
import ServiceAuthAccessToken from "../../../services/auth/ServiceAuthAccessToken";
import caseServices from '../../../services/chat/caseServices';
import ServiceProfile from '../../../services/profile/ServiceProfile';
import ServiceOrganization from '../../../services/organization/ServiceOrganization';
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import { taptalk } from '@taptalk.io/web-sdk';
import { IoIosCheckmark, IoIosAdd } from 'react-icons/io'; //IoIosArrowForward
import iconHomeNew from '../../../assets/img/sidemenu-icon/new/home.svg';
import iconOrganizationNew from '../../../assets/img/sidemenu-icon/new/organization.svg';
// import iconTechnicalNew from '../../../assets/img/sidemenu-icon/new/technical.svg';
import iconIntegrationNew from '../../../assets/img/sidemenu-icon/new/integration.svg';
import iconInboxNew from '../../../assets/img/sidemenu-icon/new/inbox.svg';
// import iconQuickReplyNew from '../../../assets/img/sidemenu-icon/new/quick-reply.svg';
import iconOperational from '../../../assets/img/sidemenu-icon/new/operational.svg';
import iconSwitchOrganization from '../../../assets/img/sidemenu-icon/new/switch-organization.svg';
import iconSetup from '../../../assets/img/sidemenu-icon/new/setup.svg';
import iconReport from '../../../assets/img/sidemenu-icon/new/report.svg';
import iconAI from '../../../assets/img/sidemenu-icon/new/ai.svg';
import mixpanel from "mixpanel-browser";
import SubMenuIntegration from './SubmenuIntegration';
// import { numberWithCommas, rupiahFormat } from '../../../helper/HelperGeneral';

const ROLE_CODE = {
  owner: 'owner',
  member: 'member',
  admin: 'admin',
  all: 'all',
  custom: 'custom'
}

const style = {
  menuArrow: {
    position: "absolute",
    right: "16px",
    top: "50%",
    transform: "translate(0, -50%)"
  },
  borderBottom: {
    borderBottom: "solid 1px #e87200"
  },
  scrollStyle: {
    position: "relative",
    backgroundColor: "rgb(199,98,0)",
    right: "-5px",
    borderRadius: "8px",
    width: "4px"
  }
};

class Sidemenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isWaitForResponseLogout: false,
      showPopupOrganization: false,
      userData: null,
      submenuList: null,
      isActiveChannels: false,
      hideMenuData: [],
      parentMenuList: {
        home: true,
        integration: false,
        operational: false,
        organization: false,
        setup: false,
        report: false,
        inbox: false
      }
    }
  }

  componentDidMount() {
    let initTaplive = () => {
      var elTapliveScript = document.createElement('script')
      elTapliveScript.id = "script-taplive"
      elTapliveScript.type = "text/javascript"
      elTapliveScript.onload = () => {
        if (window.tapliveHelper) {
          window.tapliveHelper.tapliveToggleLoadingScreen()
        }
        if (window.TapTalkLive) {
          window.TapTalkLive.init(process.env.REACT_APP_TAPLIVE_KEY, true);

          let runReqLiveChatAuthTicket = () => {
            caseServices.postRequestLivechatAuthTicket(this.props.match.params.orgID, (response) => {
              if (response.dataResult.error.message !== "") {
                console.error(response.dataResult.error.message);
              } else {
                window.TapTalkLive.authenticateWithAuthTicket(response.dataResult.data.ticket, () => {
                  console.log("Launcher ready");
                }, true);
              }
            })
          };

          if (!window.TapTalkLive.isAuthenticated()) {
            runReqLiveChatAuthTicket();
          } else {
            window.TapTalkLive.launcherLogout(() => {
              runReqLiveChatAuthTicket();
            })
          }
        }
      }
      elTapliveScript.src = process.env.REACT_APP_TAPLIVE_URL
      elTapliveScript.async = true
      elTapliveScript.defer = true
      var elTapliveScriptPosition = document.getElementsByTagName('script')[0]
      elTapliveScriptPosition.parentNode.insertBefore(elTapliveScript, elTapliveScriptPosition)
    }

    initTaplive();

    let bodyClick = document.querySelector('body');

    let _this = this;

    bodyClick.addEventListener('click', function () {
      _this.setState({
        showPopupOrganization: false
      })
    });

    // this.setState({
    //   userData: { ...HelperCustomEncryptor.doDecrypt(this.props.user.data) }
    // })

    this.runGetProfile();
    if (process.env.REACT_APP_HIDE_MENU_URL !== "") {
      this.runGetHideMenu();
    }
  }

  componentDidUpdate({ location: prevLocation }) {
    const { location: { pathname } } = this.props

    if (pathname.includes("/integration")) {
      if (
        !pathname.includes("/integration/channels") &&
        !pathname.includes("/integration/api-keys") &&
        !pathname.includes("/integration/salestalk") &&
        !pathname.includes("/integration/chatbots") &&
        !pathname.includes("/integration/webhooks") &&
        !this.state.isActiveChannels
      ) {
        this.setState({
          isActiveChannels: true
        })
      }

      if (pathname.includes("/integration/channels") && this.state.isActiveChannels) {
        this.setState({
          isActiveChannels: false
        })
      }
    }

    if (prevLocation.pathname !== this.props.location.pathname) {
      this.runGetProfile();
    }
  }

  runGetHideMenu = () => {
    ServiceOrganization.getHideMenu((res) => {
      this.setState({
        hideMenuData: res.data
      })
    })
  }

  runGetProfile = () => {
    let _user = { ...HelperCustomEncryptor.doDecrypt(this.props.user.data) };

    ServiceProfile.postGetProfile((response) => {
      if (response.dataResult.error.message === '') {
        let resultData = response.dataResult.data;
        _user.account = response.dataResult.data.account;
        this.props.setAuthActive({
          data: HelperCustomEncryptor.doEncrypt(_user)
        });

        // if(this.state.userData) {
        let _newData = resultData

        let _myData = _newData.memberships[this.findOrganization(_newData)];

        // parentMenuList
        let _parentMenuList = { ...this.state.parentMenuList };

        if (_myData) {
          if (_myData.roleCode === "custom") {
            let findIndexModule = (module) => {
              let idx = -1;

              idx = _myData.allowedModules.findIndex(v => v === module);

              return idx;
            }

            Object.keys(_parentMenuList).map(v => {
              if (v === "inbox") {
                if (findIndexModule(v) !== -1) {
                  _parentMenuList[v] = true;
                }
              } else {
                SIDEMENU_MAP[v].map(_v => {
                  // if(v === "integration") {
                  //   let idxChannel = findIndexModule("channel");
                  //   let isMediumExist = _myData.allowedModules.join().includes("channel:");

                  //   if(idxChannel === -1 && isMediumExist) {
                  //     SIDEMENU_MAP["integration"].map(item => {
                  //       if(findIndexModule(item) !== -1) {
                  //         _myData.allowedModules.push("channel");
                  //       }

                  //       return null;
                  //     })
                  //   }
                  // }

                  if (findIndexModule(_v) !== -1) {
                    _parentMenuList[v] = true;
                  }

                  // let isModuleChild = _v.include(":");

                  // if(isModuleChild) {
                  //   if(_myData.allowedModules.join().includes(`${_v.split(":")[0]}`)) {
                  //     _parentMenuList[v] = 
                  //   }
                  // }

                  return null;
                })
              }


              return null;
            })

            _parentMenuList["setup"] = true;

            this.setState({
              parentMenuList: _parentMenuList
            })

            this.props.setAllowedModule(_myData.allowedModules)
          }

          this.setState({
            userData: _newData
          })

          this.props.setMembershipData(_newData);
        } else {
          this.props.history.push(`/home`)
        }

        // }
      } else {
        doToast(response.dataResult.error.message, 'fail');
      }
    });
  };

  isActiveOrganizationCheck = (orgID) => {
    if (this.props.activeOrgID) {
      let matchParam = this.props.activeOrgID;

      return matchParam.split("-")[0] === orgID.toString();
    }
  }

  initiateAccordionMenu(menu) {
    this.props.setParentMenuBlockActive({
      activeMenuBlock: this.props.sideMenu.activeMenuBlock === menu ? "" : menu
    });
  }

  runLogoutAction = () => {
    if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
      mixpanel.reset();
    }

    this.setState({
      isWaitForResponseLogout: true
    });

    let userData = HelperGetLocalStorage.getLocalStorageData("user");

    if (userData) {
      if (taptalk.isAuthenticated()) {
        taptalk.logoutAndClearAllTapTalkData({
          onSuccess: (response) => {
            console.log(response)
          }
        });
      }

      let accessToken = HelperCustomEncryptor.doDecrypt(userData.data).accessToken;
      ServiceAuthAccessToken.postLogout(accessToken).then(response => {
        if (response.dataResult.error.code !== "") {
          this.setState({
            isWaitForResponseLogout: false
          });
        } else {
          console.log(response.dataResult.error.message);
        }
      });

      this.props.setLogout();
      localStorage.clear();
      setTimeout(() => {
        window.location.href = window.location.origin + "/";
      }, 250);
    } else {
      window.location.href = window.location.origin + "/";
    }
  }

  togglePopupOrganization = () => {
    this.setState({
      showPopupOrganization: this.state.showPopupOrganization ? false : true
    })
  }

  onClickOrganizationList = (organization) => {
    setTimeout(() => {
      window.location.href = `/o/${organization.id}-${organization.createdTime}/home`;
    }, 100);
  }

  findOrganization = (newData) => {
    let _findIndex = -1;

    if (!newData) {
      _findIndex = this.state.userData.memberships.findIndex(value => value.organization.id === parseInt(this.props.activeOrgID.split("-")[0]));
    } else {
      _findIndex = newData.memberships.findIndex(value => value.organization.id === parseInt(this.props.activeOrgID.split("-")[0]));
    }

    return _findIndex;
  }

  findMemberRole = () => {
    let user = this.state.userData;
    if (this.props.activeOrgID) {
      // let organizationID = this.props.activeOrgID.split("-")[0];

      if (user.memberships.length === 0) {
        return ROLE_CODE.all;
      } else {
        return this.findOrganization() !== -1 ? user.memberships[this.findOrganization()].roleCode : ROLE_CODE.all
      }
    }
  }

  runGetBalance = () => {
    // ServiceOrganization.postGetBalance(this.props.activeOrgID, (response) => {
    //   if(response.dataResult.error.message === "") {
    //     let responseData = response.dataResult.data.balances;
    //     this.props.setBalanceData(responseData)

    //   }else {
    //     console.log(response.dataResult.error.message);
    //   }
    // })
    return null;
  }

  runBackToMainMenuIntegration = () => {
    this.props.history.push(`/o/${this.props.activeOrgID}/integration/channels`);
  }

  checkActiveSubmenu = (subPath) => {
    let _active = false;

    _active = window.location.pathname.includes(subPath);

    return _active;
  }

  render() {
    let sideMenuList = {
      home: {
        label: "Home",
        icon: iconHomeNew,
        newTab: false,
        pathName: `/o/${this.props.activeOrgID}/home`,
        activeValue: "home",
        role: [ROLE_CODE.member, ROLE_CODE.admin, ROLE_CODE.all, ROLE_CODE.owner, ROLE_CODE.custom],
        dontPrintOnLoop: false,
        isWaitTierDetail: false
      },
      integration: {
        label: "Integration",
        icon: iconIntegrationNew,
        newTab: false,
        activeValue: "integration",
        role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
        customRoleKey: "channel",
        // pathName: `/o/${this.props.activeOrgID}/integration/launcher`,
        subMenu: {
          channels: {
            name: "Channels",
            activeValue: "channels",
            pathName: `/o/${this.props.activeOrgID}/integration/channels`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/integration/channels",
            isChannel: false,
            customRoleKey: "channel"
          },
          launcher: {
            name: "Live Chat",
            activeValue: "launcher",
            pathName: `/o/${this.props.activeOrgID}/integration/launcher`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/integration/launcher",
            isChannel: true,
            customRoleKey: "channel:launcher"
          },
          whatsapp: {
            name: "WhatsApp for SME",
            activeValue: "whatsapp",
            pathName: `/o/${this.props.activeOrgID}/integration/whatsapp-sme`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/integration/whatsapp-sme",
            isChannel: true,
            customRoleKey: "channel:whatsapp"
          },
          whatsappba: {
            name: "WhatsApp Business API",
            activeValue: "whatsappba",
            pathName: `/o/${this.props.activeOrgID}/integration/whatsapp-business-api`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/integration/whatsapp-business-api",
            isChannel: true,
            customRoleKey: "channel:whatsappba"
          },
          facebook: {
            name: "Facebook Messenger",
            activeValue: "facebook",
            pathName: `/o/${this.props.activeOrgID}/integration/messenger`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/integration/messenger",
            isChannel: true,
            customRoleKey: "channel:facebook"
          },
          instagram: {
            name: "Instagram DM",
            activeValue: "instagram",
            pathName: `/o/${this.props.activeOrgID}/integration/instagram`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/integration/instagram",
            isChannel: true,
            customRoleKey: "channel:instagram"
          },
          telegram: {
            name: "Telegram",
            activeValue: "telegram",
            pathName: `/o/${this.props.activeOrgID}/integration/telegram`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/integration/telegram",
            isChannel: true,
            customRoleKey: "channel:telegram"
          },
          google_business: {
            name: "Google Business Messages",
            activeValue: "google_business",
            pathName: `/o/${this.props.activeOrgID}/integration/google-business-messages`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/integration/google-business-messages",
            isChannel: true,
            customRoleKey: "channel:google_business"
          },
          gb_profile: {
            name: "Google Business Profile",
            activeValue: "gb_profile",
            pathName: `/o/${this.props.activeOrgID}/integration/google-business-profile`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/integration/google-business-profile",
            isChannel: true,
            customRoleKey: "channel:gb_profile"
          },
          tokopedia: {
            name: "Tokopedia",
            activeValue: "tokopedia",
            pathName: `/o/${this.props.activeOrgID}/integration/tokopedia`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/integration/tokopedia",
            isChannel: true,
            customRoleKey: "channel:tokopedia"
          },
          // twitter: {
          //   name: "Twitter DM",
          //   activeValue: "twitter",
          //   pathName: `/o/${this.props.activeOrgID}/integration/twitter`,
          //   role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
          //   subPath: "/integration/twitter",
          //   isChannel: true
          // },
          // line: {
          //   name: "LINE",
          //   activeValue: "line",
          //   pathName: `/o/${this.props.activeOrgID}/integration/line`,
          //   role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
          //   subPath: "/integration/line",
          //   isChannel: true
          // },
          apiKeys: {
            name: "API Keys",
            activeValue: "apiKeys",
            pathName: `/o/${this.props.activeOrgID}/integration/api-keys`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/integration/api-keys",
            isChannel: false,
            customRoleKey: "api_key"
          },
          salestalk: {
            name: "SalesTalk",
            activeValue: "salestalk",
            pathName: `/o/${this.props.activeOrgID}/integration/salestalk`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/integration/salestalk",
            isChannel: false,
            customRoleKey: "salestalk"
          },
          chatbots: {
            name: "Chatbots",
            activeValue: "chatbots",
            pathName: `/o/${this.props.activeOrgID}/integration/chatbots`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/integration/chatbots",
            isChannel: false,
            customRoleKey: "chatbot"
          },
          webhook2: {
            name: "Webhooks",
            activeValue: "webhook2",
            pathName: `/o/${this.props.activeOrgID}/integration/webhooks`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/integration/webhooks",
            isChannel: false,
            customRoleKey: "webhooks"
          },
          email: {
            name: "Email",
            activeValue: "email",
            pathName: `/o/${this.props.activeOrgID}/integration/email`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/integration/email",
            isChannel: true,
            customRoleKey: "channel:email"
          },
          kataai: {
            name: "Kata.ai",
            activeValue: "kataai",
            pathName: `/o/${this.props.activeOrgID}/integration/kataai`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/integration/kataai",
            isChannel: true,
            customRoleKey: "channel:kataai"
          },
        },
        dontPrintOnLoop: false,
        isWaitTierDetail: true
      },
      // quickReply: {
      //   label: "Quick Reply",
      //   newTab: false,
      //   activeValue: "quick-reply",
      //   role: [ROLE_CODE.member, ROLE_CODE.admin, ROLE_CODE.all, ROLE_CODE.owner, ROLE_CODE.custom],
      //   icon: iconQuickReplyNew,
      //   subMenu: {
      //     generalQuickReply: {
      //       name: "General Quick Replies",
      //       activeValue: "general",
      //       pathName: `/o/${this.props.activeOrgID}/quick-reply/general`,
      //       role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
      //     },
      //     personalQuickReply: {
      //       name: "Personal Quick Replies",
      //       activeValue: "personal",
      //       pathName: `/o/${this.props.activeOrgID}/quick-reply/personal`,
      //       role: [ROLE_CODE.member, ROLE_CODE.admin, ROLE_CODE.all, ROLE_CODE.owner, ROLE_CODE.custom],
      //     }
      //   },
      //   dontPrintOnLoop: false,
      //   isWaitTierDetail: true
      // },
      // technical: {
      //   label: "Technical",
      //   icon: iconTechnicalNew,
      //   newTab: false,
      //   activeValue: "technical",
      //   role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
      //   subMenu: {
      //       secretKeys: {
      //         name: "Secret Keys",
      //         activeValue: "secret-keys",
      //         pathName: `/o/${this.props.activeOrgID}/technical/secret-keys`,
      //         role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
      //       },
      //       // pushNotification: {
      //       //   name: "Push Notifications",
      //       //   activeValue: "push-notifications",
      //       //   pathName: `/o/${this.props.activeOrgID}/technical/secret-keys`
      //       // }
      //   },
      //   dontPrintOnLoop: false,
      //   isWaitTierDetail: true
      // },
      operational: {
        label: 'Operational',
        newTab: false,
        activeValue: 'operational',
        role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
        icon: iconOperational,
        customRoleKey: "operational",
        subMenu: {
          broadcastMessage: {
            name: 'Broadcast Message',
            activeValue: 'broadcast-message',
            pathName: `/o/${this.props.activeOrgID}/operational/broadcast-message`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/operational/broadcast-message",
            customRoleKey: "broadcast"
          },
          userContact: {
            name: 'Contacts',
            activeValue: 'user-contacts',
            pathName: `/o/${this.props.activeOrgID}/operational/user-contact`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/operational/user-contact",
            customRoleKey: "contact"
          },
          userSegment: {
            name: 'Contact Segments',
            activeValue: 'user-segments',
            pathName: `/o/${this.props.activeOrgID}/operational/user-segment`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/operational/user-segment",
            customRoleKey: "contact_segment"
          },
          importContact: {
            name: 'Import Contacts',
            activeValue: 'import-contacts',
            pathName: `/o/${this.props.activeOrgID}/operational/import-contacts`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/operational/import-contacts",
            customRoleKey: "contact"
          },
        },
        dontPrintOnLoop: false,
        isWaitTierDetail: true
      },
      organization: {
        label: "Organization",
        icon: iconOrganizationNew,
        newTab: false,
        activeValue: "organization",
        role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
        customRoleKey: "organization",
        subMenu: {
          // profile: {
          //   name: "Profile",
          //   activeValue: "profile",
          //   pathName: `/o/${this.props.activeOrgID}/organization/profile`
          // },
          members: {
            name: "Members",
            activeValue: "members",
            pathName: `/o/${this.props.activeOrgID}/organization/members`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/organization/members",
            customRoleKey: "member"
          },
          // setup: {
          //   name: "Setup",
          //   activeValue: "setup",
          //   pathName: `/o/${this.props.activeOrgID}/organization/setup`,
          //   role: [ROLE_CODE.admin, ROLE_CODE.owner]
          // },
          roles: {
            name: "Roles",
            activeValue: "roles",
            pathName: `/o/${this.props.activeOrgID}/organization/roles`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/organization/roles",
            customRoleKey: "role"
          },
          topics: {
            name: "Topics",
            activeValue: "topics",
            pathName: `/o/${this.props.activeOrgID}/organization/topics`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/organization/topics",
            customRoleKey: "topic"
          },
          labels: {
            name: "Case Labels",
            activeValue: "labels",
            pathName: `/o/${this.props.activeOrgID}/organization/labels`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/organization/labels",
            customRoleKey: "case_label"
          },
          billing: {
            name: "Billing",
            activeValue: "billing",
            pathName: `/o/${this.props.activeOrgID}/organization/billing`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            ignoreTier: true,
            subPath: "/organization/billing",
            customRoleKey: "billing"
          },
          organizationDetails: {
            name: "Organization Details",
            activeValue: "organizationDetails",
            pathName: `/o/${this.props.activeOrgID}/organization/details`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            ignoreTier: true,
            subPath: "/organization/details",
            customRoleKey: "organization_details"
          },
          // topup: {
          //   name: "Balance & Top Up",
          //   activeValue: "topup",
          //   pathName: `/o/${this.props.activeOrgID}/organization/top-up`,
          //   role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
          //   ignoreTier: true
          // }
        },
        dontPrintOnLoop: false,
        isWaitTierDetail: true
      },
      setup: {
        label: "Setup",
        icon: iconSetup,
        newTab: false,
        activeValue: "setup",
        role: [ROLE_CODE.member, ROLE_CODE.admin, ROLE_CODE.all, ROLE_CODE.owner, ROLE_CODE.custom],
        customRoleKey: "setup",
        subMenu: {
          quickReply: {
            name: "Quick Reply",
            activeValue: "quick-reply",
            pathName: `/o/${this.props.activeOrgID}/setup/quick-reply`,
            role: [ROLE_CODE.member, ROLE_CODE.admin, ROLE_CODE.all, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/setup/quick-reply",
            customRoleKey: "quick_reply"
          },
          officeHour: {
            name: "Office Hours",
            activeValue: "office-hour",
            pathName: `/o/${this.props.activeOrgID}/setup/office-hour`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/setup/office-hour",
            customRoleKey: "office_hours"
          },
          welcomeMessage: {
            name: "Welcome Message",
            activeValue: "welcome-message",
            pathName: `/o/${this.props.activeOrgID}/setup/welcome-message`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/setup/welcome-message",
            customRoleKey: "welcome_message"
          },
          awayMessage: {
            name: "Away Message",
            activeValue: "away-message",
            pathName: `/o/${this.props.activeOrgID}/setup/away-message`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/setup/away-message",
            customRoleKey: "away_message"
          },
          closingMessage: {
            name: "Closing Message",
            activeValue: "closing-message",
            pathName: `/o/${this.props.activeOrgID}/setup/closing-message`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/setup/closing-message",
            customRoleKey: "closing_message"
          },
          userRating: {
            name: "User Rating",
            activeValue: "user-rating",
            pathName: `/o/${this.props.activeOrgID}/setup/user-rating`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            ignoreTier: true,
            subPath: "/setup/user-rating",
            customRoleKey: "user_rating"
          },
          assignmentRules: {
            name: "General Assignments",
            activeValue: "assignment-rules",
            pathName: `/o/${this.props.activeOrgID}/setup/general-assignments`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            ignoreTier: true,
            subPath: "/setup/general-assignments",
            customRoleKey: "assignment_rules"
          },
          inboxSetting: {
            name: "Inbox Settings",
            activeValue: "inbox-settings",
            pathName: `/o/${this.props.activeOrgID}/setup/inbox-settings`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            ignoreTier: true,
            subPath: "/setup/inbox-settings",
            customRoleKey: "inbox_settings"
          },
          faq: {
            name: "QnA Path",
            activeValue: "faq",
            pathName: `/o/${this.props.activeOrgID}/setup/qna`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            ignoreTier: true,
            subPath: "/setup/qna",
            customRoleKey: "qna_path"
          },
          // autoResolve: {
          //   name: "Auto Resolve Case",
          //   activeValue: "auto-resolve-case",
          //   pathName: `/o/${this.props.activeOrgID}/setup/auto-resolve-case`,
          //   role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
          //   ignoreTier: true,
          //   subPath: "/setup/auto-resolve-case",
          //   customRoleKey: "auto_resolve_case"
          // },
          // autoUnassign: {
          //   name: "Auto Unassign Agent",
          //   activeValue: "autoUnassign",
          //   pathName: `/o/${this.props.activeOrgID}/setup/auto-unassigns-agent`,
          //   role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
          //   ignoreTier: true,
          //   subPath: "/setup/auto-unassigns-agent",
          //   customRoleKey: "auto_unassign_agent"
          // },
          contactInfoCustomField: {
            name: "Contact Info Custom Field",
            activeValue: "contact-info-custom-field",
            pathName: `/o/${this.props.activeOrgID}/setup/contact-info-custom-field`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            ignoreTier: true,
            subPath: "/setup/contact-info-custom-field",
            customRoleKey: "contact_custom_field"
          },
        },
        dontPrintOnLoop: false,
        isWaitTierDetail: true
      },
      report: {
        label: "Report",
        icon: iconReport,
        newTab: false,
        activeValue: "report",
        role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
        customRoleKey: "report",
        subMenu: {
          dailyOverview: {
            name: "Daily Overview",
            activeValue: "dailyOverview",
            pathName: `/o/${this.props.activeOrgID}/report/daily-overview`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/report/daily-overview",
            customRoleKey: "report:daily_overview"
          },
          overview: {
            name: "General Overview",
            activeValue: "overview",
            pathName: `/o/${this.props.activeOrgID}/report/general-overview`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/report/general-overview",
            customRoleKey: "report:general_overview"
          },
          messageUserSummary: {
            name: "Message & User Summary",
            activeValue: "message-user-summary",
            pathName: `/o/${this.props.activeOrgID}/report/message-user-summary`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/report/message-user-summary",
            customRoleKey: "report:message_user_summary"
          },
          caseReport: {
            name: "Case Report",
            activeValue: "case-report",
            pathName: `/o/${this.props.activeOrgID}/report/case-report`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/report/case-report",
            customRoleKey: "report:case_report"
          },
          caseRatings: {
            name: "Case Ratings",
            activeValue: "case-ratings",
            pathName: `/o/${this.props.activeOrgID}/report/case-ratings`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/report/case-ratings",
            customRoleKey: "report:case_ratings"
          },
          agentReport: {
            name: "Agent Report",
            activeValue: "agent-report",
            pathName: `/o/${this.props.activeOrgID}/report/agent-report`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/report/agent-report",
            customRoleKey: "report:agent_report"
          },
          clockInReport: {
            name: "Clock In Report",
            activeValue: "clock-in-report",
            pathName: `/o/${this.props.activeOrgID}/report/clock-in-report`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/report/clock-in-report",
            customRoleKey: "report:clock_in_report"
          },
          topicReport: {
            name: "Topic Report",
            activeValue: "topic-report",
            pathName: `/o/${this.props.activeOrgID}/report/topic-report`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/report/topic-report",
            customRoleKey: "report:topic_report"
          },
          messageApiHistory: {
            name: "Message API History",
            activeValue: "message-api-history",
            pathName: `/o/${this.props.activeOrgID}/report/message-api-history`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/report/message-api-history",
            customRoleKey: "report:message_api_history"
          },
          utm: {
            name: "Referrer & UTM",
            activeValue: "utm",
            pathName: `/o/${this.props.activeOrgID}/report/referrer-utm`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/report/referrer-utm",
            customRoleKey: "report:referrer_utm"
          },
        },
        dontPrintOnLoop: false,
        isWaitTierDetail: true
      },
      ai: {
        label: "Assistance AI",
        icon: iconAI,
        pathName: `/o/${this.props.activeOrgID}/assistance-ai`,
        newTab: false,
        activeValue: "home",
        role: [ROLE_CODE.member, ROLE_CODE.admin, ROLE_CODE.all, ROLE_CODE.owner, ROLE_CODE.custom],
        dontPrintOnLoop: false,
        isWaitTierDetail: false
      },
      inbox: {
        label: "Inbox",
        pathName: `/o/${this.props.activeOrgID}/inbox`,
        newTab: process.env.REACT_APP_ENV === "prod",
        // newTab: false,
        role: [ROLE_CODE.member, ROLE_CODE.admin, ROLE_CODE.all, ROLE_CODE.owner, ROLE_CODE.custom],
        icon: iconInboxNew,
        dontPrintOnLoop: false,
        isWaitTierDetail: true,
        customRoleKey: "inbox"
      },
      account: {
        label: "Account",
        newTab: false,
        activeValue: "account",
        role: [ROLE_CODE.member, ROLE_CODE.admin, ROLE_CODE.all, ROLE_CODE.owner, ROLE_CODE.custom],
        icon: "",
        subMenu: {
          profile: {
            name: "Profile",
            activeValue: "profile",
            pathName: `/o/${this.props.activeOrgID}/account/profile`,
            role: [ROLE_CODE.member, ROLE_CODE.admin, ROLE_CODE.all, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/account/profile",
            customRoleKey: "profile"
          },
          security: {
            name: "Security",
            activeValue: "security",
            pathName: `/o/${this.props.activeOrgID}/account/security`,
            role: [ROLE_CODE.member, ROLE_CODE.admin, ROLE_CODE.all, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/account/security",
            customRoleKey: "security"
          },
          settings: {
            name: "Settings",
            activeValue: "settings",
            pathName: `/o/${this.props.activeOrgID}/account/settings`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner, ROLE_CODE.custom],
            subPath: "/account/settings",
            customRoleKey: "settings"
          },
        },
        dontPrintOnLoop: true,
        isWaitTierDetail: true,
        customRoleKey: "account"
      }
    };

    // let activeMenuCheck = (pathString) => {
    //   console.log(pathString)
    //   return window.location.href.includes(pathString);
    // }

    let isSubmenuExist = () => {
      return typeof this.props.sideMenu === "object" && this.props.sideMenu.submenuList;
    }

    let resetSubMenu = () => {
      this.props.setParentMenuBlockActive({
        activeMenuBlock: "",
        submenuList: null,
        activeSubMenu: false
      })
    }

    let mainMenuClick = (activeParent, submenuList) => {
      let _submenuList = submenuList ? submenuList : [];
      let activeSubMenu = "";

      let isRoleMatch = (role) => {
        return role.findIndex(val => val === this.findMemberRole()) !== -1;
      }

      let i = 0;
      while (i < Object.keys(_submenuList).length) {
        if (this.state.userData.memberships[this.findOrganization()].roleCode === "custom") {
          if (activeParent === "integration") {
            let allowedModule = this.state.userData.memberships[this.findOrganization()].allowedModules;

            if (allowedModule.join("").includes("channel")) {
              this.props.history.push(_submenuList[Object.keys(_submenuList)[i]].pathName);
              activeSubMenu = _submenuList[Object.keys(_submenuList)[i]].activeValue;
              break;
            } else {
              if (isSubModuleAllowed(_submenuList[Object.keys(_submenuList)[i]].customRoleKey)) {
                this.props.history.push(_submenuList[Object.keys(_submenuList)[i]].pathName);
                activeSubMenu = _submenuList[Object.keys(_submenuList)[i]].activeValue;
                break;
              } else {
                i++;
              }
            }
          } else {
            if (isSubModuleAllowed(_submenuList[Object.keys(_submenuList)[i]].customRoleKey)) {
              this.props.history.push(_submenuList[Object.keys(_submenuList)[i]].pathName);
              activeSubMenu = _submenuList[Object.keys(_submenuList)[i]].activeValue;
              break;
            } else {
              i++;
            }
          }
        } else {
          if (isRoleMatch(_submenuList[Object.keys(_submenuList)[i]].role)) {
            this.props.history.push(_submenuList[Object.keys(_submenuList)[i]].pathName);
            activeSubMenu = _submenuList[Object.keys(_submenuList)[i]].activeValue;
            break;
          } else {
            i++;
          }
        }
      }

      this.props.setParentMenuBlockActive({
        activeMenuBlock: activeParent,
        submenuList: _submenuList,
        activeSubMenu: activeSubMenu
      })
    }

    let findMediumCounter = (medium) => {
      let mediumCounter = 0;
      let roleCode = this.state.userData.memberships[this.findOrganization()].roleCode;
      let allowedModules = this.state.userData.memberships[this.findOrganization()].allowedModules;

      if (medium === "channels") {
        if (this.props.mediumData) {
          this.props.mediumData.mediums.map(v => {
            if (roleCode === "custom") {
              if (allowedModules.findIndex(_v => _v === `channel:${v.code}`) !== -1 || allowedModules.findIndex(_v => _v === `channel`) !== -1) {
                mediumCounter = mediumCounter + v.count;
              }
            } else {
              mediumCounter = mediumCounter + v.count;
            }

            return null;
          })
        }
      } else {
        if (this.props.mediumData) {
          let findIndex = this.props.mediumData.mediums.findIndex(val => val.code === medium);

          if (findIndex !== -1) {
            mediumCounter = this.props.mediumData.mediums[findIndex].count;
          }
        }
      }

      return mediumCounter;
    }

    let onClickSubMenu = (activeValue) => {
      let _sideMenu = { ...this.props.sideMenu };
      _sideMenu.activeSubMenu = activeValue;

      this.props.setParentMenuBlockActive(_sideMenu);
    }

    // let formatMoney = (currency, amount) => {
    //   if(currency === "IDR") return rupiahFormat(amount)
    //   return `${currency} ${numberWithCommas(amount)}`
    // }

    // let getBalance = () => {
    //   let balance = "-"
    //   if (this.props.allOrganizationDetail) {
    //     let prefCurrency = this.props.allOrganizationDetail.organization.prefCurrency

    //     if(this.props.balanceData.length > 0) {
    //       let currBalance = this.props.balanceData.find(elem => elem.currency === prefCurrency);
    //       if(currBalance) {
    //         balance = formatMoney(prefCurrency, currBalance.amount)
    //       } else {
    //         balance = formatMoney(this.props.balanceData[0].currency, this.props.balanceData[0].amount)
    //       }
    //     } else {
    //       balance = formatMoney(prefCurrency, 0)
    //     }
    //   } 
    //   return balance
    // }

    let checkIsMenuHide = (isParent, menuText) => {
      let isHide = false;

      if (this.findOrganization() !== -1) {
        if (this.state.userData.memberships[this.findOrganization()]) {
          if (this.state.hideMenuData.length > 0) {
            let roleCode = this.state.userData.memberships[this.findOrganization()].roleCode;
            let _hideMenuData = this.state.hideMenuData;
            let orgID = this.props.match.params.orgID.split("-")[0];
            let indexOrgID = _hideMenuData.findIndex(v => v.orgID === orgID);

            if (indexOrgID !== -1) {
              if (roleCode === _hideMenuData[indexOrgID].roleCode) {
                if (isParent) {
                  if (_hideMenuData[indexOrgID].parentMenu.findIndex(v => v === menuText) !== -1) {
                    isHide = true;
                  }
                } else {
                  if (_hideMenuData[indexOrgID].subMenu.findIndex(v => v === menuText) !== -1) {
                    isHide = true;
                  }
                }
              }
            }
          }
        }
      }

      return isHide;
    }

    let isSubModuleAllowed = (menuCode) => {
      let _isAllowed = false;

      let findIndexModuleChild = this.state.userData.memberships[this.findOrganization()].allowedModules.findIndex(v => v === menuCode.split(":")[0]);
      let findIndexModule = this.state.userData.memberships[this.findOrganization()].allowedModules.findIndex(v => v === menuCode);

      if (findIndexModule !== -1 || findIndexModuleChild !== -1) {
        _isAllowed = true;
      }

      if (
        menuCode === "settings" ||
        menuCode === "profile" ||
        menuCode === "security" ||
        menuCode === "quick_reply"
      ) {
        _isAllowed = true;
      }

      if (menuCode === "assignment_rules") {
        let generalAssign = ["assignment_rules", "auto_resolve_case", "auto_unassign_agent", "chatbot_idle_case_config"];
        let moduleGeneralAssign = (moduleGeneral) => {
          let genAssignModule = this.state.userData.memberships[this.findOrganization()].allowedModules.findIndex(v => v === moduleGeneral);

          return genAssignModule === -1 ? false : true;
        }

        generalAssign.map((v) => {
          if (!_isAllowed) {
            _isAllowed = moduleGeneralAssign(v)
          }
        })
      }

      return _isAllowed;
    }

    return (
      <React.Fragment>
        <div className={`new-side-menu-wrapper ${(process.env.REACT_APP_HIDE_BILLING !== "true" && this.props.billingWarning) ? "side-menu-active-billing-warning" : ""}`}>
          <div className="scrolling-top-menu">
            <Scrollbars
              renderThumbVertical={props => <div {...props} style={style.scrollStyle} />}
            >
              {this.state.userData !== null &&
                (this.state.userData.memberships[this.findOrganization()].roleCode !== "custom" ?
                  // main role
                  <>
                    {Object.keys(sideMenuList).map((value, key) => {
                      if (sideMenuList[value].role.findIndex(val => val === this.findMemberRole()) !== -1 && !sideMenuList[value].dontPrintOnLoop) {
                        if (sideMenuList[value].pathName) {
                          if (!sideMenuList[value].isWaitTierDetail) {
                            return (
                              !checkIsMenuHide(true, sideMenuList[value].label) ?
                                (value === "ai" ?
                                  // (this.props.match.params.orgID).split("-")[0] === "5819" ?
                                  ((process.env.REACT_APP_IS_ONPREMISE === "true" && process.env.REACT_APP_ONPREMISE_ASISTENAI_REPORT_URL &&process.env.REACT_APP_ONPREMISE_ASISTENAI_REPORT_URL !== "") ?
                                    <NavLink to={sideMenuList[value].pathName} target={sideMenuList[value].newTab ? '_blank' : '_self'}
                                      key={`main-menu-${key}`}
                                      onClick={() => {
                                        this.props.setParentMenuBlockActive({
                                          activeMenuBlock: sideMenuList[value].activeValue,
                                          submenuList: null
                                        })
                                      }}
                                    >
                                      <div
                                        className={`
                                          new-main-side-menu-wrapper
                                          ${this.props.sideMenu.activeMenuBlock && this.props.sideMenu.activeMenuBlock === sideMenuList[value].activeValue ? 'active-side-menu' : ''}
                                          ${checkIsMenuHide(true, sideMenuList[value].label) ? "force-hide" : ""}
                                        `}
                                        onClick={() => {
                                          mainMenuClick(sideMenuList[value].activeValue, sideMenuList[value].subMenu);

                                          if (sideMenuList[value].activeValue === "organization") {
                                            this.runGetBalance()
                                          }
                                        }}
                                      >
                                        <div className="active-white-line" />
                                        <img src={sideMenuList[value].icon} alt="" />
                                        <br />
                                        <b>{sideMenuList[value].label}</b>
                                      </div>
                                    </NavLink>
                                    :
                                    ""
                                  )
                                  :
                                  <NavLink to={sideMenuList[value].pathName} target={sideMenuList[value].newTab ? '_blank' : '_self'}
                                    key={`main-menu-${key}`}
                                    onClick={() => {
                                      this.props.setParentMenuBlockActive({
                                        activeMenuBlock: sideMenuList[value].activeValue,
                                        submenuList: null
                                      })
                                    }}
                                  >
                                    <div
                                      className={`
                                          new-main-side-menu-wrapper
                                          ${this.props.sideMenu.activeMenuBlock && this.props.sideMenu.activeMenuBlock === sideMenuList[value].activeValue ? 'active-side-menu' : ''}
                                          ${checkIsMenuHide(true, sideMenuList[value].label) ? "force-hide" : ""}
                                        `}
                                      onClick={() => {
                                        mainMenuClick(sideMenuList[value].activeValue, sideMenuList[value].subMenu);

                                        if (sideMenuList[value].activeValue === "organization") {
                                          this.runGetBalance()
                                        }
                                      }}
                                    >
                                      <div className="active-white-line" />
                                      <img src={sideMenuList[value].icon} alt="" />
                                      <br />
                                      <b>{sideMenuList[value].label}</b>
                                    </div>
                                  </NavLink>
                                )
                                :
                                ""
                            )
                          } else { // if(!sideMenuList[value].isWaitTierDetail) {
                            if (this.props.currentTierDetailProps !== null) {
                              if (this.props.currentTierDetailProps.id !== 0 && this.props.currentTierDetailProps.isActive) {
                                return (
                                  // <NavLink to={sideMenuList[value].pathName} target={sideMenuList[value].newTab ? '_blank' : '_self'}
                                  !checkIsMenuHide(true, sideMenuList[value].label) ?
                                    <NavLink to={sideMenuList[value].pathName} target={'_blank'}
                                      key={`main-menu-${key}`}
                                      onClick={() => {
                                        this.props.setParentMenuBlockActive({
                                          activeMenuBlock: sideMenuList[value].activeValue,
                                          submenuList: null
                                        })
                                      }}
                                    >
                                      <div
                                        className={`
                                            new-main-side-menu-wrapper
                                            ${this.props.sideMenu.activeMenuBlock && this.props.sideMenu.activeMenuBlock === sideMenuList[value].activeValue ? 'active-side-menu' : ''}
                                            ${checkIsMenuHide(true, sideMenuList[value].label) ? "force-hide" : ""}
                                          `}
                                        onClick={() => {
                                          mainMenuClick(sideMenuList[value].activeValue, sideMenuList[value].subMenu);

                                          if (sideMenuList[value].activeValue === "organization") {
                                            this.runGetBalance()
                                          }
                                        }}
                                      >
                                        <div className="active-white-line" />
                                        <img src={sideMenuList[value].icon} alt="" />
                                        <br />
                                        <b>{sideMenuList[value].label}</b>
                                      </div>
                                    </NavLink>
                                    :
                                    ""
                                )
                              }
                            }
                          }
                        } else { // if(sideMenuList[value].pathName) { -> with sub 
                          if (!sideMenuList[value].isWaitTierDetail) {
                            return (
                              !checkIsMenuHide(true, sideMenuList[value].label) ?
                                <div
                                  className={`
                                      new-main-side-menu-wrapper
                                      ${this.props.sideMenu.activeMenuBlock && this.props.sideMenu.activeMenuBlock === sideMenuList[value].activeValue ? 'active-side-menu' : ''}
                                      ${checkIsMenuHide(true, sideMenuList[value].label) ? "force-hide" : ""}
                                    `}
                                  key={`main-menu-${key}`}
                                  onClick={() => {
                                    mainMenuClick(sideMenuList[value].activeValue, sideMenuList[value].subMenu);

                                    if (sideMenuList[value].activeValue === "organization") {
                                      this.runGetBalance()
                                    }
                                  }}
                                >
                                  <div className="active-white-line" />
                                  <img src={sideMenuList[value].icon} alt="" />
                                  <br />
                                  <b>{sideMenuList[value].label}</b>
                                </div>
                                :
                                ""
                            )
                          } else { // if(!sideMenuList[value].isWaitTierDetail) {
                            if (this.props.currentTierDetailProps !== null) {
                              if (sideMenuList[value].label === "Organization" && this.props.allOrganizationDetail) {
                                if (this.props.allOrganizationDetail.paymentSettings.isSet || this.props.allOrganizationDetail.selectedTier.id !== 0) {
                                  // if(this.props.currentTierDetailProps.id !== 0 && this.props.currentTierDetailProps.isActive) {
                                  return (
                                    !checkIsMenuHide(true, sideMenuList[value].label) ?
                                      <div
                                        className={`
                                            new-main-side-menu-wrapper
                                            ${this.props.sideMenu.activeMenuBlock && this.props.sideMenu.activeMenuBlock === sideMenuList[value].activeValue ? 'active-side-menu' : ''}
                                            ${checkIsMenuHide(true, sideMenuList[value].label) ? "force-hide" : ""}
                                          `}
                                        key={`main-menu-${key}`}
                                        onClick={() => {
                                          mainMenuClick(sideMenuList[value].activeValue, sideMenuList[value].subMenu);

                                          if (sideMenuList[value].activeValue === "organization") {
                                            this.runGetBalance()
                                          }
                                        }}
                                      >
                                        <div className="active-white-line" />
                                        <img src={sideMenuList[value].icon} alt="" />
                                        <br />
                                        <b>{sideMenuList[value].label}</b>
                                      </div>
                                      :
                                      ""
                                  )
                                  // }
                                }
                              } else {
                                if (this.props.currentTierDetailProps.id !== 0 && this.props.currentTierDetailProps.isActive) {
                                  return (
                                    !checkIsMenuHide(true, sideMenuList[value].label) ?
                                      <div
                                        className={`
                                          new-main-side-menu-wrapper
                                          ${this.props.sideMenu.activeMenuBlock && this.props.sideMenu.activeMenuBlock === sideMenuList[value].activeValue ? 'active-side-menu' : ''}
                                          ${checkIsMenuHide(true, sideMenuList[value].label) ? "force-hide" : ""}
                                        `}
                                        key={`main-menu-${key}`}
                                        onClick={() => {
                                          mainMenuClick(sideMenuList[value].activeValue, sideMenuList[value].subMenu);

                                          if (sideMenuList[value].activeValue === "organization") {
                                            this.runGetBalance()
                                          }
                                        }}
                                      >
                                        <div className="active-white-line" />
                                        <img src={sideMenuList[value].icon} alt="" />
                                        <br />
                                        <b>{sideMenuList[value].label}</b>
                                      </div>
                                      :
                                      ""
                                  )
                                }
                              }
                            }
                          }
                        }
                      } else {
                        return false;
                      }

                      return null;
                    })}
                  </>
                  // main role
                  :
                  // custom role
                  <>
                    <>
                      {Object.keys(sideMenuList).map((value, key) => {
                        if (sideMenuList[value].role.findIndex(val => val === this.findMemberRole()) !== -1 && !sideMenuList[value].dontPrintOnLoop) {
                          if (sideMenuList[value].pathName) {
                            if (!sideMenuList[value].isWaitTierDetail) {
                              return (
                                !checkIsMenuHide(true, sideMenuList[value].label) ?
                                  (value === "ai" ?
                                    // (this.props.match.params.orgID).split("-")[0] === "5819" ?
                                    ((process.env.REACT_APP_IS_ONPREMISE === "true" && process.env.REACT_APP_ONPREMISE_ASISTENAI_REPORT_URL &&process.env.REACT_APP_ONPREMISE_ASISTENAI_REPORT_URL !== "") ?
                                      <NavLink
                                        to={sideMenuList[value].pathName}
                                        target={sideMenuList[value].newTab ? '_blank' : '_self'}
                                        key={`main-menu-${key}`}
                                        onClick={() => {
                                          this.props.setParentMenuBlockActive({
                                            activeMenuBlock: sideMenuList[value].activeValue,
                                            submenuList: null
                                          })
                                        }}
                                      >
                                        <div
                                          className={`
                                            new-main-side-menu-wrapper
                                            ${this.props.sideMenu.activeMenuBlock && this.props.sideMenu.activeMenuBlock === sideMenuList[value].activeValue ? 'active-side-menu' : ''}
                                            ${checkIsMenuHide(true, sideMenuList[value].label) ? "force-hide" : ""}
                                          `}
                                          onClick={() => {
                                            mainMenuClick(sideMenuList[value].activeValue, sideMenuList[value].subMenu);

                                            if (sideMenuList[value].activeValue === "organization") {
                                              this.runGetBalance()
                                            }
                                          }}
                                        >
                                          <div className="active-white-line" />
                                          <img src={sideMenuList[value].icon} alt="" />
                                          <br />
                                          <b>{sideMenuList[value].label}</b>
                                        </div>
                                      </NavLink>
                                      :
                                      ""
                                    )
                                    :
                                    (this.state.parentMenuList[value] &&
                                      <NavLink
                                        to={sideMenuList[value].pathName}
                                        target={sideMenuList[value].newTab ? '_blank' : '_self'}
                                        key={`main-menu-${key}`}
                                        onClick={() => {
                                          this.props.setParentMenuBlockActive({
                                            activeMenuBlock: sideMenuList[value].activeValue,
                                            submenuList: null
                                          })
                                        }}
                                      >
                                        <div
                                          className={`
                                            new-main-side-menu-wrapper
                                            ${this.props.sideMenu.activeMenuBlock && this.props.sideMenu.activeMenuBlock === sideMenuList[value].activeValue ? 'active-side-menu' : ''}
                                            ${checkIsMenuHide(true, sideMenuList[value].label) ? "force-hide" : ""}
                                          `}
                                          onClick={() => {
                                            mainMenuClick(sideMenuList[value].activeValue, sideMenuList[value].subMenu);

                                            if (sideMenuList[value].activeValue === "organization") {
                                              this.runGetBalance()
                                            }
                                          }}
                                        >
                                          <div className="active-white-line" />
                                          <img src={sideMenuList[value].icon} alt="" />
                                          <br />
                                          <b>{sideMenuList[value].label}</b>
                                        </div>
                                      </NavLink>
                                    )
                                  )
                                  :
                                  ""
                              )
                            } else { // if(!sideMenuList[value].isWaitTierDetail) {
                              if (this.props.currentTierDetailProps !== null) {
                                if (this.props.currentTierDetailProps.id !== 0 && this.props.currentTierDetailProps.isActive) {
                                  return (
                                    // <NavLink to={sideMenuList[value].pathName} target={sideMenuList[value].newTab ? '_blank' : '_self'}
                                    !checkIsMenuHide(true, sideMenuList[value].label) ?
                                      (this.state.parentMenuList[value] &&
                                        <NavLink to={sideMenuList[value].pathName} target={'_blank'}
                                          key={`main-menu-${key}`}
                                          onClick={() => {
                                            this.props.setParentMenuBlockActive({
                                              activeMenuBlock: sideMenuList[value].activeValue,
                                              submenuList: null
                                            })
                                          }}
                                        >
                                          <div
                                            className={`
                                            new-main-side-menu-wrapper
                                            ${this.props.sideMenu.activeMenuBlock && this.props.sideMenu.activeMenuBlock === sideMenuList[value].activeValue ? 'active-side-menu' : ''}
                                            ${checkIsMenuHide(true, sideMenuList[value].label) ? "force-hide" : ""}
                                          `}
                                            onClick={() => {
                                              mainMenuClick(sideMenuList[value].activeValue, sideMenuList[value].subMenu);

                                              if (sideMenuList[value].activeValue === "organization") {
                                                this.runGetBalance()
                                              }
                                            }}
                                          >
                                            <div className="active-white-line" />
                                            <img src={sideMenuList[value].icon} alt="" />
                                            <br />
                                            <b>{sideMenuList[value].label}</b>
                                          </div>
                                        </NavLink>
                                      )
                                      :
                                      ""
                                  )
                                }
                              }
                            }
                          } else { // if(sideMenuList[value].pathName) { -> with sub 
                            if (!sideMenuList[value].isWaitTierDetail) {
                              return (
                                !checkIsMenuHide(true, sideMenuList[value].label) ?
                                  (this.state.parentMenuList[value] &&
                                    <div
                                      className={`
                                      new-main-side-menu-wrapper
                                      ${this.props.sideMenu.activeMenuBlock && this.props.sideMenu.activeMenuBlock === sideMenuList[value].activeValue ? 'active-side-menu' : ''}
                                      ${checkIsMenuHide(true, sideMenuList[value].label) ? "force-hide" : ""}
                                    `}
                                      key={`main-menu-${key}`}
                                      onClick={() => {
                                        mainMenuClick(sideMenuList[value].activeValue, sideMenuList[value].subMenu);

                                        if (sideMenuList[value].activeValue === "organization") {
                                          this.runGetBalance()
                                        }
                                      }}
                                    >
                                      <div className="active-white-line" />
                                      <img src={sideMenuList[value].icon} alt="" />
                                      <br />
                                      <b>{sideMenuList[value].label}</b>
                                    </div>
                                  )
                                  :
                                  ""
                              )
                            } else { // if(!sideMenuList[value].isWaitTierDetail) {
                              if (this.props.currentTierDetailProps !== null) {
                                if (sideMenuList[value].label === "Organization" && this.props.allOrganizationDetail) {
                                  if (this.props.allOrganizationDetail.paymentSettings.isSet || this.props.allOrganizationDetail.selectedTier.id !== 0) {
                                    // if(this.props.currentTierDetailProps.id !== 0 && this.props.currentTierDetailProps.isActive) {
                                    return (
                                      !checkIsMenuHide(true, sideMenuList[value].label) ?
                                        (this.state.parentMenuList[value] &&
                                          <div
                                            className={`
                                            new-main-side-menu-wrapper
                                            ${this.props.sideMenu.activeMenuBlock && this.props.sideMenu.activeMenuBlock === sideMenuList[value].activeValue ? 'active-side-menu' : ''}
                                            ${checkIsMenuHide(true, sideMenuList[value].label) ? "force-hide" : ""}
                                          `}
                                            key={`main-menu-${key}`}
                                            onClick={() => {
                                              mainMenuClick(sideMenuList[value].activeValue, sideMenuList[value].subMenu);

                                              if (sideMenuList[value].activeValue === "organization") {
                                                this.runGetBalance()
                                              }
                                            }}
                                          >
                                            <div className="active-white-line" />
                                            <img src={sideMenuList[value].icon} alt="" />
                                            <br />
                                            <b>{sideMenuList[value].label}</b>
                                          </div>
                                        )
                                        :
                                        ""
                                    )
                                    // }
                                  }
                                } else {
                                  if (this.props.currentTierDetailProps.id !== 0 && this.props.currentTierDetailProps.isActive) {
                                    return (
                                      !checkIsMenuHide(true, sideMenuList[value].label) ?
                                        (this.state.parentMenuList[value] &&
                                          <div
                                            className={`
                                            new-main-side-menu-wrapper
                                            ${this.props.sideMenu.activeMenuBlock && this.props.sideMenu.activeMenuBlock === sideMenuList[value].activeValue ? 'active-side-menu' : ''}
                                            ${checkIsMenuHide(true, sideMenuList[value].label) ? "force-hide" : ""}
                                          `}
                                            key={`main-menu-${key}`}
                                            onClick={() => {
                                              mainMenuClick(sideMenuList[value].activeValue, sideMenuList[value].subMenu);

                                              if (sideMenuList[value].activeValue === "organization") {
                                                this.runGetBalance()
                                              }
                                            }}
                                          >
                                            <div className="active-white-line" />
                                            <img src={sideMenuList[value].icon} alt="" />
                                            <br />
                                            <b>{sideMenuList[value].label}</b>
                                          </div>
                                        )
                                        :
                                        ""

                                    )
                                  }
                                }
                              }
                            }
                          }
                        } else {
                          return false;
                        }

                        return null;
                      })}
                    </>
                  </>
                  // custom role
                )
              }
            </Scrollbars>
          </div>

          {/* all role */}
          <div className="side-menu-main-bottom-wrapper">
            {this.state.userData !== null &&
              <div
                className={`
                  new-main-side-menu-wrapper
                  ${this.props.sideMenu.activeMenuBlock && this.props.sideMenu.activeMenuBlock === sideMenuList.account.activeValue ? 'active-side-menu' : ''}
                `}
                onClick={() => {
                  mainMenuClick(sideMenuList.account.activeValue, sideMenuList.account.subMenu);

                  this.setState({
                    isActiveChannels: false
                  })
                }}
              >
                <div className="active-white-line" />
                {this.state.userData.account.imageURL.thumbnail !== "" ?
                  <React.Fragment>
                    <img src={this.state.userData.account.imageURL.thumbnail} alt="" className="my-account-avatar image" />
                    <br />
                    <b>My Account</b>
                  </React.Fragment>
                  :
                  <React.Fragment>
                    <div className="my-account-avatar avatar-name">
                      <b>{Helper.renderUserAvatarWord(this.state.userData.account.fullName)}</b>
                    </div>
                    <br />
                    <b>My Account</b>
                  </React.Fragment>
                }
              </div>
            }

            <div className="new-main-side-menu-wrapper" onClick={() => this.togglePopupOrganization()}>
              <img src={iconSwitchOrganization} alt="" />
              <br />
              <b>Switch Organization</b>
            </div>
          </div>
        </div>
        {/* all role */}

        {/* submenu */}
        {this.state.userData !== null &&
          <div
            className={`
              new-sub-side-menu-wrapper 
              ${!isSubmenuExist() ? "deactive-submenu-wrapper" : ""}
              ${(process.env.REACT_APP_HIDE_BILLING !== "true" && this.props.billingWarning) ? "new-sub-side-menu-active-billing-warning" : ""}
            `}
          >
            {/* sub menu all role */}
            <>
              {(isSubmenuExist() && this.state.userData.memberships[this.findOrganization()].roleCode !== "custom") &&
                (
                  (
                    this.props.sideMenu.activeMenuBlock === "integration" ||
                    this.props.sideMenu.activeMenuBlock === "account"
                  ) ?
                    <div className='sub-menu-scroll'>
                      <Scrollbars
                        renderThumbVertical={props => <div {...props} style={style.scrollStyle} />}
                        renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} className="track-horizontal" />}
                      >
                        <div className='sub-menu-scroll-inner'>
                          {Object.keys(this.props.sideMenu.submenuList).map((value, key) => {
                            return (
                              this.state.isActiveChannels ?
                                (
                                  this.props.sideMenu.submenuList[value].isChannel ?
                                    <>
                                      <SubMenuIntegration
                                        onClickSubMenu={onClickSubMenu}
                                        findMediumCounter={findMediumCounter}
                                        mediumData={this.props.mediumData}
                                        checkActiveSubmenu={this.checkActiveSubmenu}
                                        value={value}
                                        _key={key}
                                        onClickBackToMainMenu={this.runBackToMainMenuIntegration}
                                        _isActiveChannels={this.state.isActiveChannels}
                                        orgID={this.props.match.params.orgID}
                                      />
                                    </>
                                    :
                                    ""
                                )
                                :
                                (
                                  !this.props.sideMenu.submenuList[value].isChannel ?
                                    <>
                                      <SubMenuIntegration
                                        onClickSubMenu={onClickSubMenu}
                                        findMediumCounter={findMediumCounter}
                                        mediumData={this.props.mediumData}
                                        checkActiveSubmenu={this.checkActiveSubmenu}
                                        value={value}
                                        _key={key}
                                        orgID={this.props.match.params.orgID}
                                      />
                                    </>
                                    :
                                    ""
                                )
                            )
                          })}
                        </div>
                      </Scrollbars>
                    </div>
                    :
                    Object.keys(this.props.sideMenu.submenuList).map((value, key) => {
                      return (
                        this.props.sideMenu.submenuList[value].role ?
                          this.props.sideMenu.submenuList[value].role.findIndex(val => val === this.findMemberRole()) !== -1 ?
                            //check tier
                            this.props.currentTierDetailProps !== null ?
                              this.props.sideMenu.submenuList[value].ignoreTier ?
                                (this.props.sideMenu.submenuList[value].name === "Billing" ?
                                  (process.env.REACT_APP_HIDE_BILLING !== "true" ?
                                    (!checkIsMenuHide(false, this.props.sideMenu.submenuList[value].name) ?
                                      <NavLink to={this.props.sideMenu.submenuList[value].pathName} key={`sub-menu-${key}`} onClick={() => { onClickSubMenu(this.props.sideMenu.submenuList[value].activeValue) }}>
                                        <div
                                          className={`
                                            sub-menu-content 
                                            ${this.checkActiveSubmenu(this.props.sideMenu.submenuList[value].subPath) ? "active-sub-menu" : ""}
                                            ${checkIsMenuHide(false, this.props.sideMenu.submenuList[value].name) ? "force-hide" : ""}
                                          `}
                                        >
                                          <div className={`sub-menu-icon  ${value}`}></div>
                                          <b>{this.props.sideMenu.submenuList[value].name}</b>
                                        </div>
                                      </NavLink>
                                      :
                                      ""
                                    )
                                    :
                                    ""
                                  )
                                  :
                                  (!checkIsMenuHide(false, this.props.sideMenu.submenuList[value].name) ?
                                    <NavLink to={this.props.sideMenu.submenuList[value].pathName} key={`sub-menu-${key}`} onClick={() => { onClickSubMenu(this.props.sideMenu.submenuList[value].activeValue) }}>
                                      <div
                                        className={`
                                          sub-menu-content 
                                          ${this.checkActiveSubmenu(this.props.sideMenu.submenuList[value].subPath) ? "active-sub-menu" : ""}
                                          ${checkIsMenuHide(false, this.props.sideMenu.submenuList[value].name) ? "force-hide" : ""}
                                        `}
                                      >
                                        <div className={`sub-menu-icon  ${value}`}></div>
                                        <b>{this.props.sideMenu.submenuList[value].name}</b>
                                      </div>
                                    </NavLink>
                                    :
                                    ""
                                  )
                                )
                                :
                                (!checkIsMenuHide(false, this.props.sideMenu.submenuList[value].name) ?
                                  ((this.props.sideMenu.submenuList[value].name === "Broadcast Message") ?
                                    (((this.props.match.params.orgID.split("-")[0] === "5369" || this.props.match.params.orgID.split("-")[0] === "6919") && this.findMemberRole() !== ROLE_CODE.owner) ?
                                      ""
                                      :
                                      <>
                                        {(!this.props.sideMenu.submenuList[value].ignoreTier && this.props.currentTierDetailProps.id !== 0 && this.props.currentTierDetailProps.isActive) ?
                                          <NavLink to={this.props.sideMenu.submenuList[value].pathName} key={`sub-menu-${key}`} onClick={() => { onClickSubMenu(this.props.sideMenu.submenuList[value].activeValue) }}>
                                            <div
                                              className={`
                                          sub-menu-content 
                                          ${this.checkActiveSubmenu(this.props.sideMenu.submenuList[value].subPath) ? "active-sub-menu" : ""}
                                          ${checkIsMenuHide(false, this.props.sideMenu.submenuList[value].name) ? "force-hide" : ""}
                                        `}
                                            >
                                              <div className={`sub-menu-icon  ${value}`}></div>
                                              <b>{this.props.sideMenu.submenuList[value].name}</b>
                                            </div>
                                          </NavLink>
                                          :
                                          null
                                        }
                                      </>
                                    )
                                    :
                                    <>
                                      {(!this.props.sideMenu.submenuList[value].ignoreTier && this.props.currentTierDetailProps.id !== 0 && this.props.currentTierDetailProps.isActive) ?
                                        <NavLink to={this.props.sideMenu.submenuList[value].pathName} key={`sub-menu-${key}`} onClick={() => { onClickSubMenu(this.props.sideMenu.submenuList[value].activeValue) }}>
                                          <div
                                            className={`
                                            sub-menu-content 
                                            ${this.checkActiveSubmenu(this.props.sideMenu.submenuList[value].subPath) ? "active-sub-menu" : ""}
                                            ${checkIsMenuHide(false, this.props.sideMenu.submenuList[value].name) ? "force-hide" : ""}
                                          `}
                                          >
                                            <div className={`sub-menu-icon  ${value}`}></div>
                                            <b>{this.props.sideMenu.submenuList[value].name}</b>
                                          </div>
                                        </NavLink>
                                        :
                                        null
                                      }
                                    </>
                                  )
                                  :
                                  ""
                                )
                              : // this.props.currentTierDetailProps !== null ?
                              null
                            : // this.props.sideMenu.submenuList[value].role.findIndex(val => val === this.findMemberRole()) !== -1 ?
                            null
                          : // this.props.sideMenu.submenuList[value].role ?
                          resetSubMenu()
                      )
                    })
                )
              }
            </>
            {/* sub menu all role */}

            {/* sub menu custom role */}
            <>
              {(isSubmenuExist() && this.state.userData.memberships[this.findOrganization()].roleCode === "custom") &&
                (
                  (
                    this.props.sideMenu.activeMenuBlock === "integration"
                  ) ?
                    <div className='sub-menu-scroll'>
                      <Scrollbars
                        renderThumbVertical={props => <div {...props} style={style.scrollStyle} />}
                        renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} className="track-horizontal" />}
                      >
                        <div className='sub-menu-scroll-inner'>
                          {Object.keys(this.props.sideMenu.submenuList).map((value, key) => {
                            return (
                              this.state.isActiveChannels ?
                                (
                                  this.props.sideMenu.submenuList[value].isChannel ?
                                    <>
                                      <SubMenuIntegration
                                        onClickSubMenu={onClickSubMenu}
                                        findMediumCounter={findMediumCounter}
                                        mediumData={this.props.mediumData}
                                        checkActiveSubmenu={this.checkActiveSubmenu}
                                        value={value}
                                        _key={key}
                                        onClickBackToMainMenu={this.runBackToMainMenuIntegration}
                                        _isActiveChannels={this.state.isActiveChannels}
                                        _allowedModules={this.state.userData.memberships[this.findOrganization()].allowedModules}
                                        orgID={this.props.match.params.orgID}
                                      />
                                    </>
                                    :
                                    ""
                                )
                                :
                                (
                                  !this.props.sideMenu.submenuList[value].isChannel ?
                                    <>
                                      <SubMenuIntegration
                                        onClickSubMenu={onClickSubMenu}
                                        findMediumCounter={findMediumCounter}
                                        mediumData={this.props.mediumData}
                                        checkActiveSubmenu={this.checkActiveSubmenu}
                                        value={value}
                                        _key={key}
                                        _allowedModules={this.state.userData.memberships[this.findOrganization()].allowedModules}
                                        orgID={this.props.match.params.orgID}
                                      />
                                    </>
                                    :
                                    ""
                                )
                            )
                          })}
                        </div>
                      </Scrollbars>
                    </div>
                    :
                    Object.keys(this.props.sideMenu.submenuList).map((value, key) => {
                      return (
                        this.props.sideMenu.submenuList[value].role ?
                          this.props.sideMenu.submenuList[value].role.findIndex(val => val === this.findMemberRole()) !== -1 ?
                            //check tier
                            this.props.currentTierDetailProps !== null ?
                              this.props.sideMenu.submenuList[value].ignoreTier ?
                                (this.props.sideMenu.submenuList[value].name === "Billing" ?
                                  (process.env.REACT_APP_HIDE_BILLING !== "true" ?
                                    (!checkIsMenuHide(false, this.props.sideMenu.submenuList[value].name) ?
                                      (isSubModuleAllowed(this.props.sideMenu.submenuList[value].customRoleKey) &&
                                        <NavLink to={this.props.sideMenu.submenuList[value].pathName} key={`sub-menu-${key}`} onClick={() => { onClickSubMenu(this.props.sideMenu.submenuList[value].activeValue) }}>
                                          <div
                                            className={`
                                              sub-menu-content 
                                              ${this.checkActiveSubmenu(this.props.sideMenu.submenuList[value].subPath) ? "active-sub-menu" : ""}
                                              ${checkIsMenuHide(false, this.props.sideMenu.submenuList[value].name) ? "force-hide" : ""}
                                            `}
                                          >
                                            <div className={`sub-menu-icon  ${value}`}></div>
                                            <b>{this.props.sideMenu.submenuList[value].name}</b>
                                          </div>
                                        </NavLink>
                                      )
                                      :
                                      ""
                                    )
                                    :
                                    ""
                                  )
                                  :
                                  (!checkIsMenuHide(false, this.props.sideMenu.submenuList[value].name) ?
                                    (isSubModuleAllowed(this.props.sideMenu.submenuList[value].customRoleKey) &&
                                      <NavLink to={this.props.sideMenu.submenuList[value].pathName} key={`sub-menu-${key}`} onClick={() => { onClickSubMenu(this.props.sideMenu.submenuList[value].activeValue) }}>
                                        <div
                                          className={`
                                          sub-menu-content 
                                          ${this.checkActiveSubmenu(this.props.sideMenu.submenuList[value].subPath) ? "active-sub-menu" : ""}
                                          ${checkIsMenuHide(false, this.props.sideMenu.submenuList[value].name) ? "force-hide" : ""}
                                        `}
                                        >
                                          <div className={`sub-menu-icon  ${value}`}></div>
                                          <b>{this.props.sideMenu.submenuList[value].name}</b>
                                        </div>
                                      </NavLink>
                                    )
                                    :
                                    ""
                                  )
                                )
                                :
                                (!checkIsMenuHide(false, this.props.sideMenu.submenuList[value].name) ?
                                  ((this.props.sideMenu.submenuList[value].name === "Broadcast Message" || this.props.sideMenu.submenuList[value].name === "Import Contacts") ?
                                    ((this.props.match.params.orgID.split("-")[0] === "5369" && this.findMemberRole() !== ROLE_CODE.owner) ?
                                      ""
                                      :
                                      <>
                                        {(!this.props.sideMenu.submenuList[value].ignoreTier && this.props.currentTierDetailProps.id !== 0 && this.props.currentTierDetailProps.isActive) ?
                                          (isSubModuleAllowed(this.props.sideMenu.submenuList[value].customRoleKey) &&
                                            <NavLink to={this.props.sideMenu.submenuList[value].pathName} key={`sub-menu-${key}`} onClick={() => { onClickSubMenu(this.props.sideMenu.submenuList[value].activeValue) }}>
                                              <div
                                                className={`
                                                sub-menu-content 
                                                ${this.checkActiveSubmenu(this.props.sideMenu.submenuList[value].subPath) ? "active-sub-menu" : ""}
                                                ${checkIsMenuHide(false, this.props.sideMenu.submenuList[value].name) ? "force-hide" : ""}
                                              `}
                                              >
                                                <div className={`sub-menu-icon  ${value}`}></div>
                                                <b>{this.props.sideMenu.submenuList[value].name}</b>
                                              </div>
                                            </NavLink>
                                          )
                                          :
                                          null
                                        }
                                      </>
                                    )
                                    :
                                    <>
                                      {(!this.props.sideMenu.submenuList[value].ignoreTier && this.props.currentTierDetailProps.id !== 0 && this.props.currentTierDetailProps.isActive) ?
                                        (isSubModuleAllowed(this.props.sideMenu.submenuList[value].customRoleKey) &&
                                          <NavLink to={this.props.sideMenu.submenuList[value].pathName} key={`sub-menu-${key}`} onClick={() => { onClickSubMenu(this.props.sideMenu.submenuList[value].activeValue) }}>
                                            <div
                                              className={`
                                            sub-menu-content 
                                            ${this.checkActiveSubmenu(this.props.sideMenu.submenuList[value].subPath) ? "active-sub-menu" : ""}
                                            ${checkIsMenuHide(false, this.props.sideMenu.submenuList[value].name) ? "force-hide" : ""}
                                          `}
                                            >
                                              <div className={`sub-menu-icon  ${value}`}></div>
                                              <b>{this.props.sideMenu.submenuList[value].name}</b>
                                            </div>
                                          </NavLink>
                                        )
                                        :
                                        null
                                      }
                                    </>
                                  )
                                  :
                                  ""
                                )
                              : // this.props.currentTierDetailProps !== null ?
                              null
                            : // this.props.sideMenu.submenuList[value].role.findIndex(val => val === this.findMemberRole()) !== -1 ?
                            null
                          : // this.props.sideMenu.submenuList[value].role ?
                          resetSubMenu()
                      )
                    })
                )
              }
            </>
            {/* sub menu custom role */}

            {/* 
            {(this.props.sideMenu.activeMenuBlock === "organization" && this.props.balanceData) &&
              <div className="your-channel-limit">
                <p>
                  <b>Balance</b>
                </p>

                <b className="channel-limit">{getBalance()}</b>
              </div>
            } */}

            {(this.props.sideMenu.activeMenuBlock === "integration" && this.props.mediumData && this.state.isActiveChannels) &&
              <div className="your-channel-limit">
                <p>
                  <b>Your Channels</b>
                </p>

                <b className="channel-limit">{this.props.mediumData.numberOfItems.current}/{this.props.mediumData.numberOfItems.maximum !== -1 ? this.props.mediumData.numberOfItems.maximum : "Unlimited"} channels</b>
              </div>
            }
          </div>
        }
        {/* submenu */}

        {/* organization list */}
        {this.state.userData !== null &&
          <div className={`organization-popup-wrapper ${this.state.showPopupOrganization ? '' : 'deactive-organization-popup-wrapper'}`}>
            <div className="popup-organization-content-info popup-top-content">
              <p className="popup-organization-content-info-name">
                <b>{this.state.userData !== null && this.state.userData.account.fullName}</b>
              </p>

              <p className="popup-organization-content-info-email">
                {this.state.userData !== null && this.state.userData.account.email}
              </p>
            </div>

            <div className={`popup-oganization-list`}>
              <ul>
                {this.state.userData.memberships.map((value, index) => {
                  return (
                    <li
                      key={`organization-list-${index}`}
                      className={this.isActiveOrganizationCheck(value.organization.id) ? 'active-organization' : ''}
                    >
                      <a href={`/o/${value.organization.id}-${value.organization.createdTime}/home`} onClick={() => {
                        this.props.setParentMenuBlockActive({
                          activeMenuBlock: "home",
                          submenuList: null
                        })
                      }}>
                        <div className="organization-link-wrapper">
                          <IoIosCheckmark />
                          {value.organization.name}
                        </div>
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>

            {(this.state.userData.canCreateOrganization) &&
              <NavLink to="/organization/new">
                <div className="popup-organization-content-wrapper new-organization-wrapper">
                  <b>New Organization</b>
                  <IoIosAdd />
                </div>
              </NavLink>
            }

            <div onClick={() => this.runLogoutAction()} className="popup-organization-content-wrapper logout-wrapper">
              <b>Logout</b>
            </div>
          </div>
        }
        {/* organizationlist  */}
      </React.Fragment >
    );
  }
}

const mapStateToProps = state => ({
  sideMenu: state.sideMenu,
  user: state.user,
  activeOrgID: state.activeOrgID,
  billingWarning: state.billingWarning,
  allOrganizationDetail: state.allOrganizationDetail,
  mediumData: state.mediumData,
  balanceData: state.balanceData,
  membershipData: state.membershipData
});

const mapDispatchToProps = {
  setParentMenuBlockActive,
  setLogout,
  setBalanceData,
  setAuthActive,
  setMembershipData,
  setAllowedModule
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidemenu)