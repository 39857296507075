import React, { useState, useEffect } from "react";
import "./IntegrationChatGPTSetupPromptSetting.scss";
import { connect } from 'react-redux';
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import NextButton from "../../../reuseableComponent/NextButton/NextButton";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import CustomSelectOption from "../../../reuseableComponent/customSelectOption/CustomSelectOption";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import { FiAlertCircle, FiInfo, FiPlus, FiTrash2 } from "react-icons/fi";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import SelectBulk from "../../../reuseableComponent/selectBulk/SelectBulk";
import { doToast, scrollToClass } from "../../../../helper/HelperGeneral";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ChatbotServices from "../../../../services/newServices/ChatbotService";
import PopupDiscard from "../../../reuseableComponent/popupDiscard/PopupDiscard";

const IntegrationChatGPTSetupPromptSetting = (props) => {

    const onSelectModel = (model) => {
        let promptSettings = { ...props.promptSettings };
        if (promptSettings.model.value === model.value) {
            return;
        }
        promptSettings.model = model;
        props.setPromptSettings(promptSettings);
        let _errorMessageData = { ...errorMessageData };
        _errorMessageData.model = "";
        setErrorMessageData(_errorMessageData);
    }

    const onSelectTone = (tone) => {
        let promptSettings = { ...props.promptSettings };
        if (promptSettings.tone.value === tone.value) {
            return;
        }
        promptSettings.tone = tone;
        props.setPromptSettings(promptSettings);
        let _errorMessageData = { ...errorMessageData };
        _errorMessageData.tone = "";
        setErrorMessageData(_errorMessageData);
    }

    const onSelectLanguage = (language) => {
        let promptSettings = { ...props.promptSettings };
        if (promptSettings.language.value === language.value) {
            promptSettings.language = {
                id: "",
                code: "",
                value: "",
                name: "",
                label: "",
            };
        }
        else {
            promptSettings.language = language;
        }
        props.setPromptSettings(promptSettings);
    }

    const onChangeGreetings = (e, index) => {
        let val = e.target.value;

        let promptSettings = { ...props.promptSettings };
        promptSettings.greetings[index] = val;
        props.setPromptSettings(promptSettings);

        if (val) {
            let _errorMessageData = { ...errorMessageData };
            if (_errorMessageData.greetings.length > index) {
                _errorMessageData.greetings[index] = "";
                setErrorMessageData(_errorMessageData);
            }
        }
    }

    const resizeGreetingsTextArea = (index) => {
        const textarea = document.getElementById(`greetings-${index}`);
        if (!textarea) {
            return;
        }
        textarea.style.height = "1px"; // Reset height
        if (textarea.scrollHeight <= 48) {
            textarea.style.height = "48px";
        }
        else {
            textarea.style.height = "" + textarea.scrollHeight + "px";
        }
    }

    const addGreetingMessage = () => {
        let promptSettings = { ...props.promptSettings };
        if (promptSettings.greetings.length >= 5) {
            return;
        }
        promptSettings.greetings.push("");
        props.setPromptSettings(promptSettings);
        let _errorMessageData = { ...errorMessageData };
        _errorMessageData.greetings.push("");
        setErrorMessageData(_errorMessageData);
    }

    const removeGreetingMessage = (index) => {
        let promptSettings = { ...props.promptSettings };
        if (promptSettings.greetings.length <= 1) {
            return;
        }
        promptSettings.greetings.splice(index, 1);
        props.setPromptSettings(promptSettings);
        let _errorMessageData = { ...errorMessageData };
        _errorMessageData.greetings.splice(index, 1);
        setErrorMessageData(_errorMessageData);
    }

    const onChangeSystemPrompt = (e) => {
        let val = e.target.value;
        let promptSettings = { ...props.promptSettings };
        promptSettings.systemPrompt = val;
        props.setPromptSettings(promptSettings);
        resizeSystemPromptTextArea();
    }

    const resizeSystemPromptTextArea = () => {
        const elements = document.getElementsByClassName("input-system-prompt");
        if (!elements) {
            return;
        }
        const textarea = elements[0];
        if (!textarea) {
            return;
        }
        textarea.style.height = "1px"; // Reset height
        if (textarea.scrollHeight <= 96) {
            textarea.style.height = "96px";
        }
        else {
            textarea.style.height = "" + textarea.scrollHeight + "px";
        }
    }

    const validateContinue = () => {
        let promptSettings = { ...props.promptSettings };
        let _errorMessageData = { ...errorMessageData };
        let errorClass = false;

        if (!promptSettings.model.value) {
            _errorMessageData.model = "This field is required";
            if (!errorClass) {
                errorClass = "dropdown-model";
            }
        }

        if (!promptSettings.tone.value) {
            _errorMessageData.tone = "This field is required";
            if (!errorClass) {
                errorClass = "dropdown-tone";
            }
        }

        promptSettings.greetings.map((value, index) => {
            if (!value) {
                if (_errorMessageData.greetings.length > index) {
                    _errorMessageData.greetings[index] = "This field is required";
                }
                if (!errorClass) {
                    errorClass = `greetings-list-${index}`;
                }
            }
        });

        setErrorMessageData(_errorMessageData);

        if (errorClass) {
            scrollToClass(`.${errorClass}`);
        }
        else {
            props.savePromptSettings();
        }
    }

    const DEFAULT_TONE_LIST_ARRAY = [
        {
            id: "casual",
            code: "casual",
            value: "casual",
            name: "Casual",
            label: "Casual",
        },
        {
            id: "formal",
            code: "formal",
            value: "formal",
            name: "Formal",
            label: "Formal",
        },
    ];

    const DEFAULT_LANGUAGE_LIST_ARRAY = [
        {
            id: "en",
            code: "en",
            value: "en",
            name: "English",
            label: "English",
        },
        {
            id: "id",
            code: "id",
            value: "id",
            name: "Indonesian",
            label: "Indonesian",
        },
    ];

    const fetchPromptSettingsOptionsAndSavedData = () => {
        setIsLoadingOptions(true);

        // Fetch options
        ChatbotServices.getChatGptPromptSettingsOptions(props.match.params.orgID, (response) => {
            let dataResult = response.dataResult;
            if (dataResult.error.message === "") {
                let models = dataResult.data.models;
                let mappedModels = [];
                let modelHashMap = {};
                if (models && models.length > 0) {
                    models.map((model) => {
                        model.id = model.code;
                        model.value = model.code;
                        model.label = model.name;
                        mappedModels.push(model);
                        modelHashMap[model.code] = model;
                    });
                    setModelOptions(mappedModels);
                }
                let tones = dataResult.data.tones;
                let mappedTones = [];
                let toneHashMap = {};
                if (tones && tones.length > 0) {
                    tones.map((tone) => {
                        tone.id = tone.code;
                        tone.value = tone.code;
                        tone.label = tone.name;
                        mappedTones.push(tone);
                        toneHashMap[tone.code] = tone;
                    });
                    setToneOptions(mappedTones);
                }
                let languages = dataResult.data.languages;
                let mappedLanguages = [];
                let languageHashMap = {};
                if (languages && languages.length > 0) {
                    languages.map((language) => {
                        language.id = language.code;
                        language.value = language.code;
                        language.label = language.name;
                        mappedLanguages.push(language);
                        languageHashMap[language.code] = language;
                    });
                    setLanguageOptions(mappedLanguages);
                    setBulkList(mappedLanguages);
                    setBulkListOrigin(mappedLanguages);
                }

                // Fetch saved data
                let data = {
                    "chatbotID": Number(props.match.params.id)
                };
                ChatbotServices.getChatGptSavedPromptSettings(props.match.params.orgID, data, (response) => {
                    let dataResult = response.dataResult;
                    if (dataResult.error.message === "") {
                        let promptSettings = { ...props.promptSettings };
                        let data = dataResult.data;
                        if (data.modelCode && modelHashMap[data.modelCode]) {
                            promptSettings.model = modelHashMap[data.modelCode];
                        }
                        if (data.toneCode && toneHashMap[data.toneCode]) {
                            promptSettings.tone = toneHashMap[data.toneCode];
                        }
                        if (data.defaultLanguage && languageHashMap[data.defaultLanguage]) {
                            promptSettings.language = languageHashMap[data.defaultLanguage];
                        }
                        if (data.supportedLanguages && data.supportedLanguages.length > 0) {
                            promptSettings.supportedLanguages = [];
                            data.supportedLanguages.map((languageCode) => {
                                if (languageHashMap[languageCode]) {
                                    promptSettings.supportedLanguages.push(languageHashMap[languageCode]);
                                }
                            });
                        }
                        if (data.greetings && data.greetings.length > 0) {
                            promptSettings.greetings = data.greetings;
                        }
                        if (data.systemPrompt) {
                            promptSettings.systemPrompt = data.systemPrompt;
                        }
                        props.setPromptSettings(promptSettings);
                        if (!promptSettingsOrigin) {
                            setPromptSettingsOrigin(JSON.stringify(promptSettings));
                        }
                    }
                    else {
                        doToast(`Failed to get saved prompt settings: ${dataResult.error.message}`, 'fail');
                    }
                    setIsLoadingOptions(false);
                });
            }
            else {
                setIsLoadingOptions(false);
                doToast(`Failed to get prompt settings options: ${dataResult.error.message}`, 'fail');
            }
        });
    }

    const onChangeSearchBulk = (e) => {
        let _bulkOrigin = bulkListOrigin.slice();
        let result = [];
        setSearchBulk(e.target.value);

        if (_bulkOrigin.length > 0) {
            for (let i in _bulkOrigin) {
                let objectKey = Object.keys(_bulkOrigin[i]);

                for (let j in objectKey) {
                    if(objectKey[j] === "label" || objectKey[j] === "name") {
                        let isString = typeof _bulkOrigin[i][objectKey[j]] === "string";
                        if (_bulkOrigin[i][objectKey[j]].toString().toLowerCase().includes(e.target.value.toLowerCase()) && isString) {
                            result.push(_bulkOrigin[i])
                            break;
                        }
                    }
                }
            }
        }

        setBulkList(result);
    }

    const onChangeSingleSelect = (e, val) => {
        if (!props.isLoadingSave) {
            let _prompt = {...props.promptSettings};
    
            if (e.target.checked) {
                _prompt.supportedLanguages = [..._prompt.supportedLanguages, val];
            }
            else {
                _prompt.supportedLanguages = _prompt.supportedLanguages.filter(el => el.id !== val.id)
            }
    
            props.setPromptSettings(_prompt);
        }
    }

    const removeSingleBulk = (val) => {
        if (!props.isLoadingSave) {
            let _prompt = {...props.promptSettings};
            
            _prompt.supportedLanguages = _prompt.supportedLanguages.filter(el => el.id !== val.id);
            props.setPromptSettings(_prompt);
        }
    }

    const isChecked = (id) => {
        const _data = {...props.promptSettings};
        return _data.supportedLanguages.findIndex(val => val.id === id) !== -1;
    }

    const onClearAll = () => {
        if (!props.isLoadingSave) {
            let _prompt = {...props.promptSettings};
            _prompt.supportedLanguages = [];
            props.setPromptSettings(_prompt);
        }
    }

    const onCheckAll = (e) => {
        if (!props.isLoadingSave) {
            let _prompt = {...props.promptSettings};

            if (e.target.checked) {
                _prompt.supportedLanguages = bulkList.slice();
            }
            else {
                _prompt.supportedLanguages = [];
            }
            props.setPromptSettings(_prompt);
        }
    }

    const toggleModalDiscard = () => {
        setIsShowModalDiscard(!isShowModalDiscard);
    }

    let [modelOptions, setModelOptions] = useState([]);
    let [toneOptions, setToneOptions] = useState(DEFAULT_TONE_LIST_ARRAY.slice());
    let [languageOptions, setLanguageOptions] = useState(DEFAULT_LANGUAGE_LIST_ARRAY.slice());
    let [promptSettingsOrigin, setPromptSettingsOrigin] = useState("");
    let [searchBulk, setSearchBulk] = useState("");
    let [bulkListOrigin, setBulkListOrigin] = useState(DEFAULT_LANGUAGE_LIST_ARRAY.slice());
    let [bulkList, setBulkList] = useState(DEFAULT_LANGUAGE_LIST_ARRAY.slice());
    let [isLoadingOptions, setIsLoadingOptions] = useState(false);
    let [errorMessageData, setErrorMessageData] = useState({
        model: "",
        tone: "",
        greetings: [],
    });
    let [isShowModalDiscard, setIsShowModalDiscard] = useState(false);

    useEffect(() => {
        fetchPromptSettingsOptionsAndSavedData();
    }, [props.match.params.orgID]);

    useEffect(() => {
        setTimeout(() => {
            props.promptSettings.greetings.map((value, index) => {
                resizeGreetingsTextArea(index);
            });
            resizeSystemPromptTextArea();
        }, 0);
        let _errorMessageData = { ...errorMessageData };
        _errorMessageData.greetings.length = props.promptSettings.greetings.length;
        setErrorMessageData(_errorMessageData);
    }, [props.promptSettings]);

    return (
        <>
            <SectionWrap
                {...props}
                orgID={props.match.params.orgID}
                withHeader
                withSideMenu
                noWrap
                dataOrigin={promptSettingsOrigin}
                dataAfterChange={JSON.stringify(props.promptSettings)}
            >
                <div className="nav-button-wrapper">
                    <BackButton
                        onClick={() => {
                            if (promptSettingsOrigin !== JSON.stringify(props.promptSettings)) {
                                setIsShowModalDiscard(true);
                            }
                            else {
                                props.setActiveTab(0);
                            }
                        }}
                        text={"Back to Knowledge Base"}
                    />
                    <NextButton
                        onClick={validateContinue}
                        // text={"Continue to Leads Generation"}
                        text={"Continue to Submission"}
                    />
                </div>

                <div className="sectionWrap">
                    <b className="prompt-settings-title">Prompt Settings</b>
                    <p className="prompt-settings-description">Customize how chatbot generates responses, help determine behaviors and styles for chatbot’s style to fit different needs.</p>

                    {
                        isLoadingOptions ?
                            <RetrieveDataLoading
                                isLoading={isLoadingOptions}
                                errorMessage=""
                                errorCode=""
                            />
                            :
                            <div className="form-content-wrapper">
                                <div className="dropdown-model">
                                    <label><b>Model</b></label>
                                    <CustomSelectOption
                                        optionListProps={modelOptions}
                                        valueDropdownProps={props.promptSettings.model}
                                        placeholderProps={"Please select model"}
                                        onClickDropDownListOptionProps={onSelectModel}
                                        isDisabled={props.isLoadingSave}
                                        _className={`${errorMessageData.model ? "border-red" : ""}`}
                                    />
                                    {
                                        errorMessageData.model ?
                                            <ErrorOrInfoComp
                                                text={errorMessageData.model}
                                                _className={"font-red"}
                                                icon={<FiAlertCircle />}
                                            />
                                            :
                                            <ErrorOrInfoComp
                                                text={`To know more about GPT Model, <a href="https://platform.openai.com/docs/models" target="_blank" rel="noopener noreferrer">click here</a>`}
                                                icon={<FiInfo />}
                                            />
                                    }
                                </div>

                                <div className="dropdown-tone">
                                    <label><b>Tone</b></label>
                                    <CustomSelectOption
                                        optionListProps={toneOptions}
                                        valueDropdownProps={props.promptSettings.tone}
                                        placeholderProps={"Please select tone"}
                                        onClickDropDownListOptionProps={onSelectTone}
                                        isDisabled={props.isLoadingSave}
                                        _className={`${errorMessageData.tone ? "border-red" : ""}`}
                                    />
                                    {
                                        errorMessageData.tone &&
                                        <ErrorOrInfoComp
                                            text={errorMessageData.tone}
                                            _className={"font-red"}
                                            icon={<FiAlertCircle />}
                                        />
                                    }
                                </div>

                                <div className="dropdown-language">
                                    <label>
                                        <b>Default Language</b>
                                        <span className="optional"> (Optional)</span>
                                    </label>
                                    <CustomSelectOption
                                        optionListProps={languageOptions}
                                        valueDropdownProps={props.promptSettings.language}
                                        placeholderProps={"Please select language"}
                                        onClickDropDownListOptionProps={onSelectLanguage}
                                        isDisabled={props.isLoadingSave}
                                    />

                                    <SelectBulk
                                        onChangeSearchBulk={onChangeSearchBulk}
                                        onClearAll={onClearAll}
                                        bulkList={bulkList}
                                        onChangeSingleSelect={onChangeSingleSelect}
                                        isChecked={isChecked}
                                        isCheckAll={props.promptSettings.supportedLanguages.length > 0}
                                        searchBulk={searchBulk}
                                        title={"Supported Languages"}
                                        selectedBulk={props.promptSettings.supportedLanguages}
                                        removeSingleBulk={removeSingleBulk}
                                        selectedTitle={"Selected languages will be displayed here."}
                                        isTitleOptional
                                        searchTitle={"Search for languages"}
                                        onCheckAll={onCheckAll}
                                        errorText={""}
                                        isDisabled={props.isLoadingSave}
                                    />
                                </div>

                                <div className="greetings-wrapper">
                                    <label><b>Greetings</b></label>
                                    {
                                        props.promptSettings.greetings.map((value, index) => {
                                            return (
                                                <div className={`greetings-list greetings-list-${index}`}>
                                                    <textarea
                                                        disabled={props.isLoadingSave}
                                                        id={`greetings-${index}`}
                                                        onChange={(e) => onChangeGreetings(e, index)}
                                                        value={value}
                                                        placeholder="Insert greeting message"
                                                        className={`input-greetings ${errorMessageData.greetings[index] ? "border-red" : ""}`}
                                                    />
                                                    {
                                                        props.promptSettings.greetings.length > 1 &&
                                                        <FiTrash2
                                                            className="icon-remove-greeting"
                                                            onClick={() => { removeGreetingMessage(index) }}
                                                        />
                                                    }
                                                    {
                                                        errorMessageData.greetings[index] &&
                                                        <ErrorOrInfoComp
                                                            text={errorMessageData.greetings[index]}
                                                            _className={"font-red add-margin-bottom"}
                                                            icon={<FiAlertCircle />}
                                                        />
                                                    }
                                                </div>
                                            )
                                        })
                                    }

                                    {
                                        props.promptSettings.greetings.length < 5 &&
                                        <ButtonWithLoadingOrIcon
                                            text="Add another greeting message"
                                            onClickAction={addGreetingMessage}
                                            className="main-button-26 no-fill-button-no-border button-add-greeting"
                                            icon={{
                                                type: "svg",
                                                src: FiPlus
                                            }}
                                            position="left"
                                        />
                                    }
                                </div>

                                <div className="system-prompt-wrapper">
                                    <label>
                                        <b>System Prompt</b>
                                        <span className="optional"> (Optional)</span>
                                    </label>
                                    <p className="system-prompt-description">Help ChatGPT defines expected behavior and ensures relevance by providing guidelines for responses, instructions, constraints, or objectives.</p>
                                    <textarea
                                        disabled={props.isLoadingSave}
                                        onChange={(e) => onChangeSystemPrompt(e)}
                                        value={props.promptSettings.systemPrompt}
                                        placeholder="Insert system prompt here"
                                        className={`input-system-prompt`}
                                    />
                                    <ErrorOrInfoComp
                                        text="E.g: ‘When giving answer to user question, make it brief and easy to understand’"
                                        icon={<FiInfo />}
                                    />
                                </div>
                            </div>
                    }
                </div>
            </SectionWrap>

            <PopupDiscard
                dataOrigin={promptSettingsOrigin}
                dataAfterChange={JSON.stringify(props.promptSettings)}
                isOpenDiscard={isShowModalDiscard}
                toggleDiscard={toggleModalDiscard}
                discardProgress={() => {
                    setIsShowModalDiscard(false);
                    props.setActiveTab(0);
                }}
                discardDesc={props.discardDesc}
            />
        </>
    )
}

const mapStateToProps = state => ({
    allowedModule: state.allowedModule
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationChatGPTSetupPromptSetting)
