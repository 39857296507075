import React, { useEffect, useState } from "react";
import "./InboxSetting.scss";
import { checkID } from "../../../helper/HelperGeneral";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import InboxConfigServices from "../../../services/newServices/InboxConfigServices";
import SlaServices from "../../../services/newServices/SlaServices";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import iconXgrey from '../../../assets/img/integration/icon-x-grey.svg';
import statusConnected from '../../../assets/img/integration/status-connected.svg';
import mixpanel from "mixpanel-browser";
import { FiEdit } from "react-icons/fi";
import { NavLink } from "react-router-dom";

const InboxSetting = (props) => {
    let [val, setVal] = useState(false);
    let [valNeedReply, setValNeedReply] = useState(false);
    let [isWaitingFetch, setIsWaitingFetch] = useState(true);
    let [retriveDataError, setRetrieveDataError] = React.useState({
        code: false,
        message: false
    });
    let [orgID, setOrgID] = React.useState(false);
    let [valSla, setValSla] = React.useState({});

    useEffect(() => {
        doMount();
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

        if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
            mixpanel.track(
                "[Page] Inbox Setting",
                {
                    userFullName: myAgentData.account.fullName,
                    userID: myAgentData.account.id,
                    useStateorganizationID: props.match.params.orgID.split("-")[0]
                }
            );
        }
    }, [])

    useEffect(() => {
        if (orgID) {
            getData();
        }
    }, [orgID])

    useEffect(() => {
        if (val) {
            getNeedReply();
            getSla();
        }
    }, [val])

    const doMount = async () => {
        const paramID = checkID(props.match);

        if (paramID) {
            setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    props.history.push(`/o/${urlOrgID}/setup/inbox-settings`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    let getData = () => {
        let _retriveDataError = { ...retriveDataError };
        setIsWaitingFetch(true);

        InboxConfigServices.getConfig(orgID, (response) => {
            let dataResult = response.dataResult;

            if (dataResult.error.message === "") {
                setVal(dataResult.data.inboxConfigs);
            } else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
                setIsWaitingFetch(false);
            }
        })
    }

    let getNeedReply = () => {
        let _retriveDataError = { ...retriveDataError };
        setIsWaitingFetch(true);
        InboxConfigServices.getNeedReplyConfig(orgID, (response) => {
            let dataResult = response.dataResult;

            if (dataResult.error.message === "") {
                setValNeedReply(dataResult.data.needReplyIndicator);
            } else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }

            // setIsWaitingFetch(false);
        })
    }

    let getSla = () => {
        let _retriveDataError = { ...retriveDataError };
        setIsWaitingFetch(true);
        SlaServices.getSla(orgID, (response) => {
            let dataResult = response.dataResult;

            if (dataResult.error.message === "") {
                setValSla(dataResult.data.config.firstResponseSLA);
            } else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }

            setIsWaitingFetch(false);
        })
    }

    // let setSetting = () => {
    //     if(retriveDataError.code !== "49901") {
    //         setIsWaitingSet(true);

    //         let data = {
    //             showTabOthers: val.showTabOthers
    //         }

    //         InboxConfigServices.setConfig(orgID, data, (response) => {
    //             setIsWaitingSet(false);
    //             let dataResult = response.dataResult;

    //             if(dataResult.error.message === "") {
    //                 setValOrigin(val);

    //                 let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

    //                 if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
    //                     mixpanel.track(
    //                         "[Action] Set Inbox Setting",
    //                         {
    //                             status : val.showTabOthers ? "On" : "Off",
    //                             userFullName : myAgentData.account.fullName,
    //                             userID : myAgentData.account.id,
    //                             useStateorganizationID : props.match.params.orgID.split("-")[0]
    //                         }
    //                     );
    //                 }

    //                 doToast(dataResult.data.message);
    //             }else {
    //                 doToast(dataResult.error.message, "fail");
    //             }
    //         })
    //     }
    // }

    return (
        <SectionWrap
            {...props}
            orgID={orgID}
            withHeader
            withSideMenu
        >
            <div className="inbox-settings sectionWrap">
                {/* tier blocking */}
                {retriveDataError.code === "49901" &&
                    <RetrieveDataLoading
                        isLoading={isWaitingFetch}
                        errorMessage={retriveDataError.message}
                        errorCode={retriveDataError.code}
                    />
                }
                {/* tier blocking */}

                <React.Fragment>
                    <div className="inbox-settings-header">
                        <b>Inbox Settings</b>
                    </div>

                    {(isWaitingFetch || (retriveDataError.code && retriveDataError.code !== "49901")) ?
                        <RetrieveDataLoading
                            isLoading={isWaitingFetch}
                            errorMessage={retriveDataError.message}
                        />
                        :
                        <div className="inbox-settings-form">
                            <div className="inbox-settings-form-content form-content-wrapper">
                                <b>Show Tab "Others"</b>
                                <p className="status-wrapper">
                                    {val.showTabOthers ?
                                        <>
                                            <img src={statusConnected} alt="" />
                                            <b className="status-value status-enabled">Enabled</b>
                                        </>
                                        :
                                        <>
                                            <img src={iconXgrey} alt="" />
                                            <b className="status-value">Disabled</b>
                                        </>
                                    }
                                </p>

                                <NavLink to={`/o/${orgID}/setup/inbox-settings/other-tab`}>
                                    <ButtonWithLoadingOrIcon
                                        icon={{
                                            type: "svg",
                                            src: FiEdit
                                        }}
                                        className="no-fill-button main-button-40 button-save"
                                        text="Edit"
                                        position="left"
                                    />
                                </NavLink>
                            </div>

                            <div className="inbox-settings-form-content form-content-wrapper">
                                <b>Show Tab "Resolved"</b>
                                <p className="status-wrapper">
                                    {val.showTabClosed ?
                                        <>
                                            <img src={statusConnected} alt="" />
                                            <b className="status-value status-enabled">Enabled</b>
                                        </>
                                        :
                                        <>
                                            <img src={iconXgrey} alt="" />
                                            <b className="status-value">Disabled</b>
                                        </>
                                    }
                                </p>

                                <NavLink to={`/o/${orgID}/setup/inbox-settings/resolved-tab`}>
                                    <ButtonWithLoadingOrIcon
                                        icon={{
                                            type: "svg",
                                            src: FiEdit
                                        }}
                                        className="no-fill-button main-button-40 button-save"
                                        text="Edit"
                                        position="left"
                                    />
                                </NavLink>
                            </div>

                            <div className="inbox-settings-form-content form-content-wrapper">
                                <b>Show Contact’s Phone Number</b>
                                <p className="status-wrapper">
                                    {val.showContactPhone ?
                                        <>
                                            <img src={statusConnected} alt="" />
                                            <b className="status-value status-enabled">Enabled</b>
                                        </>
                                        :
                                        <>
                                            <img src={iconXgrey} alt="" />
                                            <b className="status-value">Disabled</b>
                                        </>
                                    }
                                </p>

                                <NavLink to={`/o/${orgID}/setup/inbox-settings/phone-number`}>
                                    <ButtonWithLoadingOrIcon
                                        icon={{
                                            type: "svg",
                                            src: FiEdit
                                        }}
                                        className="no-fill-button main-button-40 button-save"
                                        text="Edit"
                                        position="left"
                                    />
                                </NavLink>
                            </div>

                            {/* <div className="inbox-settings-form-content form-content-wrapper content-need-reply"> */}
                            <div className="inbox-settings-form-content form-content-wrapper">
                                <b>Need Reply Indicator</b>
                                <p className="status-wrapper">
                                    {!valNeedReply.isCustom ?
                                        <b>Default</b>
                                        :
                                        <b>Custom</b>
                                    }
                                </p>

                                <NavLink to={`/o/${orgID}/setup/inbox-settings/need-reply`}>
                                    <ButtonWithLoadingOrIcon
                                        icon={{
                                            type: "svg",
                                            src: FiEdit
                                        }}
                                        className="no-fill-button main-button-40 button-save"
                                        text="Edit"
                                        position="left"
                                    />
                                </NavLink>
                            </div>

                            <div className="inbox-settings-form-content form-content-wrapper">
                                <b>Allow Reply on Agent Away</b>
                                <p className="status-wrapper">
                                    {val.allowReplyOnAway ?
                                        <>
                                            <img src={statusConnected} alt="" />
                                            <b className="status-value status-enabled">Enabled</b>
                                        </>
                                        :
                                        <>
                                            <img src={iconXgrey} alt="" />
                                            <b className="status-value">Disabled</b>
                                        </>
                                    }
                                </p>

                                <NavLink to={`/o/${orgID}/setup/inbox-settings/allow-reply-on-away`}>
                                    <ButtonWithLoadingOrIcon
                                        icon={{
                                            type: "svg",
                                            src: FiEdit
                                        }}
                                        className="no-fill-button main-button-40 button-save"
                                        text="Edit"
                                        position="left"
                                    />
                                </NavLink>
                            </div>

                            <div className="inbox-settings-form-content form-content-wrapper">
                                <b>Allow Handover Case</b>
                                <p className="status-wrapper">
                                    {val.allowHandoverCase ?
                                        <>
                                            <img src={statusConnected} alt="" />
                                            <b className="status-value status-enabled">Enabled</b>
                                        </>
                                        :
                                        <>
                                            <img src={iconXgrey} alt="" />
                                            <b className="status-value">Disabled</b>
                                        </>
                                    }
                                </p>

                                <NavLink to={`/o/${orgID}/setup/inbox-settings/allow-handover`}>
                                    <ButtonWithLoadingOrIcon
                                        icon={{
                                            type: "svg",
                                            src: FiEdit
                                        }}
                                        className="no-fill-button main-button-40 button-save"
                                        text="Edit"
                                        position="left"
                                    />
                                </NavLink>
                            </div>

                            <div className="inbox-settings-form-content form-content-wrapper">
                                <b>First Response SLA</b>
                                <p className="status-wrapper">
                                    {valSla.isEnabled ?
                                        <>
                                            <img src={statusConnected} alt="" />
                                            <b className="status-value status-enabled">Enabled</b>
                                        </>
                                        :
                                        <>
                                            <img src={iconXgrey} alt="" />
                                            <b className="status-value">Disabled</b>
                                        </>
                                    }
                                </p>

                                <NavLink to={`/o/${orgID}/setup/inbox-settings/sla`}>
                                    <ButtonWithLoadingOrIcon
                                        icon={{
                                            type: "svg",
                                            src: FiEdit
                                        }}
                                        className="no-fill-button main-button-40 button-save"
                                        text="Edit"
                                        position="left"
                                    />
                                </NavLink>
                            </div>  
                        </div>
                    }
                </React.Fragment>
            </div>
        </SectionWrap>
    )
}

export default InboxSetting;