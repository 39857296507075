import React, { useState, useEffect } from 'react';
import './ChatRoom.css';
import ChatRoomHeader from './chatRoomHeader/ChatRoomHeader';
import ChatRoomNoChat from './chatRoomNoChat/ChatRoomNoChat';
import ChatRoomMain from './chatRoomMain/ChatRoomMain';
import ChatRoomStartChat from './chatRoomMain/chatRoomStartChat/ChatRoomStartChat';
import ChatRoomUserInfo from './chatRoomMain/chatRoomUserInfo/ChatRoomUserInfo';
import ChatRoomSalesTalkProductModal from "./chatRoomMain/chatRoomSalesTalkProductModal/ChatRoomSalesTalkProductModal";
import ChatRoomEditContactInfo from './chatRoomMain/chatRoomEditContactInfo/ChatRoomEditContactInfo';
import ChatRoomCaseHistoryModal from './chatRoomCaseHistoryModal/ChatRoomCaseHistoryModal';
import ChatRoomBroadcastHistoryModal from './chatRoomBroadcastHistoryModal/ChatRoomBroadcastHistoryModal';
import ChatRoomProductDetailModal from './chatRoomProductDetailModal/ChatRoomProductDetailModal';
import ChatRoomProductSelectedModal from './chatRoomProductSelectedModal/ChatRoomProductSelectedModal';
import ChatRoomForward from './chatRoomForward/ChatRoomForward';
import ChatroomPopupMessageInfo from './chatRoomPopupMessageInfo/ChatRoomPopupMessageInfo';
import caseServices from '../../services/chat/caseServices';
import { connect } from 'react-redux';
// import ChatRoomNewTemplatedMessage from './chatRoomMain/chatRoomNewMessage/ChatRoomNewTemplatedMessage';
// import ModalSendTemplatedMessage from './chatRoomMain/modalSendTemplatedMessage/ModalSendTemplatedMessage';
import ModalStartConversationWABA from '../roomList/roomListTab/startConversation/modalStartConversation/ModalStartConversationWABA-old';
import PreviewMessage from '../roomList/roomListTab/startConversation/previewMessage/PreviewMessage';
import ModalSending from '../reuseableComponent/modalSendTemplatedMessage/ModalSendTemplatedMessage';
import { doToast } from '../../helper/HelperGeneral';
    
var ChatRoom = (props) => {
  let [startNewChat] = useState(false);
  let [isUserInfoShow, setIsUserInfoShow] = useState(false);
  let [isShowModalCaseHistory, setIsShowModalCaseHistory] = useState(false); 
  let [isShowModalBroadcaseHistory, setIsShowModalBroadcastHistory] = useState(false); 
  let [caseDetail, setCaseDetail] = useState(null);

  let [caseHistoryPopupData, setCaseHistoryPopupData] = useState(null);
  let [broadcastHistoryPopupData, setBroadcastHistoryPopupData] = useState(null);
  let [sendMessageQueue, setSendMessageQueue] = useState({
    q: [],
  });
  let [isRunningSendMessageQueue, setIsRunningSendMessageQueue] = useState(false);
  let [isShowChatRoomNewTemplatedMessage, setIsShowChatRoomNewTemplatedMessage] = useState(false);
  let [newTemplatedMessageData, setNewTemplatedMessageData] = useState({});
  let [dataPreviewMessage, setDataPreviewMessage] = useState(false);
  let [isShowModalPreviewMessage, setIsShowModalPreviewMessage] = useState(false);
  let [reset, setReset] = useState(false);
  let [isShowModalSending, setIsShowModalSending] = useState(false);

  let toggleIsUserInfoShowAction = () => {
    setIsUserInfoShow(!isUserInfoShow);
  }

  let toggleCaseModalHistory = () => {
    setIsShowModalCaseHistory(!isShowModalCaseHistory)
  }

  let toggleBroadcastModalHistory = () => {
    if(isShowModalBroadcaseHistory) {
      setBroadcastHistoryPopupData(null);
    }

    setIsShowModalBroadcastHistory(!isShowModalBroadcaseHistory)
  }

  let setCaseDetailAction = (caseDetail) => {
    setCaseDetail(caseDetail)
  }

  let onClickCaseHistory = (caseDetail) => {
    setCaseHistoryPopupData(caseDetail)
    toggleCaseModalHistory();
  }

  let onClickBroadcastHistory = (detail) => {
    setBroadcastHistoryPopupData(detail)
    toggleBroadcastModalHistory();
  }

  useEffect(() => {
    if(sendMessageQueue.q.length > 0 && !isRunningSendMessageQueue && navigator.onLine) {
      runSendMessageQueue();
    }

    // if(sendMessageQueue.forceRun) {
    //   runSendMessageQueue()
    // }
  }, [sendMessageQueue])

  useEffect(() => {
    if(props.lastConnectProps) {
      runSendMessageQueue()
    }
  }, [props.lastConnectProps])

  //send message
  let runSendMessageQueue = async () => {
    let _sendMessageQueue = {...sendMessageQueue};
    let _isRunningMessageQueue = isRunningSendMessageQueue;

		// if(navigator.onLine) {
    //   _isRunningMessageQueue = true;
		// 	setIsRunningSendMessageQueue(true);
		// }else {
    //   _isRunningMessageQueue = false;
		// 	setIsRunningSendMessageQueue(false);
		// }
    _isRunningMessageQueue = true;
    setIsRunningSendMessageQueue(true);
    
    // let mess = _sendMessageQueue.q.slice();

		if(_sendMessageQueue.q.length > 0 && _isRunningMessageQueue) {
      //run api
      caseServices.postSendMessage(props.parentProps.match.params.orgID, _sendMessageQueue.q[0], (response) => {
        if(_sendMessageQueue.q[0].callbackSolved) {
            _sendMessageQueue.q[0].callbackSolved();
        }

        let _dataResult = response.dataResult;

        if(_dataResult.status === 200) {
          if(!_dataResult.data.success) {
              _sendMessageQueue.q[0].callback(_dataResult.data);
              setIsRunningSendMessageQueue(false);
              doToast(_dataResult.data.message, "fail");
          }else {
            setTimeout(() => {
              // _sendMessageQueue.forceRun = true;
              // mess[0].message.isSending = false;

              // tapCoreChatRoomManager.changeMessageStatus(mess[0].message.room.roomID, mess[0].message, (rooms, roomListHashMap) => {
              //   props.setMessageListenerNewMessage(mess[0].message);
              // });
              
              runSendMessageQueue();
            }, 100);
          }
        }else {
          setIsRunningSendMessageQueue(false);
          doToast(_dataResult.error.message, "fail");
        }

        _sendMessageQueue.q.shift();
        setSendMessageQueue(_sendMessageQueue);
      });
	  }else {
      setIsRunningSendMessageQueue(false);
      // setSendMessageQueue(_sendMessageQueue);
			return;
		}
	}

	let pushSendMessageQueue = (data) => {
    let _sendMessageQueue = {...sendMessageQueue};

    _sendMessageQueue.q.push(data);
    setSendMessageQueue(_sendMessageQueue);
	}
  //send message

  let toggleModalStartConversation = (data = newTemplatedMessageData) => {
    if(data && data.templates) setNewTemplatedMessageData(data)
    setIsShowChatRoomNewTemplatedMessage(!isShowChatRoomNewTemplatedMessage);
  };

  let toggleModalSending = (isShow) => {
    setIsShowModalSending(isShow);
  };

  let toggleModalPreviewMessage = (isShow) => {
    setIsShowModalPreviewMessage(isShow);
  };

  let close = () => {
    toggleModalPreviewMessage(false);
    setReset(!reset)
    setIsShowChatRoomNewTemplatedMessage(false);
  };

  let resetDataPreviewMessage = (data = null) => {
    setDataPreviewMessage(data)
  }

  useEffect(() => {
    setIsUserInfoShow(true)
  }, [])

  return (
    <div className="chat-room-wrapper">
        {props.activeRoom === null ? 
          <ChatRoomNoChat />
          :
          <React.Fragment>
            <ChatRoomHeader 
              listenerStartTypingProps={props.listenerStartTypingProps}
              listenerStopTypingProps={props.listenerStopTypingProps}
              // listenerUserOnlineProps={props.listenerUserOnlineProps}
              parentProps={props.parentProps}
              messageListenerNewMessageProps={props.messageListenerNewMessageProps}
              caseDetailValProps={props.caseDetailValProps}
              isUserInfoShowProps={isUserInfoShow}
              toggleIsUserInfoShowActionProps={toggleIsUserInfoShowAction}
              _changeContactInfo={props._changeContactInfo}
              topicListProps={props.topicListProps}
            />

            {/* <ChatRoomNewTemplatedMessage
              isShowModalStartConversationProps={isShowChatRoomNewTemplatedMessage}
              toggleModalStartConversationProps={toggleModalStartConversation}
              parentProps={props.parentProps}
              orgIDProps={props.orgIDProps}
              data={newTemplatedMessageData}
              setDataPreviewMessage={(data) => setDataPreviewMessage({ ...data })}
              toggleModalPreviewMessageProps={toggleModalPreviewMessage}
              // toggleModalSendingProps={toggleModalSending}
              toggleReset={reset}
            /> */}

            <ModalStartConversationWABA
              {...props}
              newMessage24h={true}
              orgIDProps={props.parentProps.match.params.orgID}
              isShowModalStartConversationProps={isShowChatRoomNewTemplatedMessage}
              toggleModalStartConversationProps={toggleModalStartConversation}
              data={newTemplatedMessageData}
              parentProps={props.parentProps}
              topicListProps={props.topicListProps}
              runSetDataPreviewMessage={(data) => setDataPreviewMessage(typeof data === "object" ? { ...data } : false)}
              dataPreviewMessage={dataPreviewMessage}
              toggleModalPreviewMessageProps={toggleModalPreviewMessage}
              toggleReset={reset}
            />

            {/* forward */}
            <ChatRoomForward 
              parentProps={props.parentProps}
              topicListProps={props.topicListProps}
              orgIDProps={props.parentProps.match.params.orgID}
              onClickRoomListProps={props.onClickRoomListProps}
            />
            {/* forward */}

            <PreviewMessage
              {...props}
              type={"waba"}
              newMessage24h={true}
              resetDataPreviewMessage={resetDataPreviewMessage}
              orgIDProps={props.parentProps.match.params.orgID}
              isShowModalPreviewMessageProps={isShowModalPreviewMessage}
              dataPreviewMessage={dataPreviewMessage}
              runSetDataPreviewMessage={(data) => setDataPreviewMessage(typeof data === "object" ? { ...data } : false)}
              topicListProps={props.topicListProps}
              toggleModalSendingProps={toggleModalSending}
              onClickRoomListProps={props.onClickRoomListProps}
              toggleModalStartConversationProps={toggleModalStartConversation}
              toggleModalPreviewMessageProps={toggleModalPreviewMessage}
              closeModal={close}
            />

            <ModalSending
              isShowModalSendingProps={isShowModalSending}
              toggleModalSendingProps={toggleModalSending}
            />

            {startNewChat ? 
              <ChatRoomStartChat />
              :
              <React.Fragment>
                <ChatRoomMain 
                  messageListenerNewMessageProps={props.messageListenerNewMessageProps}
                  messageListenerUpdateMessageProps={props.messageListenerUpdateMessageProps}
                  deleteLocalChatRoomProps={props.deleteLocalChatRoomProps}
                  toggleMarkAsSolvedPanelProps={props.toggleMarkAsSolvedPanelProps}
                  toggleModalStartConversationProps={toggleModalStartConversation}
                  markAsSolvedPanelShowProps={props.markAsSolvedPanelShowProps}
                  parentProps={props.parentProps}
                  caseDetailValProps={props.caseDetailValProps}
                  setNewEmitMessageProps={props.setNewEmitMessageProps}
                  isUserInfoShowProps={isUserInfoShow}
                  setCaseDetailActionProps={setCaseDetailAction}
                  pushSendMessageQueueProps={pushSendMessageQueue}
                />

                <ChatRoomUserInfo 
                  isUserInfoShowProps={isUserInfoShow}
                  isShowModalCaseHistoryProps={isShowModalCaseHistory} 
                  toggleCaseModalHistoryProps={toggleCaseModalHistory} 
                  messageListenerNewMessageProps={props.messageListenerNewMessageProps}
                  messageListenerUpdateMessageProps={props.messageListenerUpdateMessageProps}
                  listenerUserOnlineProps={props.listenerUserOnlineProps}
                  parentProps={props.parentProps}
                  caseDetailProps={caseDetail}
                  onClickCaseHistoryProps={onClickCaseHistory}
                  onClickBroadcastHistoryProps={onClickBroadcastHistory}
                />

                <ChatRoomSalesTalkProductModal 
                  parentProps={props.parentProps}
                />

                <ChatRoomEditContactInfo 
                  parentProps={props.parentProps}
                />

                {/* {caseHistoryPopupData !== null &&  */}
                  <ChatRoomCaseHistoryModal 
                    isShowModalCaseHistoryProps={isShowModalCaseHistory} 
                    toggleCaseModalHistoryProps={toggleCaseModalHistory}
                    messageListenerNewMessageProps={props.messageListenerNewMessageProps}
                    messageListenerUpdateMessageProps={props.messageListenerUpdateMessageProps}
                    caseHistoryPopupDataProps={caseHistoryPopupData}
                    parentProps={props.parentProps}
                  />
  
                  <ChatRoomBroadcastHistoryModal 
                    {...props}
                    isShowModalBroadcastHistoryProps={isShowModalBroadcaseHistory} 
                    toggleBroadcastModalHistoryProps={toggleBroadcastModalHistory}
                    messageListenerNewMessageProps={props.messageListenerNewMessageProps}
                    messageListenerUpdateMessageProps={props.messageListenerUpdateMessageProps}
                    broadcastHistoryPopupDataProps={broadcastHistoryPopupData}
                  />
                {/* } */}

                {/* salestalk modal */}
                  <ChatRoomProductDetailModal />

                  <ChatRoomProductSelectedModal />
                {/* salestalk modal */}

                <ChatroomPopupMessageInfo {...props} />
              </React.Fragment>
            }

          </React.Fragment>
        }
    </div>
  );
}


const mapStateToProps = state => ({
  activeRoom: state.activeRoom,
  salesTalkInbox: state.salesTalkInbox
});

export default connect(mapStateToProps, null)(ChatRoom);
