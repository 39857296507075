import React, { useState, useEffect } from "react";
import "./IntegrationChatGPTSetupHandoverSettings.scss";
import { FiInfo, FiTrash2, FiPlus, FiAlertCircle } from "react-icons/fi";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import CustomSelectOption from "../../../reuseableComponent/customSelectOption/CustomSelectOption";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import NextButton from "../../../reuseableComponent/NextButton/NextButton";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { connect } from 'react-redux';
import { scrollToClass } from "../../../../helper/HelperGeneral";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import PopupDiscard from "../../../reuseableComponent/popupDiscard/PopupDiscard";

const IntegrationChatGPTSetupHandoverSettings = (props) => {
    
    let [handoverSettingsOrigin, setHandoverSettingsOrigin] = useState("");
    let [isLoadingSavedData, setIsLoadingSavedData] = useState(false);
    let [errorMessageData, setErrorMessageData] = useState({
        criteria: [
            {
                criteria: "",
                topic: ""
            }
        ]
    });
    let [isShowModalDiscard, setIsShowModalDiscard] = useState(false);

    useEffect(() => {
        props.getTopics();
    }, []);

    useEffect(() => {
        fetchSavedHandoverSettings();
    }, [props.match.params.orgID]);

    useEffect(() => {
        let _errorMessageData = {...errorMessageData};
        while (_errorMessageData.criteria.length < props.handoverSettings.criteria.length) {
            _errorMessageData.criteria.push({
                criteria: "",
                topic: ""
            });
        }
        setErrorMessageData(_errorMessageData);
    }, [props.handoverSettings]);

    const fetchSavedHandoverSettings = () => {
        setIsLoadingSavedData(true);

        // TODO: GET SAVED SETTINGS API
        setTimeout(() => {
            setIsLoadingSavedData(false);
            if (!handoverSettingsOrigin) {
                setHandoverSettingsOrigin(JSON.stringify(props.handoverSettings));
            }
        }, 1000);
    }

    const toggleModalDiscard = () => {
        setIsShowModalDiscard(!isShowModalDiscard);
    }

    const onChangeText = (e, i) => {
        let _data = { ...props.handoverSettings };
        let _errorMessageData = { ...errorMessageData };
        _errorMessageData.criteria[i].criteria = "";
        _data.criteria[i].criteria = e.target.value;
        setErrorMessageData(_errorMessageData);
        props.setHandoverSettings(_data);
    }

    const onSelectTopic = (e, i) => {
        let _data = { ...props.handoverSettings };
        let _errorMessageData = { ...errorMessageData };
        _errorMessageData.criteria[i].topic = "";
        _data.criteria[i].topic = e;
        setErrorMessageData(_errorMessageData);
        props.setHandoverSettings(_data);
    }

    const validateContinue = () => {
        let _errorMessageData = { ...errorMessageData };
        let errorClass = false;

        if (props.handoverSettings.isEnable) {
            props.handoverSettings.criteria.map((value, index) => {
                if (!value.criteria) {
                    if (_errorMessageData && errorMessageData.criteria && errorMessageData.criteria.length > index) {
                        _errorMessageData.criteria[index].criteria = "This field is required"
                    }
                    if (!errorClass) {
                        errorClass = `criteria-wrapper-${index}`;
                    }
                }
                if (!value.topic.value) {
                    if (_errorMessageData && errorMessageData.criteria && errorMessageData.criteria.length > index) {
                        _errorMessageData.criteria[index].topic = "This field is required"
                    }
                    if (!errorClass) {
                        errorClass = `criteria-wrapper-${index}`;
                    }
                }
            });
        }

        setErrorMessageData(_errorMessageData);

        if (errorClass) {
            scrollToClass(`.${errorClass}`);
        }
        else {
            props.setActiveTab(4);
        }
    }

    const toggleConfig = () => {
        let _data = { ...props.handoverSettings };
        _data.isEnable = !_data.isEnable;
        props.setHandoverSettings(_data);

        if (!_data.isEnable) {
            let _errorMessageData = { ...errorMessageData };
            _errorMessageData.criteria.map((value) => {
                value.criteria = "";
                value.topic = "";
            });
            setErrorMessageData(_errorMessageData);
        }
    }

    const addAnotherCriteria = () => {
        let _data = { ...props.handoverSettings };
        let _errorMessageData = { ...errorMessageData };

        _errorMessageData.criteria.push({
            criteria: "",
            topic: ""
        });

        _data.criteria.push({
            criteria: "",
            topic: {
                value: "",
                label: ""
            }
        });
        setErrorMessageData(_errorMessageData);
        props.setHandoverSettings(_data);
    }

    const onDeleteCriteria = (i) => {
        let _data = { ...props.handoverSettings };
        let _errorMessageData = { ...errorMessageData };

        _errorMessageData.criteria.splice(i, 1);
        _data.criteria.splice(i, 1);

        setErrorMessageData(_errorMessageData);
        props.setHandoverSettings(_data);
    }

    const filterTopicSelection = (i) => {
        let printTopic = [];

        props.topicList.map((val) => {
            let findIndexTopic = props.handoverSettings.criteria.findIndex(_v => _v.topic.value === val.value);

            if (findIndexTopic === -1 || findIndexTopic === i) {
                printTopic.push(val);
            }
        })

        return printTopic;
    }

    const printCriteria = (v, i) => {
        return (
            <div key={`criteria-wrap-${i}`} className={`criteria-wrapper criteria-wrapper-${i} form-content-wrapper`}>
                <label>
                    <b>Criteria</b>

                    {/* <span className={`${i !== 0 ? "right-18" : ""}`}>{v.text.length}/25 character</span> */}
                </label>
                <input
                    type="text"
                    placeholder="Insert criteria here"
                    className={` ${errorMessageData.criteria && errorMessageData.criteria.length > i && errorMessageData.criteria[i] && errorMessageData.criteria[i].criteria !== "" ? "border-red" : ""}`}
                    value={v.criteria}
                    onChange={(e) => onChangeText(e, i)}
                    disabled={!props.handoverSettings.isEnable}
                />
                {
                    (errorMessageData.criteria && errorMessageData.criteria.length > i && errorMessageData.criteria[i] && errorMessageData.criteria[i].criteria !== "") &&
                    <ErrorOrInfoComp
                        text={errorMessageData.criteria[i].criteria}
                        _className={`${errorMessageData.criteria && errorMessageData.criteria.length > i && errorMessageData.criteria[i] && errorMessageData.criteria[i].criteria !== "" ? "red-text" : ""}`}
                        icon={<FiAlertCircle />}
                    />
                }

                <label>
                    <b>Topic</b>
                </label>

                <CustomSelectOption
                    optionListProps={filterTopicSelection(i)}
                    valueDropdownProps={v.topic}
                    placeholderProps={'Select topic'}
                    onClickDropDownListOptionProps={(e) => onSelectTopic(e, i)}
                    specificClassNameProps="custom-select-criteria-topic"
                    dropdownError={""}
                    _className={errorMessageData.criteria && errorMessageData.criteria.length > i && errorMessageData.criteria[i] && errorMessageData.criteria[i].topic !== "" ? "border-red" : ""}
                    isDisabled={!props.handoverSettings.isEnable}
                    isLoading={!props.topicList || props.topicList.length === 0}
                />
                {
                    errorMessageData.criteria && errorMessageData.criteria.length > i && errorMessageData.criteria[i] && errorMessageData.criteria[i].topic !== "" &&
                    <ErrorOrInfoComp
                        text={errorMessageData.criteria && errorMessageData.criteria.length > i && errorMessageData.criteria[i] && errorMessageData.criteria[i].topic}
                        _className={`${errorMessageData.criteria[i].topic !== "" ? "red-text" : ""}`}
                        icon={<FiAlertCircle />}
                    />
                }

                {
                    (props.handoverSettings.isEnable && props.handoverSettings.criteria.length > 1) &&
                    <FiTrash2
                        className='button-delete'
                        onClick={() => onDeleteCriteria(i)}
                    />
                }
            </div>
        )
    }

    return (
        <>
            <SectionWrap
                {...props}
                orgID={props.match.params.orgID}
                withHeader
                withSideMenu
                noWrap
                dataOrigin={handoverSettingsOrigin}
                dataAfterChange={JSON.stringify(props.handoverSettings)}
            >
                <div className="nav-button-wrapper">
                    <BackButton
                        onClick={() => {
                            if (handoverSettingsOrigin !== JSON.stringify(props.handoverSettings)) {
                                setIsShowModalDiscard(true);
                            }
                            else {
                                props.setActiveTab(2);
                            }
                        }}
                        text={"Back to Leads Generation"}
                    />

                    <NextButton
                        onClick={validateContinue}
                        text={"Continue to Submission"}
                    />
                </div>

                <div className="chatgpt-setup-handover-page-wrapper sectionWrap">
                    <p className="top-title">
                        <b>Handover Settings</b>

                        <span className="top-subtitle">
                            Turning this on will allow cases to be handed over to agents when messages with specific criteria are received.
                        </span>

                        {
                            !isLoadingSavedData &&
                            <div className="gpt-handover-switch-wrapper">
                                <label className="custom-switch-wrapper">
                                    <input type="checkbox" checked={props.handoverSettings.isEnable} onChange={() => toggleConfig()} />
                                    <span className="custom-switch round"></span>
                                </label>
                            </div>
                        }
                    </p>

                    {
                        isLoadingSavedData ?
                        <RetrieveDataLoading
                            isLoading={isLoadingSavedData}
                            errorMessage=""
                            errorCode=""
                        />
                        :
                        <>
                            <ErrorOrInfoComp
                                icon={<FiInfo />}
                                _className="top-info"
                                text="Criteria helps ChatGPT define conversations that needs to be handed over to agents. E.g. When there is no possible answers from the knowledge base"
                            />
                            {
                                props.handoverSettings.criteria &&
                                props.handoverSettings.criteria.map((v, i) => {
                                return (
                                    printCriteria(v, i)
                                )
                            })}
        
                            {props.handoverSettings.criteria.length < props.topicList.length &&
                                <ButtonWithLoadingOrIcon
                                    text="Add another criteria"
                                    onClickAction={() => addAnotherCriteria()}
                                    className="main-button-26 no-fill-button add-another-criteria"
                                    icon={{
                                        type: "svg",
                                        src: FiPlus
                                    }}
                                    position="left"
                                />
                            }
                        </>
                    }
                </div>
            </SectionWrap>

            <PopupDiscard
                dataOrigin={handoverSettingsOrigin}
                dataAfterChange={JSON.stringify(props.handoverSettings)}
                isOpenDiscard={isShowModalDiscard}
                toggleDiscard={toggleModalDiscard}
                discardProgress={() => {
                    setIsShowModalDiscard(false);
                    props.setActiveTab(2);
                }}
                discardDesc={props.discardDesc}
            />
        </>
    )
}

const mapStateToProps = state => ({
    allowedModule: state.allowedModule
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationChatGPTSetupHandoverSettings)
