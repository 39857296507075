import React, { useEffect, useState } from "react";
import "./ChatRoomReplyOutMessage.scss";
import { FiFile } from "react-icons/fi";
import { tapCoreChatRoomManager } from '@taptalk.io/web-sdk';
import { connect } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { CHAT_TYPE } from '../../../../../helper/HelperConst';

let ChatRoomReplyOutMessage = (props) => {
    let {
        message,
        activeUserID,
        scrollToReply
    } = props;

    let [replyToSrc, setReplyToSrc] = useState('');
    let [isFileInDB, setIsFileInDB] = useState(false);

    useEffect(() => {
        let fetchFromDB = () => {
            if (message.quote.fileID) {
                tapCoreChatRoomManager.getFileFromDB(message.quote.fileID, function (data) {
                    if (data) {
                        setReplyToSrc(data.file);
                        setIsFileInDB(true);
                    } else {
                        downloadFile();
                    }
                })
            }
        }

        let downloadFile = () => {
            let data = {
                room: {
                    roomID: props.activeRoom.roomID
                },
                data: {
                    fileID: message.quote.fileID
                }
            }

            tapCoreChatRoomManager.downloadMessageFile(data, {
                onSuccess: (data) => {
                    setReplyToSrc(data.base64);
                    setIsFileInDB(true);
                },

                onProgress: (message, percentage, bytes) => {
                    // setPercentageDownload(percentage);
                },

                onError: (errorCode, errorMessage) => {
                    // setOnImageDownloadProgress(false);
                    console.log(errorCode, errorMessage);
                }
            })
        }

        if (message.replyTo.messageType === CHAT_TYPE.TAPChatMessageTypeImage ||
            message.replyTo.messageType === CHAT_TYPE.TAPMessageTypeBroadcastImage ||
            message.replyTo.messageType === CHAT_TYPE.TAPMessageTypeWhatsAppBATemplateImage
        ) {
            if (!message.quote.imageURL || message.quote.imageURL === "") {
                fetchFromDB();
            } else {
                setReplyToSrc(message.quote.imageURL);
            }
        }

        if (message.replyTo.messageType === CHAT_TYPE.TAPChatMessageTypeVideo) {
            if (!message.quote.videoURL || message.quote.videoURL === "") {
                fetchFromDB();
            } else {
                setReplyToSrc(message.quote.videoURL);
            }
        }
    }, [message])

    // let scrollToReply = (localID) => {
    //     let targetScroll = document.querySelectorAll(".chat-room-main-content")[0];
    //     let targetLocalID = document.querySelector(`#message-${localID}`);

    //     if(targetLocalID !== null) {
    //         targetScroll.scrollTop = targetLocalID.offsetTop;

    //         targetLocalID.classList.add("highlight-chat-bubble");
    //         setTimeout(() => {
    //             targetLocalID.classList.remove("highlight-chat-bubble");
    //         }, 2000);
    //     }
    // }

    return (
        <div
            className={`reply-message-out-bubble 
                        ${(
                    message.replyTo.messageType !== CHAT_TYPE.TAPChatMessageTypeText &&
                    message.replyTo.messageType !== CHAT_TYPE.TAPChatMessageTypeLink &&
                    message.replyTo.messageType !== CHAT_TYPE.TAPMessageTypeBroadcastText &&
                    message.replyTo.messageType !== CHAT_TYPE.TAPMessageTypeWhatsAppBATemplateText &&
                    message.replyTo.messageType !== CHAT_TYPE.TAPChatMessageTypeLocation &&
                    message.replyTo.messageType !== CHAT_TYPE.TAPChatMessageTypeTextInteractive &&
                    message.replyTo.messageType !== CHAT_TYPE.TAPChatMessageTypeImageInteractive &&
                    message.replyTo.messageType !== CHAT_TYPE.TAPChatMessageTypeVideoInteractive
                ) ?
                    "with-media-or-file"
                    :
                    ""
                }
            `}
            onClick={() => scrollToReply(message.replyTo.localID)}
        >
            <div className={`reply-message-out-bubble-name-text-wrapper with-border 
                             ${(
                    message.replyTo.messageType !== CHAT_TYPE.TAPChatMessageTypeText &&
                    message.replyTo.messageType !== CHAT_TYPE.TAPChatMessageTypeLink &&
                    message.replyTo.messageType !== CHAT_TYPE.TAPMessageTypeBroadcastText &&
                    message.replyTo.messageType !== CHAT_TYPE.TAPMessageTypeWhatsAppBATemplateText &&
                    message.replyTo.messageType !== CHAT_TYPE.TAPChatMessageTypeLocation &&
                    message.replyTo.messageType !== CHAT_TYPE.TAPChatMessageTypeTextInteractive &&
                    message.replyTo.messageType !== CHAT_TYPE.TAPChatMessageTypeImageInteractive &&
                    message.replyTo.messageType !== CHAT_TYPE.TAPChatMessageTypeVideoInteractive
                ) ?
                    "with-media-file"
                    :
                    ""
                }
            `}>
                {(
                    message.replyTo.messageType !== CHAT_TYPE.TAPChatMessageTypeText &&
                    message.replyTo.messageType !== CHAT_TYPE.TAPChatMessageTypeLink &&
                    message.replyTo.messageType !== CHAT_TYPE.TAPMessageTypeBroadcastText &&
                    message.replyTo.messageType !== CHAT_TYPE.TAPMessageTypeWhatsAppBATemplateText &&
                    message.replyTo.messageType !== CHAT_TYPE.TAPChatMessageTypeLocation &&
                    message.replyTo.messageType !== CHAT_TYPE.TAPChatMessageTypeTextInteractive &&
                    message.replyTo.messageType !== CHAT_TYPE.TAPChatMessageTypeImageInteractive &&
                    message.replyTo.messageType !== CHAT_TYPE.TAPChatMessageTypeVideoInteractive
                ) &&
                    <div className="reply-file-media-wrapper">
                        {(message.replyTo.messageType === CHAT_TYPE.TAPChatMessageTypeImage ||
                          message.replyTo.messageType === CHAT_TYPE.TAPMessageTypeBroadcastImage ||
                          message.replyTo.messageType === CHAT_TYPE.TAPMessageTypeWhatsAppBATemplateImage
                        ) &&
                            (replyToSrc !== "" &&
                                // <img 
                                //     src={isFileInDB ? `data:image/png;base64, ${replyToSrc}` : replyToSrc}
                                //     // src={"https://engine-dev.taptalk.io/v1/public/110/chat/file/image/3b9aec82-5e81-4fef-bb85-292ce06db4cf-1609917478?token=7fb1914db446f81691877141aa717502"}
                                //     alt="reply"  
                                //     className="reply-message-image"
                                // />
                                <LazyLoadImage
                                    alt={""}
                                    src={isFileInDB ? `data:image/png;base64, ${replyToSrc}` : replyToSrc}
                                    className='reply-message-image'
                                />
                            )
                        }

                        {message.replyTo.messageType === CHAT_TYPE.TAPChatMessageTypeVideo &&
                            (replyToSrc !== "" &&
                                <video
                                    src={isFileInDB ? `data:video/mp4;base64, ${replyToSrc}` : replyToSrc}
                                    // src={"https://engine-dev.taptalk.io/v1/public/110/chat/file/video/b254bc0a-d786-493a-805c-7633f3ddbffc-1610093877?token=e1fc355cac5f9f28393b9391efcebcb1"}
                                    className="reply-message-video"
                                />
                            )
                        }

                        {(message.replyTo.messageType === CHAT_TYPE.TAPChatMessageTypeFile || message.replyTo.messageType === CHAT_TYPE.TAPChatMessageTypeDocumentInteractive) &&
                            <div className="reply-message-file">
                                <FiFile />
                            </div>
                        }
                    </div>
                }

                <div className="reply-message-out-bubble-reply-name-wrapper">
                    <p className="reply-message-out-bubble-reply-name">
                        <b>
                            {message.replyTo.userID === activeUserID ? "You" : message.quote.title}
                        </b>
                    </p>

                    <p className="reply-message-out-bubble-reply-text">
                        {message.quote.content}
                    </p>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    activeRoom: state.activeRoom
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomReplyOutMessage);