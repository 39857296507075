import React from "react";
import { FiInfo } from "react-icons/fi";
import AscendingDescendingTable from "../AscendingDescendingTable/AscendingDescendingTable";
import "./AscendingDescendingTableHeader.scss";
import { Tooltip } from 'reactstrap';

const AscendingDescendingTableHeader = ({
  className,
  fieldName,
  title,
  onClick,
  sortByVal,
  noSort,
  withTooltip,
  tooltipText,
  showTooltip,
  toogleTooltipClock,
  idx
}) => {
  return (
    <td 
      className={`sort-table-header ${className}`} 
      onClick={() => {
        if(!withTooltip) {
          onClick();
        }
      }}
    >
      <b>{title}</b>

      {withTooltip &&
        <div className="sort-table-tooltip-wrapper">
          <FiInfo 
            id={`TooltipExample${idx}`}
          />

          <Tooltip
            isOpen={showTooltip}
            target={`TooltipExample${idx}`}
            toggle={toogleTooltipClock}
          >
            {tooltipText}
          </Tooltip>
        </div>
      }
      
      {!noSort &&
        <AscendingDescendingTable
          onDescending={() => onClick({ by: fieldName, order: "DESC" })}
          onAscending={() => onClick({ by: fieldName, order: "ASC" })}
          fieldName={fieldName}
          sortVal={sortByVal}
        />
      }

    </td>
  )
};

export default AscendingDescendingTableHeader;
