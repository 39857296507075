import React, { useEffect, useState } from 'react';
import { Modal, ModalBody } from "reactstrap";
import './TopicReportShow.scss';
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import ReportService from "../../../../services/newServices/ReportService";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import { checkID } from '../../../../helper/HelperGeneral';
import HelperDate from '../../../../helper/HelperDate';
import { FiDownload, FiFilter } from 'react-icons/fi';
import FilterReportForm from '../../FilterReportForm/FilterReportForm';
import AscendingDescendingTableHeader from '../../../reuseableComponent/AscendingDescendingTableHeader/AscendingDescendingTableHeader';
import Pagination from "../../../reuseableComponent/pagination/Pagination";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import mixpanel from "mixpanel-browser";

let TopicReportShow = (props) => {
  let [orgID, setOrgID] = React.useState(false);
  let [isWaitingFetchTopicReport, setIsWaitingFetchCaseRating] = useState(true);
  let [isLoadingExportReport, setIsLoadingExportReport] = useState(false);
  let [retriveDataError, setRetrieveDataError] = React.useState({
      code: false,
      message: false
  });
  let [dateVal, setDateVal] = useState({
    from:  new Date(new Date().setDate(new Date().getDate() - 30)),
    to: new Date()
  });
  let [dateValOrigin, setDateValOrigin] = useState({
    from:  new Date(new Date().setDate(new Date().getDate() - 30)),
    to: new Date()
  });
  let [filterDateForCalender, setFilterDateForCalender] = useState({
    from: undefined,
    to: undefined
  });
  let [filterDateForCalenderOrigin, setFilterDateForCalenderOrigin] = useState({
    from: undefined,
    to: undefined
  });
  let [filterBy, setFilterBy] = useState({
    text: "Last 30 Days",
    val: "last_30"
  });
  let [sort, setSort] = useState({
    by: "topicName",
    order: "ASC"
  })
  let [filterTopicVal, setFilterTopicVal] = useState({
    selectedTopic: [],
    isCheckAll: false,
    topicListOrigin: [],
    topicList: [],
  });
  let [filterTopicValOrigin, setFilterTopicValOrigin] = useState({
    selectedTopic: [],
    isCheckAll: false,
    topicListOrigin: [],
    topicList: [],
  });
  let [pageDisplay, setPageDisplay] = useState(1);
  let [totalItems, setTotalItems] = useState(0);
  let [printedTopicReportData, setPrintedTopicReportData] = useState([]);
  let [showModalFilter, setShowModalFilter] = useState(false);
  // let [topicReportDataOrigin, setTopicReportDataOrigin] = useState(null);

  useEffect(() => {
    doMount();
    let myTopicData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    
    if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
      mixpanel.track(
        "[Page] Topic Reporting", 
        {
            userFullName : myTopicData.account.fullName,
            userID : myTopicData.account.id,
            organizationID : props.match.params.orgID.split("-")[0]
        }
      );
    }
  }, []);

  // useEffect(() => {
  //   if(props.topicReportData) {
  //     setTopicReportDataOrigin(props.topicReportData);
  //   }
  // }, [props.topicReportData])

  useEffect(() => {
    if(orgID) {
      getTopicReport();
    }
  }, [orgID])

  let doMount = async () => {
    //filter
    setDateVal(props.filterData.dateVal);
    setDateValOrigin(props.filterData.dateVal);
    setFilterDateForCalender(props.filterData.filterDateForCalender);
    setFilterDateForCalenderOrigin(props.filterData.filterDateForCalender);
    setFilterBy(props.filterData.filterBy);
    setFilterTopicVal(props.filterData.filterDataTopic);
    setFilterTopicValOrigin(props.filterData.filterDataTopic);
    //filter

    let paramID = checkID(props.match);

    if (paramID) {
        setOrgID(paramID);
    } else {
        ServiceOrganization.postGetOrganization((response) => {
            if (response.dataResult.error !== "") {
                let responseData = response.dataResult.data;
                if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                let { id, createdTime } = responseData.memberships[0].organization;
                let urlOrgID = `${id}-${createdTime}`;
                props.history.push(`/o/${urlOrgID}/organization/members`);
            } else {
                console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
            }
        });
    }
  };

  let onSort = (_sort) => {
    let _s = {
      by: _sort,
      order: _sort === sort.by ? (sort.order === "ASC" ? "DESC" : "ASC") : "ASC"
    }
    
    setSort(_s);
    setPageDisplay(1);
    getTopicReport(_s, 1);
  }

  // useEffect(() => {
  //   if (props.history.location.state) {
  //     if (props.history.location.state.success) {
  //       doToast(props.history.location.state.message);
  //     } else {
  //       doToast(props.history.location.state.message, 'fail');
  //     }

  //     window.history.pushState(null, '');
  //   }
  // }, [props.history]);

  let TABLE_HEADER = [
    {
      title: "Topic Name",
      value: "topicName",
      class: "col_1 with-max-width" 
    },
    {
      title: "Total Cases",
      value: "totalCasesCreated",
      class: "col_2 with-max-width" 
    },
    {
      title: "Total Resolved",
      value: "totalCasesClosed",
      class: "col_5 with-max-width"
    },
    {
      title: "Average Case Duration",
      value: "averageCaseDuration",
      class: "col_3 with-max-width" 
    },
    // {
    //   title: "Total First Responded",
    //   value: "totalFirstResponded",
    //   class: "col_4 with-max-width" 
    // },
  ];

  let downloadReport = (data) => {
    // let id = orgID.split('-');
    // let timestamp = + new Date();
    let url = window.URL.createObjectURL(new Blob([data]));
    let link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `topic-report-from-${HelperDate.formatToString(new Date(dateVal.from), "dd-MM-yyyy")}-to-${HelperDate.formatToString(new Date(dateVal.to), "dd-MM-yyyy")}.csv`);
    document.body.appendChild(link);
    link.click();
  }

  let exportTopicReport = () => {
    let _topicIDs = [];
    
    filterTopicVal.selectedTopic.map((v) => {
      _topicIDs.push(v.id);
      return null;
    })

    let data = {
      startDate: dateVal.from ? HelperDate.formatToString(new Date(dateVal.from), "yyyy-MM-dd") : "",
      endDate: dateVal.to ?  HelperDate.formatToString(new Date(dateVal.to), "yyyy-MM-dd") : "",
      sortBy: sort.by,
      sortOrder: sort.order,
      topicIDs: _topicIDs
    }

    setIsLoadingExportReport(true);
    
    ReportService.exportTopicReport(orgID, data,
      (response) => {
        setIsLoadingExportReport(false);
        if (!response) {
          // setShowModalFailed(true);
        } else {
          downloadReport(response.dataResult)
        }
      }
    )
  }

  let handleChangePage = (page) => {
    setPageDisplay(page)
    
    getTopicReport(false, page);
  }

  let toggleModalFilter = () => {
    setShowModalFilter(!showModalFilter);
  }

  let cancelTopicReport = () => {
    toggleModalFilter();
    setDateVal(dateValOrigin);
    setFilterDateForCalender(filterDateForCalenderOrigin);
    setFilterTopicVal(filterTopicValOrigin);

  }

  let submitFilterReport = (data) => {
    setDateVal(data.dateVal);
    setDateValOrigin(data.dateVal);
    setFilterDateForCalender(data.filterDateForCalender);
    setFilterDateForCalenderOrigin(data.filterDateForCalender);
    setFilterBy(data.filterBy);
    setFilterTopicVal(data.filterDataTopic);
    setFilterTopicValOrigin(data.filterDataTopic);
    toggleModalFilter();

    if(data.pageNumber) {
      setPageDisplay(data.pageNumber);
    }

    let _top = [];

    data.filterDataTopic.selectedTopic.map((v) => {
      _top.push(v.id);
      return null;
    })
    
    let _data = {
      startDate: data.dateVal.from ? HelperDate.formatToString(new Date(data.dateVal.from), "yyyy-MM-dd") : "",
      endDate: data.dateVal.to ?  HelperDate.formatToString(new Date(data.dateVal.to), "yyyy-MM-dd") : "",
      pageNumber: data.pageNumber ? data.pageNumber : pageDisplay,
      pageSize: 50,
      sortBy: sort.by,
      sortOrder: sort.order,
      topicIDs: _top
    }

    getTopicReport(false, false, _data);
    // setDateValOrigin(dateVal);
    // setFilterDateForCalenderOrigin(filterDateForCalender);
    // setFilterTopicValOrigin(FilterTopicVal);
    // setTopicReportDataOrigin(data);
  } 

  let PrintModalFilter = () => (
    <Modal isOpen={showModalFilter} className="modal-custom-filter-topic-report" toggle={cancelTopicReport}>
      <ModalBody>
        <p className="modal-custom-filter-topic-report-title">
          <FiFilter />
          <b>Custom Filter</b>
        </p>

        <FilterReportForm 
          {...props} 
          cancelTopicReport={cancelTopicReport}
          submitFilterReport={submitFilterReport}
          setPageNumber={1}
          topicReportData={{
            filterDataTopic: filterTopicVal,
            dateVal: dateVal,
            filterDateForCalender: filterDateForCalender,
            filterBy: filterBy
          }}
        />
      </ModalBody>
    </Modal>
  );

  let getTopicReport = (_sort = false, page = false, _data = false) => {
    setIsWaitingFetchCaseRating(true);
    let _retriveDataError = {...retriveDataError};

    let data;

    if(!_data) {
      let _top = [];
  
      filterTopicVal.selectedTopic.map((v) => {
        _top.push(v.id)
        return null;
      })
  
      data = {
        startDate: dateVal.from ? HelperDate.formatToString(new Date(dateVal.from), "yyyy-MM-dd") : "",
        endDate: dateVal.to ?  HelperDate.formatToString(new Date(dateVal.to), "yyyy-MM-dd") : "",
        pageNumber: !page ? pageDisplay : page,
        pageSize: 50,
        sortBy: !_sort ? sort.by : _sort.by,
        sortOrder: !_sort ? sort.order : _sort.order,
        topicIDs: _top
      }
    }else {
      data = _data;
    }

    ReportService.getTopicReport(props.match.params.orgID, data, (response) => {
      setIsWaitingFetchCaseRating(false);
      let dataResult = response.dataResult;

      if (dataResult.error.message === '') {
        setPrintedTopicReportData(dataResult.data.items);
        setTotalItems(dataResult.data.totalItems);
      } else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }
    });
  };

  return (
    <SectionWrap
      {...props}
      orgID={orgID}
      withHeader
      withSideMenu
    >
      <div className="topic-report-show-wrapper sectionWrap">
        {/* tier blocking */}
        {retriveDataError.code  === "49901" &&
          <RetrieveDataLoading 
            isLoading={isWaitingFetchTopicReport}
            errorMessage={retriveDataError.message}
            errorCode={retriveDataError.code}
            loadingText={"Loading Data"}
          />
        }
        {/* tier blocking */}

        <React.Fragment>
          <p className="topic-report-show-title">
            <b>Topic Report</b>
          </p>

          <PrintModalFilter />
          
          {!retriveDataError.code &&
            <>
              {isLoadingExportReport ?
                <ButtonWithLoadingOrIcon 
                  className="orange-button main-button-40 add-topic-report-show-button"
                  text="Download Report"
                  position="left"
                  isLoading
                  loadingColor="gray"
                  isDisabled
                />
                :
                <ButtonWithLoadingOrIcon 
                    className="orange-button main-button-40 add-topic-report-show-button"
                    onClickAction={exportTopicReport}
                    text="Download Report"
                    position="left"
                    icon={{
                      type: "svg",
                      src: FiDownload
                    }}
                />
              }
            </>
          }

          <div className="topic-report-show-data-outer-wrapper">
            {(isWaitingFetchTopicReport || (retriveDataError.code && retriveDataError.code !== "49901")) ? 
              <RetrieveDataLoading 
                isLoading={isWaitingFetchTopicReport}
                errorMessage={retriveDataError.message}
                errorCode={retriveDataError.code}
              />
              :
              <React.Fragment>
                <div className="topic-report-show-data-wrapper">
                  {!retriveDataError.code &&
                    <ButtonWithLoadingOrIcon 
                      icon={{
                        type: "svg",
                        src: FiFilter
                      }}
                      text="Custom Filter"
                      className="main-button-48 no-fill-black-button"
                      position="left"
                      dontBold
                      onClickAction={toggleModalFilter}
                      isDisabled={isLoadingExportReport}
                    />
                  }
                  {/* {printedTopicReportData.length === 0 ? 
                    <div className="no-topic-report">
                      <img src={NoReportFound} alt="No Report Found" />

                      <p>
                        <b>No report found.</b>
                      </p>
                    </div>
                    : */}
                    <table className='no-hover'>
                      <thead>
                        <tr>
                          {TABLE_HEADER.map((val, idx) => {
                            return (
                              <React.Fragment key={`table-head-${idx}`}>
                                <AscendingDescendingTableHeader 
                                  className={val.class}
                                  fieldName={val.value}
                                  title={val.title}
                                  onClick={() => onSort(val.value)}
                                  sortByVal={sort}
                                />
                              </React.Fragment>
                            )
                          })}
                        </tr>
                      </thead>
                      
                      {printedTopicReportData.length === 0 ? 
                        // <tbody>
                        //     <tr>
                        //       <td colspan="5" className="text-center"><b className="grey-font">No report found.</b></td>
                        //     </tr>
                        // </tbody>
                        <tbody>
                            <tr>
                              <td colSpan="5" className="text-center">
                                <div className='lds-ring'>
                                  <div />
                                  <div />
                                  <div />
                                  <div />
                                </div>
                              </td>
                            </tr>
                        </tbody>
                        :
                        <tbody>
                            {printedTopicReportData.map((value, key) => {
                              return (
                                <tr key={`topic-report-show-row-${key}`}>
                                  <td className="col_1">
                                    {value.topicName}
                                  </td>
                                  <td className="col_2">
                                    {value.totalCasesCreated}
                                  </td>
                                  <td className="col_3">
                                    {value.totalCasesClosed}
                                  </td>
                                  <td className="col_4">
                                    {value.averageCaseDuration}
                                  </td>
                                </tr>
                              )
                            })}
                        </tbody>
                      }
                    </table>
                  {/* } */}
                </div>

                <div className="pagination-wrapper">
                  {
                      totalItems > 0 &&
                        <Pagination
                          total={totalItems}
                          page={pageDisplay}
                          listPerPage={50}
                          step={1}
                          onChangePage={handleChangePage}
                        />
                  }
                </div>
              </React.Fragment>
            }
          </div>   
        </React.Fragment>
      </div>
    </SectionWrap>
  );
};

export default TopicReportShow;
