import React, { useState, useEffect } from "react";
import "./SourceText.scss";
import { connect } from 'react-redux';
import { FiAlertCircle, FiEdit, FiInfo, FiRotateCcw, FiUpload, FiUploadCloud } from "react-icons/fi";
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { doToast } from "../../../../../helper/HelperGeneral";
import ErrorOrInfoComp from "../../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import ChatbotServices from "../../../../../services/newServices/ChatbotService";
import PopupLoading from "../../../../reuseableComponent/popupLoading/PopUpLoading";
import PopUpErrorUpload from "../../../../reuseableComponent/popUpErrorUpload/PopUpErrorUpload";

const SourceText = (props) => {

    let [isLoading, setIsLoading] = useState(false);
    let [valName, setValName] = useState("");
    let [valContent, setValContent] = useState("");
    let [pendingUploadFile, setPendingUploadFile] = useState({
        name: "",
        content: "",
      });
    let [errorMessageData, setErrorMessageData] = useState({
      name: "",
      content: "",
    });
    let [isShowModalUploading, setIsShowModalUploading] = useState(false);
    let [isShowModalError, setIsShowModalError] = useState(false);
    let [isShowModalDuplicate, setIsShowModalDuplicate] = useState(false);
    let [duplicateMessage, setDuplicateMessage] = useState("Text source name already exists");

    useEffect(() => {
        resizeTextArea();
    }, []);

    const toggleModalLoading = () => {
        setIsShowModalUploading(!isShowModalUploading);
    }

    const toggleModalError = () => {
        if (isShowModalError) {
            setPendingUploadFile({
                name: "",
                content: "",
            });
        }
        setIsShowModalError(!isShowModalError);
    }

    const toggleModalDuplicate = () => {
        if (isShowModalDuplicate) {
            setPendingUploadFile({
                name: "",
                content: "",
            });
        }
        setIsShowModalDuplicate(!isShowModalDuplicate);
    }

    const onChangeName = (e) => {
        let _valName = e.target.value;
        setValName(_valName);
        if (_valName) {
            let _errorMessageData = {...errorMessageData};
            if (props.checkFileNameExists(_valName)) {
                _errorMessageData.name = duplicateMessage;
            }
            else {
                _errorMessageData.name = "";
            }
            setErrorMessageData(_errorMessageData);
        }
    }

    const onChangeContent = (e) => {
        setValContent(e.target.value);
        if (e.target.value) {
            let _errorMessageData = {...errorMessageData};
            _errorMessageData.content = "";
            setErrorMessageData(_errorMessageData);
        }
        resizeTextArea();
    }

    const resizeTextArea = () => {
        const elements = document.getElementsByClassName("input-content");
        if (!elements) {
            return;
        }
        const textarea = elements[0];
        textarea.style.height = "1px"; // Reset height
        if (textarea.scrollHeight <= 168) {
            textarea.style.height = "168px";
        }
        else {
            textarea.style.height = "" + textarea.scrollHeight + "px";
            // textarea.style.overflow = "hidden";
        }
    }

    const validateText = () => {
        let _errorMessageData = {...errorMessageData};
        let hasError = false;
        if (!valName) {
            _errorMessageData.name = "This field is required";
            hasError = true;
        }
        else if (props.checkFileNameExists(valName)) {
            _errorMessageData.name = "Text source name already exists";
            hasError = true;
        }
        if (!valContent) {
            _errorMessageData.content = "This field is required";
            hasError = true;
        }
        setErrorMessageData(_errorMessageData);
        if (!hasError) {
            // props.onSubmitText('' + valName, '' + valContent);
            // setValName("");
            // setValContent("");
            uploadTextSource();
        }
    }

    const uploadTextSource = () => {
        let item = {
            "name": valName,
            "content": valContent
        };
        let data = {
            "chatbotID": Number(props.match.params.id),
            "type": "text",
            "text": item
        };
        setIsShowModalUploading(true);
        setPendingUploadFile(item);
        ChatbotServices.addKnowledgeBaseItem(props.match.params.orgID, data, (response) => {
            setIsShowModalUploading(false);
            let dataResult = response.dataResult;
            if (dataResult.data.success) {
                doToast(`Source has been successfully uploaded`);
                setPendingUploadFile({
                    name: "",
                    content: "",
                });
                setValName("");
                setValContent("");
                props.fetchPendingChanges();
            }
            else if (dataResult.error.message === "") {
                setDuplicateMessage(dataResult.data.message);
                setIsShowModalDuplicate(true);
            }
            else {
                setIsShowModalError(true);
            }
        });
    }

    return (
        <>
            <div className="source-text-wrapper">
                <div className="form-content-wrapper">
                    <label>
                        <b>Name</b>
                        {/* <span className={`input-counter grey-font ${customFieldVal.fieldCode.length > FIELD_CODE_MAX_LENGTH ? "red-text" : ""}`}>
                            {customFieldVal.fieldCode.length}/{FIELD_CODE_MAX_LENGTH} characters
                        </span> */}
                    </label>
                    <input
                        className={`input-name ${errorMessageData.name ? "border-red" : ""}`} 
                        disabled={isLoading}
                        type="text"
                        id="name"
                        onChange={(e) => onChangeName(e)}
                        value={valName}
                        placeholder="Insert name here"
                    />
                    {
                        errorMessageData.name ?
                        <ErrorOrInfoComp
                            text={errorMessageData.name}
                            _className={"font-red"}
                            icon={<FiAlertCircle />}
                        />
                        :
                        <ErrorOrInfoComp
                            text="Name will only be used for labeling and does not affect chatbot"
                            icon={<FiInfo />}
                        />
                    }
                </div>

                <div className="form-content-wrapper">
                    <label>
                        <b>Content</b>
                    </label>
                    <textarea
                        className={`input-content ${errorMessageData.content ? "border-red" : ""}`} 
                        disabled={isLoading}
                        type="text"
                        id="content"
                        onChange={(e) => onChangeContent(e)}
                        value={valContent}
                        placeholder="Insert content here"
                    />
                    {
                        errorMessageData.content &&
                        <ErrorOrInfoComp
                            text={errorMessageData.content}
                            _className={"font-red"}
                            icon={<FiAlertCircle />}
                        />
                    }
                </div>

                <ButtonWithLoadingOrIcon
                    className="no-fill-button main-button-40"
                    position="left"
                    text="Upload Source"
                    onClickAction={validateText}
                />
            </div>

            <PopupLoading
                isOpen={isShowModalUploading}
                toggle={toggleModalLoading}
                title="Uploading..."
                description="This may take a while, please wait"
                // buttonText={uploadQueue.files.length > 1 ? "Cancel" : ""}
                // buttonText="Cancel"
                // buttonAction={toggleModalLoading}
            />

            <PopUpErrorUpload
                isOpen={isShowModalError && !isShowModalUploading && pendingUploadFile}
                toggle={toggleModalError}
                title="Something Went Wrong"
                description="File upload was interrupted, please check your network and try again."
                errorFiles={[pendingUploadFile]}
                buttonText="Try Again"
                buttonIcon={FiRotateCcw}
                buttonAction={uploadTextSource}
            />

            <PopUpErrorUpload
                isOpen={isShowModalDuplicate && !isShowModalUploading && !isShowModalError && pendingUploadFile}
                toggle={toggleModalDuplicate}
                title="Something Went Wrong"
                description={duplicateMessage}
                errorFiles={[pendingUploadFile]}
                buttonText="Edit"
                buttonIcon={FiEdit}
                buttonAction={() => {
                    toggleModalDuplicate();
                    document.getElementById("name").focus();
                }}
                buttonClass="no-fill-button main-button-48"
            />
        </>
    )
}

const mapStateToProps = state => ({
    // allowedModule: state.allowedModule
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(SourceText)
