import React from "react";
import "./AscendingDescendingTable.scss";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";

const AscendingDescendingTable = ({ 
    onDescending, 
    onAscending,
    fieldName,
    sortVal
}) => (
  <div className="ascending-descending-table-wrapper">
    <FiChevronUp className={`up-button ${(fieldName === sortVal.by && sortVal.order === "ASC") ? "active-sort" : ""}`} onClick={onAscending}/>
    <FiChevronDown className={`down-button ${(fieldName === sortVal.by && sortVal.order === "DESC") ? "active-sort" : ""}`} onClick={onDescending} />
  </div>
);

export default AscendingDescendingTable;
