import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./IntegrationChatGPTUsage.scss";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import ChatbotService from '../../../../services/newServices/ChatbotService';
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import CustomSelectOption from "../../../reuseableComponent/customSelectOption/CustomSelectOption";
import WalletPurple from "../../../../assets/img/wallet-purple.svg";
import TokenRed from "../../../../assets/img/token-red.svg";
import NoReportFound from "../../../../assets/img/no-report-found.svg";
import { doToast } from "../../../../helper/HelperGeneral";
import Chart from 'chart.js';
import { FiInfo, FiCalendar } from "react-icons/fi";

const IntegrationChatGPTUsage = (props) => {
  let { match } = props;
  let [orgID, setOrgID] = useState(false);
  let [chatbotDetail, setIntegrationChatbotDetail] = useState(false);
  let [retrieveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false,
  });
  let [isLoadingGetDetail, setIsLoadingGetDetail] = useState(true);

  useEffect(() => {
    if (orgID) {
      setRetrieveDataError({
        code: false,
        message: false,
      })
      getDetail();
    }
  }, [orgID, match])

  useEffect(() => {
    let _data = {
      "labels": [
        "< 5m",
        "5m - < 15m",
        "15m - < 30m",
        "30m - < 1hr",
        "1hr - < 2hrs",
        "2hrs - < 4hrs",
        "4hrs - < 8hrs",
        "8hrs - < 12hrs",
        "> 12hrs"
      ],
      "datasets": [
        {
          "data": [
            77,
            9,
            4,
            2,
            9,
            4,
            2,
            0,
            133
          ],
          "backgroundColor": [
            "#FFCB99",
            "#FFCB99",
            "#FFCB99",
            "#FFCB99",
            "#FFCB99",
            "#FFCB99",
            "#FFCB99",
            "#FFCB99",
            "#FFCB99"
          ],
        }
      ]
    };

    setTimeout(() => {
      const ctx = document.getElementById('myChatDailyChatSession');
      const myChatDailyChatSession = new Chart(ctx, {
        type: 'bar',
        data: _data,
        options: {
          legend: {
            display: false
          },
          events: ['click'],
          tooltips: {
            enabled: true,
            mode: 'single',
            callbacks: {
              label: function (tooltipItems, data) {
                return tooltipItems.xLabel + ' Cases';
              }
            }
          },
        }
      });

      const ctx2 = document.getElementById('myChatDailyChatMessage');
      const myChatDailyChatMessage = new Chart(ctx2, {
        type: 'bar',
        data: _data,
        options: {
          legend: {
            display: false
          },
          events: ['click'],
          tooltips: {
            enabled: true,
            mode: 'single',
            callbacks: {
              label: function (tooltipItems, data) {
                return tooltipItems.xLabel + ' Cases';
              }
            }
          },
        }
      });
    }, 2000)
  }, [])

  const getDetail = () => {
    let _retrieveDataError = { ...retrieveDataError };
    setIsLoadingGetDetail(true);

    let data = {
      id: Number(match.params.id)
    }

    ChatbotService.detailChatbot(orgID, data, (response) => {
      if (response.dataResult.status === 200) {
        let item = response.dataResult.data.chatbot;
        setIntegrationChatbotDetail(item);
      } else {
        _retrieveDataError.message = response.dataResult.error.message;
        _retrieveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retrieveDataError);
        doToast(response.dataResult.error.message, 'fail');
      }

      setIsLoadingGetDetail(false);
    });
  };

  const checkOrgID = (match) => {
    const { orgID: param } = match.params;
    // if (!param) getOrganization();
    if (!param) return false;
    else return param;
  };

  const back = () => {
    props.history.push(`/o/${orgID}/integration/chatbots/detail/${props.match.params.id}`);
  };

  const doMount = async () => {
    const paramID = checkOrgID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = "/organization/new";
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          props.history.push(`/o/${urlOrgID}/organization/billing`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    doMount();
  }, []);

  return (
    <SectionWrap {...props} orgID={orgID} withHeader withSideMenu>
      <BackButton text="Back to Chatbot Details" onClick={back} />

      <div className="view-chatgpt-usage sectionWrap">
        <div className="view-chatgpt-usage-top">
          <b><FiInfo /> Chatbot Usage</b>
        </div>

        {isLoadingGetDetail || retrieveDataError.code ?
          <div className="chatbot-detail-loading-wrapper">
            <RetrieveDataLoading
              isLoading={isLoadingGetDetail}
              errorMessage={retrieveDataError.message}
            />
          </div>
          :
          chatbotDetail ?
            <React.Fragment>
              <div className="view-chatgpt-usage-bottom">
                <div className="view-chatgpt-usage-filter-wrapper">
                  <CustomSelectOption
                    iconLeft={<FiCalendar className="icon-left" />}
                    isDisabled={false}
                    optionListProps={[
                      {
                        value: "2024, 20 Jul - 20 Aug",
                        label: "2024, 20 Jul - 21 Aug",
                      },
                      {
                        value: "2024, 30 Jul - 30 Aug",
                        label: "2024, 31 Jul - 31 Aug",
                      }
                    ]}
                    valueDropdownProps={
                      {
                        value: "2024, 20 Jul - 20 Aug",
                        label: "2024, 20 Jul - 21 Aug",
                      }
                    }
                    placeholderProps={"Select period"}
                    onClickDropDownListOptionProps={() => { }}
                    specificClassNameProps="custom-select-usage-period"
                    _className={``}
                  />
                </div>

                <div className="view-chatgpt-usage-top-usage-wrapper">
                  <div className="package-information-left">
                    <p className="package-information-top">
                      <FiInfo />
                      <b>Package Information</b>
                    </p>

                    <p className="package-information-list">
                      <b>Chatbot Name: </b>
                      Greetings
                    </p>

                    <p className="package-information-list">
                      <b>Tier: </b>
                      Solo Tier TapTalk.io
                    </p>

                    <p className="package-information-list">
                      <b>Token Limit: </b>
                      15.000.000
                    </p>
                  </div>

                  <div className="package-information-right">
                    <div className="package-information-right-list">
                      <img src={TokenRed} alt="" />

                      <p className="package-information-right-text-1">
                        <b>Token Usage</b>
                      </p>

                      <p className="package-information-right-text-2">
                        <b>1.000</b>
                      </p>

                      <p className="package-information-right-text-3">
                        <b className="grey-text">/ 10.000.000</b>
                      </p>
                    </div>

                    <div className="package-information-right-list">
                      <img src={TokenRed} alt="" />

                      <p className="package-information-right-text-1">
                        <b>Total Chat Session</b>
                      </p>

                      <p className="package-information-right-text-2">
                        <b>1.000</b>
                      </p>
                    </div>

                    <div className="package-information-right-list">
                      <img src={WalletPurple} alt="" />

                      <p className="package-information-right-text-1">
                        <b>Total Messages</b>
                      </p>

                      <p className="package-information-right-text-2">
                        <b>10.000.000</b>
                      </p>

                      <p className="package-information-right-text-3">
                        <b className="grey-text">/ billing cycle</b>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="view-chatgpt-usage-bar-chart-wrapper">
                  <div className="title-wrapper">
                    <b>Daily Chat Session</b>

                    <div className="bar-chart-legend-wrapper">
                      <div className="bar-chart-legend-box">

                      </div>

                      # of Chat Session
                    </div>
                  </div>

                  <canvas className={"force-hide"} id="myChatDailyChatSession" width="100" height="35"></canvas>

                  <div className="box-no-data">
                    <img src={NoReportFound} alt="" />
                    <br />
                    <p>
                      <b>No Report Found</b>
                    </p>
                  </div>
                </div>

                <div className="view-chatgpt-usage-bar-chart-wrapper">
                  <div className="title-wrapper">
                    <b>Daily Messages <span className="grey-text">(200.000)</span></b>

                    <div className="bar-chart-legend-wrapper">
                      <div className="bar-chart-legend-box">

                      </div>

                      # of Chat Session
                    </div>
                  </div>

                  <canvas className={""} id="myChatDailyChatMessage" width="100" height="35"></canvas>

                  <div className="box-no-data">
                    <img src={NoReportFound} alt="" />
                    <br />
                    <p>
                      <b>No Report Found</b>
                    </p>
                  </div>
                </div>
              </div>
            </React.Fragment>
            :
            ""
        }
      </div>
    </SectionWrap>
  );
};

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {

};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IntegrationChatGPTUsage);
