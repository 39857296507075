import React from 'react';
import './ChatRoomMessageInTokopedia.scss';
import { FiExternalLink } from 'react-icons/fi';
import Helper from '../../../../helper/HelperChat';
import { printLastMessageName, generateElipsis } from '../../../../helper/HelperGeneral';
import { CHAT_TYPE } from '../../../../helper/HelperConst';
import MessageDeleteGrey from "../../../../assets/img/icon-notallowed-grey.svg";
import { taptalk } from '@taptalk.io/web-sdk';
// import { MdInsertDriveFile } from 'react-icons/md';
import iconAgent from '../../../../assets/img/chatroom/icon-agent.svg';
import iconChatbot from '../../../../assets/img/chatroom/icon-chatbot.svg';
import iconReplaceAvatar from "../../../../assets/img/replace-avatar.svg";
import MessageAction from '../../../reuseableComponent/messageAction/MessageAction';
import ChatRoomReplyInMessage from "../chatRoomReplyInMessage/chatRoomReplyInMessage/ChatRoomReplyInMessage";
import ChatRoomMessageLinkPreview from '../chatRoomMessageLinkPreview/ChatRoomMessageLinkPreview';
import { setReplyMessage } from '../../../../redux/actions/reduxActionReplyMessage';
import { connect } from 'react-redux';

const ROLE_LIST = {
    agent: {
        value: "agent",
        label: "Agent"
    },
    organization: {
        value: "organization",
        label: "Organization"
    },
    chatbot: {
        value: "chatbot",
        label: "Chatbot"
    }
}

var ChatRoomMessageInTokopedia = (props) => {
    let {
        singleChatDataProps,
        activeUserID,
        onReplyMessage,
        onForwardMessage,
        scrollToReply,
        caseData
        //   activeRoom
    } = props;

    // let messageActionView = (message) => {
    //     let onClickReply = () => {
    //         onReplyMessage(message)
    //     }

    //     return (
    //         <div
    //             className={`message-action-wrapper message-action-right with-forward`}
    //         >
    //             <div className="message-action-button-wrapper" title="Copy to clipboard" onClick={() => Helper.copyToClipBoard(message.data.name)}>
    //                 <FiCopy />
    //             </div>

    //             <div className="message-action-button-wrapper reply-button" title="Reply" onClick={() => onClickReply()}>
    //                 <FaReply />
    //             </div>

    //             <div className="message-action-button-wrapper reply-button forward-button" title="Forward" onClick={() => onForwardMessage(message)}>
    //                 <FaReply />
    //             </div>
    //         </div>
    //     )
    // }

    let clickExternal = (link) => {
        window.open(link, "_blank");
    }

    return (
        <div className="chat-room-message-in-tokopedia--wrapper" id={`message-${singleChatDataProps.localID}`}>
            {/* {singleChatDataProps.room.type === 2 && */}
            <div className="group-sender-avatar-wrapper" style={{ background: taptalk.getRandomColor(printLastMessageName(singleChatDataProps, caseData, true, true, props.inboxConfigsRedux)) }}>
                {singleChatDataProps.user.imageURL.thumbnail !== "" ?
                    <img src={singleChatDataProps.user.imageURL.thumbnail} alt="" onError={(e) => { e.target.onerror = null; e.target.src = iconReplaceAvatar; }} />
                    :
                    <b>{Helper.renderUserAvatarWord(printLastMessageName(singleChatDataProps, caseData, true, true, props.inboxConfigsRedux))}</b>
                }

                {(singleChatDataProps.user.userRole.code === ROLE_LIST.agent.value ||
                    singleChatDataProps.user.userRole.code === ROLE_LIST.organization.value
                ) &&
                    <img src={iconAgent} alt="" className="avatar-icon-badge" />
                }

                {(singleChatDataProps.user.userRole.code === ROLE_LIST.chatbot.value) &&
                    <img src={iconChatbot} alt="" className="avatar-icon-badge" />
                }
            </div>
            {/* } */}

            {singleChatDataProps.isDeleted ?
                <div className={`message-in-bubble deleted-bubble ${singleChatDataProps.isDeleted ? 'deleted-group-in' : ''}`}>
                    {/* {props.forwarded &&
                    <div className="forwarded-message">
                        <b>Forwarded</b>
                        <br />
                        From: <b>Keju Manis</b>
                    </div>
                } */}

                    <React.Fragment>
                        {singleChatDataProps.room.type === 2 &&
                            <p className="group-sender-name-wrapper">
                                <b>{printLastMessageName(singleChatDataProps, caseData, true, false, props.inboxConfigsRedux)}</b>
                            </p>
                        }

                        <img src={MessageDeleteGrey} alt="" className="deleted-icon" />
                        This message was deleted.
                    </React.Fragment>
                </div>
                :

                <React.Fragment>
                    <div className="message-in-bubble"
                    //  style={
                    //      singleChatDataProps.replyTo.localID !== "" ? 
                    //         (singleChatDataProps.replyTo.messageType === CHAT_TYPE.TAPChatMessageTypeText ?
                    //             {paddingTop: "64px"} 
                    //             :
                    //             {paddingTop: "84px"}
                    //         )
                    //         : 
                    //         {}
                    // }
                    >
                        <p className="group-sender-name-wrapper">
                            <b>
                                {singleChatDataProps.user.userRole.code === ROLE_LIST.agent.value &&
                                    <span className="agent-span">{ROLE_LIST.agent.label} - </span>
                                }

                                {singleChatDataProps.user.userRole.code === ROLE_LIST.organization.value &&
                                    <span className="agent-span">{ROLE_LIST.organization.label} - </span>
                                }

                                {singleChatDataProps.user.userRole.code === ROLE_LIST.chatbot.value &&
                                    <span className="agent-span">{ROLE_LIST.chatbot.label} - </span>
                                }

                                {printLastMessageName(singleChatDataProps, caseData, true, false, props.inboxConfigsRedux)}
                            </b>
                        </p>

                        {singleChatDataProps.replyTo.localID !== "" &&
                            <ChatRoomReplyInMessage
                                message={singleChatDataProps}
                                activeUserID={activeUserID}
                                scrollToReply={scrollToReply}
                            />
                        }

                        {/* {singleChatDataProps.forwardFrom.localID !== "" &&
                        <div className="forwarded-message">
                            <b>Forwarded</b>
                            <br />
                            From: <b>{singleChatDataProps.forwardFrom.fullname}</b>
                        </div>
                    } */}

                        <div className='tokopedia-image-wrapper'>
                            <img alt={singleChatDataProps.body} src={singleChatDataProps.data.image_url} />
                        </div>

                        <div className='tokopedia-product-description-wrapper'>
                            <span className="message-body" dangerouslySetInnerHTML={{ __html: generateElipsis(Helper.lineBreakChatRoom(singleChatDataProps.data.name), 100) }}></span>

                            <p className='tokopedia-price-wrapper'>
                                <b>{singleChatDataProps.data.price}</b>
                            </p>
                        </div>

                        <div className='tokopedia-link' onClick={() => clickExternal(singleChatDataProps.data.product_url)}>
                            <FiExternalLink />
                            <b>View Product</b>
                        </div>

                        {singleChatDataProps.type === CHAT_TYPE.TAPChatMessageTypeLink &&
                            <ChatRoomMessageLinkPreview
                                isOut={true}
                                message={singleChatDataProps}
                            />
                        }

                        <p className="message-info">
                            {Helper.getDateMonthYear(singleChatDataProps.created)} <span className="centered-dot" /> {Helper.getHourMinute(singleChatDataProps.created)}
                        </p>

                        {/* {messageActionView(singleChatDataProps)} */}
                        <MessageAction
                            singleChatDataProps={singleChatDataProps}
                            _className="message-action-dropdown-left"
                            replyAction={() => onReplyMessage(singleChatDataProps)}
                            forwardAction={() => onForwardMessage(singleChatDataProps)}
                        />
                    </div>
                </React.Fragment>
            }
        </div>
    );
}


const mapStateToProps = state => ({
    replyMessage: state.replyMessage,
    activeRoom: state.activeRoom,
    inboxConfigsRedux: state.inboxConfigsRedux
});

const mapDispatchToProps = {
    setReplyMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomMessageInTokopedia);
