import React, { useEffect } from "react";
import "./ManualPaymentVerificationModal.scss";
import { Modal, ModalBody } from "reactstrap";
import { FiX, FiCheckCircle, FiImage, FiArrowLeft } from "react-icons/fi";
// import { doToast } from "../../../../../../helper/HelperGeneral";
// import mandiriIcon from "../../../../../../assets/img/mandiri.png";

// const STEP = {
//     1: {
//         label: "Payment Instructions"
//     },
//     2: {
//         label: "Verify Payment"
//     }
// };

let ManualPaymentVerificationModal = (props) => {
    // let [stepVerification, setStepVerification] = useState(STEP[1]);

    // let copyToClip = () => {
    //     document.getElementById("account-mandiri").select();
    //     document.execCommand("copy");
    //     doToast("Account Number Copied");
    // };

    let resetManualVerification = () => {
        
    }

    useEffect(() => {
        if(!props.isOpen) {
            resetManualVerification();
        }
    }, [props.isOpen])

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} className="modal-manual-verification">
            <ModalBody>
                <FiX onClick={props.toggle} className="close-modal" />
                
                <div className="manual-verification-progress-bar-wrapper">
                    <div className="progress-value-wrapper">
                        <div className="progress-step-wrapper">
                            <div className="progress-step-inner">
                                <b>1</b>
                            </div>                          
                        </div>
                        
                        <br />
                        <b className="progress-label">Payment Instructions</b>
                    </div>

                    <div className="line-separator orange-line">
                        {/* orange-line */}
                    </div>

                    <div className="progress-value-wrapper selected-value-wrapper">
                        <div className="progress-step-wrapper">
                            <div className="progress-step-inner">
                                <b>2</b>
                            </div>                          
                        </div>
                        
                        <br />
                        <b className="progress-label">Verify Payment</b>
                    </div>
                </div>

                {/* step 1 */}
                {/* <div className="manual-step-1">
                    <div className="manual-middle">
                        <div className="warning-top">
                            Please transfer the exact amount below to the following account
                        </div>

                        <label><b>Amount</b></label>
                        <div className="value-wrapper">
                            <b>IDR 1.199.000</b>

                            <FiCopy className="icon-copy" />
                        </div>

                        <label><b>PT Tap Talk Teknologi</b></label>
                        <div className="value-wrapper value-wrapper-bank">
                            <img src={mandiriIcon} alt="" />

                            <b>{process.env.REACT_APP_TAPTALK_BANK_ACCOUNT_NUMBER_MANDIRI}</b>

                            <FiCopy className="icon-copy" />
                        </div>
                    </div>

                    <p className="warning-bottom">
                        Once transferred, click on the button below to continue
                    </p>
                    
                    <button className="orange-button main-button-40 button button-submit">
                        <FiCheckCircle />
                        <b>
                            I Have Transferred Payment
                        </b>
                    </button>
                </div> */}
                {/* step 1 */}

                {/* step 2 */}
                <div className="manual-step-2">
                    <div className="manual-middle">
                        <div className="warning-top">
                            Please complete the form below
                        </div>

                        <div className="form-content-wrapper">
                            <label><b>Your Bank Account Name</b></label>
                            <input type="text" placeholder="Enter Bank Account Name" />
                        </div>
                        
                        <div className="form-content-wrapper">
                            <label><b>Your Bank Name</b></label>
                            <input type="text" placeholder="Enter Bank Name" />
                        </div>

                        <div className="form-content-wrapper">
                            <label><b>Amount Transferred</b></label>
                            <input type="text" placeholder="IDR" />
                        </div>

                        <div className="form-content-wrapper">
                            <label><b>Upload Image Proof</b></label>
                        </div>

                        <label htmlFor="select-image">
                            <button className="no-fill-button main-button-40 button-select-image">
                                <FiImage />
                                <b>Select Image</b>
                            </button>
                        </label>
                    </div>
                    
                    <button className="no-fill-button main-button-40 button button-submit">
                        <FiArrowLeft />
                        <b>
                            Go Back
                        </b>
                    </button>

                    <button className="orange-button main-button-40 button button-submit button-verify">
                        <FiCheckCircle />
                        <b>
                            Verify Payment
                        </b>
                    </button>
                </div>
                {/* step 2 */}
            </ModalBody>
        </Modal>
    )
}

export default ManualPaymentVerificationModal;