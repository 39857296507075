const CHAT_TYPE = {
    TAPChatMessageTypeText: 1001,
    TAPChatMessageTypeImage: 1002,
    TAPChatMessageTypeVideo: 1003,
    TAPChatMessageTypeFile: 1004,
    TAPChatMessageTypeLocation: 1005,
    TAPChatMessageTypeContact: 1006,
    TAPChatMessageTypeSticker: 1007,
    TAPChatMessageTypeVoice: 1008,
    TAPChatMessageTypeAudio: 1009,
    TAPChatMessageTypeLink: 1010,
    TAPChatMessageTypeSystemMessage: 9001,
    TAPChatMessageTypeUnreadMessageIdentifier: 9002,
    TAPChatMessageTypeCaseClosed: 3001,
    TAPChatMessageTypeLeaveReview: 3003,
    TAPChatMessageTypeLeaveReviewDisabled: 3004,
    MessageTypeBroadcastText: 3011,
    MessageTypeBroadcastImage: 3012,
    MessageTypeWhatsAppBATemplateText: 3021,
    MessageTypeWhatsAppBATemplateImage: 3022,
    MessageTypeWhatsAppBATemplateVideo: 3023,
    MessageTypeWhatsAppBATemplateFile: 3024,
    TAPChatMessageTypeTextInteractive: 3025,
    TAPChatMessageTypeImageInteractive: 3026,
    TAPChatMessageTypeVideoInteractive: 3027,
    TAPChatMessageTypeDocumentInteractive: 3028,
    MessageTypeTokopedia: 3031,
    TAPChatMessageTypeEmail: 3041,
    CASE_CREATED: 3005,
    CASE_CLOSED: 3001,
    CASE_REOPEN: 3002,
    CASE_RATING_BUTTON: 3003,
    CASE_RATING_BUTTON_DISABLED: 3004,
    CASE_UPDATE_AGENT_CHANGE: 3006,
    CASE_UPDATE_DETAIL_UPDATE: 3007
}

const MAX_PINNED = 10;

const MUTE_OPTION = [
    {
        label: "1 Hour",
        val: 3600000
    },
    {
        label: "8 Hours",
        val: 28800000
    },
    {
        label: "3 Days",
        val: 259200000
    },
    {
        label: "Always",
        val: undefined
    },
]

const OTHER_TAB_OPT = [
    {
        label: "Default",
        value: "default"
    },
    {
        label: "Always Show",
        value: "always_show"
    },
    {
        label: "Always Hide",
        value: "always_hide"
    }
]

const NEED_REPLY_OPT = [
    {
        label: "Default",
        value: "default"
    },
    {
        label: "Custom",
        value: "custom"
    }
]

export { CHAT_TYPE, MUTE_OPTION, MAX_PINNED, OTHER_TAB_OPT, NEED_REPLY_OPT };