import React from "react";
import "./TopBarBlocking.scss";

let TopBarBlocking = (props) => {
    return (
        <div className="top-bar-blocking-wrapper" style={props.style}>
            {props.children}
        </div>
    )
}

export default TopBarBlocking;
