import React, { useState, useEffect } from "react";
import "./IntegrationChatGPTSetupSubmission.scss";
import { FiInfo } from "react-icons/fi";
import { connect } from 'react-redux';
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import { doToast } from "../../../../helper/HelperGeneral";
import ChatbotServices from "../../../../services/newServices/ChatbotService";
import PopupDiscard from "../../../reuseableComponent/popupDiscard/PopupDiscard";

const IntegrationChatGPTSetupSubmission = (props) => {
    let [versionDescriptionOrigin, setVersionDescriptionOrigin] = useState("");
    let [errorMessageData, setErrorMessageData] = useState({
        desc: ""
    })
    let [loadingSubmit, setLoadingSubmit] = useState(false);
    let [isShowModalDiscard, setIsShowModalDiscard] = useState(false);

    useEffect(() => {
        if (!versionDescriptionOrigin) {
            setVersionDescriptionOrigin(JSON.stringify(props.versionDescription));
        }
    }, [props.versionDescription]);

    const toggleModalDiscard = () => {
        setIsShowModalDiscard(!isShowModalDiscard);
    }

    let onChangeText = (e) => {
        let _data = { ...props.versionDescription };
        _data = e.target.value;
        props.setVersionDescription(_data);
        if (_data) {
            let _errorMessageData = { ...errorMessageData };
            setErrorMessageData(_errorMessageData);
            _errorMessageData[e.target.id] = "";
        }
    }

    let onClickSubmitChanges = () => {
        let _errorMessageData = { ...errorMessageData };
        let errCount = 0;

        if (props.versionDescription === "") {
            _errorMessageData.desc = "This field is required";
            errCount = errCount + 1;
        }

        if (errCount !== 0) {
            setErrorMessageData(_errorMessageData);
        }
        else {
            setLoadingSubmit(true);

            ChatbotServices.setChatGptSubmission(props.match.params.orgID, { chatbotID: parseInt(props.match.params.id), versionDescription: props.versionDescription }, (res) => {
                let dataRes = res.dataResult;

                if (dataRes.error.message) {
                    setLoadingSubmit(false);
                    doToast(dataRes.error.message, "fail");
                }
                else {
                    if (dataRes.data.success) {
                        doToast(dataRes.data.message);
                        setVersionDescriptionOrigin(JSON.stringify(props.versionDescription));

                        setTimeout(() => {
                            props.backToChatbotDetails();
                        }, 3000)
                    } else {
                        setLoadingSubmit(false);
                        doToast(dataRes.data.message, "fail");
                    }
                }
            })
        }
    }

    return (
        <>
            <SectionWrap
                {...props}
                orgID={props.match.params.orgID}
                withHeader
                withSideMenu
                noWrap
                dataOrigin={versionDescriptionOrigin}
                dataAfterChange={JSON.stringify(props.versionDescription)}
            >
                <div className="nav-button-wrapper">
                    <BackButton
                        onClick={() => {
                            if (versionDescriptionOrigin !== JSON.stringify(props.versionDescription)) {
                                setIsShowModalDiscard(true);
                            }
                            else {
                                props.setActiveTab(1);
                            }
                        }}
                        // text={"Back to Handover Settings"}
                        text="Back to Prompt Settings"
                    />
                </div>

                <div className="chatgpt-setup-submission-page-wrapper sectionWrap">
                    <p className="top-title">
                        <b>Submission</b>

                        {!loadingSubmit ?
                            <ButtonWithLoadingOrIcon
                                className="orange-button main-button-40"
                                position="right"
                                text="Submit Changes"
                                onClickAction={() => {
                                    onClickSubmitChanges();
                                }}
                            />
                            :
                            <ButtonWithLoadingOrIcon
                                className="orange-button main-button-40"
                                isLoading
                                loadingColor="gray"
                                position="left"
                                text="Submit Changes"
                                isDisabled
                            />
                        }

                    </p>

                    <div className="form-content-wrapper">
                        <label>
                            <b>Version Description</b>
                        </label>

                        <input
                            type="text"
                            placeholder="Input version description"
                            value={props.versionDescription}
                            id={"desc"}
                            onChange={(e) => onChangeText(e)}
                            className={errorMessageData.desc === "" ? "" : "border-red"}
                        />

                        <ErrorOrInfoComp
                            text={errorMessageData.desc === "" ? `Version description will appear in every version history to help differentiate any changes` : errorMessageData.desc}
                            icon={<FiInfo />}
                            _className={errorMessageData.desc === "" ? "" : "red-text"}
                        />
                    </div>
                </div>
            </SectionWrap>

            <PopupDiscard
                dataOrigin={versionDescriptionOrigin}
                dataAfterChange={JSON.stringify(props.versionDescription)}
                isOpenDiscard={isShowModalDiscard}
                toggleDiscard={toggleModalDiscard}
                discardProgress={() => {
                    setIsShowModalDiscard(false);
                    props.setActiveTab(1);
                }}
                discardDesc={props.discardDesc}
            />
        </>
    )
}

const mapStateToProps = state => ({
    allowedModule: state.allowedModule
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationChatGPTSetupSubmission)