import React from "react";
import "./NextButton.css";
import { FaChevronRight } from "react-icons/fa";

const NextButton = ({ text, onClick, _className }) => (
  <button className={`comp-next-button ${_className}`} onClick={onClick}>
    <p>
      {text}
      &nbsp; &nbsp;
      <FaChevronRight />
    </p>
  </button>
);

export default NextButton;
