import React from 'react';
import './ChatRoomMessageInRating.scss';
import Helper from '../../../../helper/HelperChat';
import MessageDeleteGrey from "../../../../assets/img/icon-notallowed-grey.svg";
import { taptalk } from '@taptalk.io/web-sdk';
import iconAgent from '../../../../assets/img/chatroom/icon-agent.svg';
import iconChatbot from '../../../../assets/img/chatroom/icon-chatbot.svg';
import iconReplaceAvatar from "../../../../assets/img/replace-avatar.svg";
import ChatRoomReplyInMessage from "../chatRoomReplyInMessage/chatRoomReplyInMessage/ChatRoomReplyInMessage";
import { setReplyMessage } from '../../../../redux/actions/reduxActionReplyMessage';
import { connect } from 'react-redux';
import MessageAction from '../../../reuseableComponent/messageAction/MessageAction';


const ROLE_LIST = {
    agent: {
        value: "agent",
        label: "Agent"
    },
    organization: {
        value: "organization",
        label: "Organization"
    },
    chatbot: {
        value: "chatbot",
        label: "Chatbot"
    }
}

var ChatRoomMessageInRating = (props) => {
    let {
        singleChatDataProps,
        activeUserID,
        onReplyMessage,
        scrollToReply,
        //   activeRoom
    } = props;

    //   let messageActionView = (message) => {
    //       let onClickReply = () => {
    //          onReplyMessage(message)
    //       }

    //       return (
    //           <div className="message-action-wrapper message-action-right">  
    //                 <div className="message-action-button-wrapper" title="Copy to clipboard" onClick={() => Helper.copyToClipBoard(message.body)}>
    //                     <FiCopy />
    //                 </div>

    //                 <div className="message-action-button-wrapper reply-button" title="Reply"  onClick={() => onClickReply()}>
    //                     <FaReply />
    //                 </div>
    //            </div>
    //       )
    //   }

    let printBody = (message) => {
        let _split = message.split("\n");

        if (props.caseDetailData && props.caseDetailData.case.medium !== "launcher") {
            // if(_split[_split.length - 1].includes("case_rating")) {
            //     message = message.replace(_split[_split.length - 1], "");
            //     message = message.replace("Rate your conversation:", "");
            // }

            _split.splice((_split.length - 2), 2);
        }

        return _split.join("\n");
    }

    return (
        <div className="chat-room-message-in-wrapper" id={`message-${singleChatDataProps.localID}`}>
            {/* {singleChatDataProps.room.type === 2 && */}
            <div className="group-sender-avatar-wrapper" style={{ background: taptalk.getRandomColor(singleChatDataProps.user.fullname) }}>
                {singleChatDataProps.user.imageURL.thumbnail !== "" ?
                    <img src={singleChatDataProps.user.imageURL.thumbnail} alt="" onError={(e) => { e.target.onerror = null; e.target.src = iconReplaceAvatar; }} />
                    :
                    <b>{Helper.renderUserAvatarWord(singleChatDataProps.user.fullname)}</b>
                }

                {(singleChatDataProps.user.userRole.code === ROLE_LIST.agent.value ||
                    singleChatDataProps.user.userRole.code === ROLE_LIST.organization.value
                ) &&
                    <img src={iconAgent} alt="" className="avatar-icon-badge" />
                }

                {(singleChatDataProps.user.userRole.code === ROLE_LIST.chatbot.value) &&
                    <img src={iconChatbot} alt="" className="avatar-icon-badge" />
                }
            </div>
            {/* } */}

            {singleChatDataProps.isDeleted ?
                <div className={`message-in-bubble-rating deleted-bubble ${singleChatDataProps.isDeleted ? 'deleted-group-in' : ''}`}>
                    {/* {props.forwarded &&
                    <div className="forwarded-message">
                        <b>Forwarded</b>
                        <br />
                        From: <b>Keju Manis</b>
                    </div>
                } */}

                    <React.Fragment>
                        {singleChatDataProps.room.type === 2 &&
                            <p className="group-sender-name-wrapper">
                                <b>{singleChatDataProps.user.fullname}</b>
                            </p>
                        }

                        <img src={MessageDeleteGrey} alt="" className="deleted-icon" />
                        This message was deleted.
                    </React.Fragment>
                </div>
                :

                <React.Fragment>
                    <div className="message-in-bubble-rating">
                        <p className="group-sender-name-wrapper">
                            <b>
                                {singleChatDataProps.user.userRole.code === ROLE_LIST.agent.value &&
                                    <span className="agent-span">{ROLE_LIST.agent.label} - </span>
                                }

                                {singleChatDataProps.user.userRole.code === ROLE_LIST.organization.value &&
                                    <span className="agent-span">{ROLE_LIST.organization.label} - </span>
                                }

                                {singleChatDataProps.user.userRole.code === ROLE_LIST.chatbot.value &&
                                    <span className="agent-span">{ROLE_LIST.chatbot.label} - </span>
                                }

                                {singleChatDataProps.user.fullname}
                            </b>
                        </p>

                        {singleChatDataProps.replyTo.localID !== "" &&
                            <ChatRoomReplyInMessage
                                message={singleChatDataProps}
                                activeUserID={activeUserID}
                                scrollToReply={scrollToReply}
                            />
                        }

                        {/* {singleChatDataProps.forwardFrom.localID !== "" &&
                        <div className="forwarded-message">
                            <b>Forwarded</b>
                            <br />
                            From: <b>{singleChatDataProps.forwardFrom.fullname}</b>
                        </div>
                    } */}

                        <span className="message-body" dangerouslySetInnerHTML={{ __html: Helper.lineBreakChatRoom(printBody(singleChatDataProps.body).trim()) }}></span>

                        <p className="message-info">
                            {Helper.getDateMonthYear(singleChatDataProps.created)} <span className="centered-dot" /> {Helper.getHourMinute(singleChatDataProps.created)}
                        </p>

                        {/* {messageActionView(singleChatDataProps)} */}
                        <MessageAction
                            singleChatDataProps={singleChatDataProps}
                            _className="message-action-dropdown-left"
                            replyAction={() => onReplyMessage(singleChatDataProps)}
                            hideForward
                        />

                    </div>
                </React.Fragment>
            }
        </div>
    );
}


const mapStateToProps = state => ({
    replyMessage: state.replyMessage,
    activeRoom: state.activeRoom,
    caseDetailData: state.caseDetailData
});

const mapDispatchToProps = {
    setReplyMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomMessageInRating);
