import React from "react";
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { FiArrowLeft } from "react-icons/fi";
import { SIDEMENU_MAP } from '../../../constants/taplive';
import { findMyOrgID } from "../../../helper/HelperGeneral";
// import { Scrollbars } from 'react-custom-scrollbars'; 

let SubMenuIntegration = (props) => {
    // const style = {
    //     scrollStyle: {
    //       position: "relative",
    //       backgroundColor: "rgb(199,98,0)",
    //       right: "-5px",
    //       borderRadius: "8px",
    //       width: "4px"
    //     }
    // };

    let isSubModuleAllowed = (menuCode) => {
        let _isAllowed = false;

        if (props._allowedModules) {
            if (menuCode === "channel") {
                SIDEMENU_MAP.integration.map(v => {
                    let idxModule = props._allowedModules.findIndex(item => item === menuCode);

                    if (idxModule !== -1) {
                        _isAllowed = true;
                    }

                    if (props._allowedModules.join().includes("channel")) {
                        _isAllowed = true;
                    }

                    return null;
                });
            } else {
                let findIndexModule = props._allowedModules.findIndex(v => v === menuCode);
                let findIndexModuleChild = props._allowedModules.findIndex(v => v === menuCode.split(":")[0]);

                if (findIndexModule !== -1 || findIndexModuleChild !== -1) {
                    _isAllowed = true;
                }
            }
        } else {
            _isAllowed = true;
        }

        return _isAllowed;
    }

    return (
        // Object.props.keys(props.sideMenu.submenuList).map((props.value, props.key) => {
        //     return (
        <>
            {(props._isActiveChannels && props._key === 1) &&
                <>
                    <p className="back-to-main-menu-sidemenu" onClick={props.onClickBackToMainMenu}>
                        <FiArrowLeft />
                        <b>Back to Main Menu</b>
                    </p>

                    <p className="channels-text-side-menu">
                        <b>CHANNELS</b>
                    </p>
                </>
            }


            {props.sideMenu.submenuList[props.value].activeValue === "kataai" ?
                <>
                    {(findMyOrgID(props.orgID) === "1" || findMyOrgID(props.orgID) === "3254" || findMyOrgID(props.orgID) === "6802") ?
                        <>
                            {isSubModuleAllowed(props.sideMenu.submenuList[props.value].customRoleKey) &&
                                <NavLink to={props.sideMenu.submenuList[props.value].pathName} key={`sub-menu-${props.key}`} onClick={() => { props.onClickSubMenu(props.sideMenu.submenuList[props.value].activeValue) }}>
                                    <div
                                        className={`
                            sub-menu-content integration-submenu 
                            ${props.checkActiveSubmenu(props.sideMenu.submenuList[props.value].subPath) ? "active-sub-menu" : ""}
                            ${props.findMediumCounter(props.value) !== 0 ? "sub-menu-overflow" : ""}
                            ${(props.sideMenu.submenuList[props.value].name === "Google Business Messages" && props.findMediumCounter(props.value) === 0) ? "force-hide" : ""}
                        `}
                                    >
                                        <div className={`sub-menu-icon  ${props.value}`}></div>
                                        <b>{props.sideMenu.submenuList[props.value].name}</b>

                                        {/* medium count */}
                                        {(
                                            props.sideMenu.activeMenuBlock === "integration"
                                            //    props.sideMenu.submenuList[props.value].name === "Channels"
                                        ) &&
                                            (props.findMediumCounter(props.value) !== 0 ?
                                                <div className="medium-counter">
                                                    {props.mediumData.hideChannelCounts ? "" : props.findMediumCounter(props.value)}
                                                </div>
                                                :
                                                ""
                                            )
                                        }
                                        {/* medium count */}
                                    </div>
                                </NavLink>
                            }

                        </>
                        :
                        ""
                    }
                </>
                :
                <>
                    {isSubModuleAllowed(props.sideMenu.submenuList[props.value].customRoleKey) &&
                        <NavLink to={props.sideMenu.submenuList[props.value].pathName} key={`sub-menu-${props.key}`} onClick={() => { props.onClickSubMenu(props.sideMenu.submenuList[props.value].activeValue) }}>
                            <div
                                className={`
                                sub-menu-content integration-submenu 
                                ${props.checkActiveSubmenu(props.sideMenu.submenuList[props.value].subPath) ? "active-sub-menu" : ""}
                                ${props.findMediumCounter(props.value) !== 0 ? "sub-menu-overflow" : ""}
                                ${(props.sideMenu.submenuList[props.value].name === "Google Business Messages" && props.findMediumCounter(props.value) === 0) ? "force-hide" : ""}
                            `}
                            >
                                <div className={`sub-menu-icon  ${props.value}`}></div>
                                <b>{props.sideMenu.submenuList[props.value].name}</b>

                                {/* medium count */}
                                {(
                                    props.sideMenu.activeMenuBlock === "integration"
                                    //    props.sideMenu.submenuList[props.value].name === "Channels"
                                ) &&
                                    (props.findMediumCounter(props.value) !== 0 ?
                                        <div className="medium-counter">
                                            {props.mediumData.hideChannelCounts ? "" : props.findMediumCounter(props.value)}
                                        </div>
                                        :
                                        ""
                                    )
                                }
                                {/* medium count */}
                            </div>
                        </NavLink>
                    }
                </>
            }
        </>
        // })
    )
}

const mapStateToProps = state => ({
    sideMenu: state.sideMenu,
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(SubMenuIntegration);