import React from "react";
import SlaConfigPage from "./slaConfig/SlaConfigPage";

let SlaConfig = (props) => {
    return (
        <div className="integration-taptalk-sla-setup">
           <React.Fragment>
                <SlaConfigPage
                    {...props}
                    back={() => {
                        props.history.push({
                            pathname: `/o/${props.match.params.orgID}/setup/inbox-settings`,
                            state: {
                                tab: "idleCase"
                            }
                        })
                    }}
                    backToText="Back to Inbox Settings"
                    medium={"global"}
                />
            </React.Fragment>
        </div>
    )
}

export default SlaConfig;
