import React, { useState, useEffect, useRef } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import "../auth/Auth.css";
import "./MemberStatus.scss";
import { checkID, doToast } from "../../helper/HelperGeneral";
import HelperDate from "../../helper/HelperDate";
import HelperCustomEncryptor from "../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../helper/HelperGetLocalStorage";
import RetrieveDataLoading from "../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { FiRotateCcw, FiChevronDown } from "react-icons/fi";
import iconFilterBlack from "../../assets/img/icon-filter-inbox-black.svg";
import iconFilterGrey from "../../assets/img/icon-filter-inbox-grey.svg";
import CustomSearchBox from "../reuseableComponent/customSearchBox/CustomSearchBox";
import MemberServices from "../../services/newServices/MemberServices";
import ServiceOrganization from "../../services/organization/ServiceOrganization";
import mixpanel from "mixpanel-browser";

const FILTER_CLOCK_STATUS = [
  {
    value: "online_connected",
    label: "Online (Connected)"
  },
  {
    value: "online_not_connected",
    label: "Online (Not Connected)"
  },
  {
    value: "away",
    label: "Away"
  },
  {
    value: "offline",
    label: "Offline"
  }
]

let MemberStatus = props => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);
  let [searchVal, setSearchVal] = useState("");
  let [lastUpdate, setLastUpdate] = useState(new Date());
  let [members, setMembers] = useState([]);
  let [membersOrigin, setMembersOrigin] = useState([]);
  let [waitForResponseGetList, setWaitForResponseGetList] = useState(true);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  })
  let [showStatus, setShowStatus] = useState(false);
  let [clockStateFilter, setClockStateFilter] = useState([]);
  let autoRefreshRef = useRef(null);

  let timerAutoRefresh = () => {
    autoRefreshRef.current = setInterval(() => {
      getMemberList();
    }, 600000)
  }

  let doMount = async () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/organization/members`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    doMount();
  }, [location.pathname]);

  useEffect(() => {
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

    if (orgID) {
      if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
        mixpanel.track(
          "[Page] Member Status",
          {
            userFullName: myAgentData.account.fullName,
            userID: myAgentData.account.id,
            organizationID: orgID.split("-")[0]
          }
        );
      }

      timerAutoRefresh();
    }
  }, [orgID])

  useEffect(() => {
    if (props.history.location.state) {
      if (props.history.location.state.success) {
        doToast(props.history.location.state.inviteMessage);
      } else {
        if (props.history.location.state.success === false) {
          doToast(props.history.location.state.inviteMessage, 'fail');
        }
      }

      window.history.pushState(null, '');
    }
  }, [props.history])

  let onChangeSearch = (e) => {
    setSearchVal(e.target.value);

    let val = e.target.value.toLowerCase();

    if (val === "") {
      setMembers(membersOrigin)
    } else {
      let result = [];
      for (let i in membersOrigin) {
        let objectKey = Object.keys(membersOrigin[i]);

        for (let j in objectKey) {
          let isString = typeof membersOrigin[i][objectKey[j]] === "string";

          if (objectKey[j] === "accountName" || objectKey[j] === "memberAlias" || objectKey[j] === "accountEmail") {
            if (membersOrigin[i][objectKey[j]].toString().toLowerCase().includes(val) && isString) {
              result.push(membersOrigin[i])
              break;
            }
          }
        }
      }

      setMembers(result);
    }
  }

  let onClearSearch = () => {
    let valSearch = document.querySelector(`#search-box-input-val`);
    valSearch.value = "";
    setMembers(membersOrigin);
    setSearchVal("");
  }

  let getMemberList = (clockFilterData = false) => {
    setWaitForResponseGetList(true);
    let _retriveDataError = { ...retriveDataError };
    let _clockFilter = [];

    if (clockFilterData === false) {
      if (clockStateFilter.length > 0) {
        clockStateFilter.map((v) => {
          _clockFilter.push(v.value)
          return null;
        })
      }
    } else {
      clockFilterData.map((v) => {
        _clockFilter.push(v.value)
        return null;
      })
    }

    let data = {
      states: _clockFilter
    };

    MemberServices.getClockStatus(orgID, data, (response) => {
      let dataResult = response.dataResult;

      if (response.dataResult.error.message === "") {
        if (clockFilterData !== false) {
          let _members = response.dataResult.data.members.slice();

          let val = searchVal.toLowerCase();

          if (val === "") {
            setMembers(_members);
          } else {
            let result = [];
            for (let i in _members) {
              let objectKey = Object.keys(_members[i]);

              for (let j in objectKey) {
                let isString = typeof _members[i][objectKey[j]] === "string";

                if (objectKey[j] === "accountName" || objectKey[j] === "memberAlias" || objectKey[j] === "accountEmail") {
                  if (_members[i][objectKey[j]].toString().toLowerCase().includes(val) && isString) {
                    result.push(_members[i])
                    break;
                  }
                }
              }
            }

            setMembers(result);
          }
        } else {
          setMembers(response.dataResult.data.members);
        }

        setMembersOrigin(response.dataResult.data.members);
        setLastUpdate(new Date().valueOf());
      } else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setWaitForResponseGetList(false);
    })
  }

  let toggleDropdownStatus = () => {
    if (!waitForResponseGetList) {
      setShowStatus(!showStatus)
    }
  }

  useEffect(() => {
    if (orgID) getMemberList();
  }, [orgID]);

  let findIndexMemberState = (v) => {
    let idx = -1;

    idx = clockStateFilter.findIndex(_v => _v.value === v);
    return idx;
  }

  let changeClockFilter = (e, v) => {
    if (!waitForResponseGetList) {
      toggleDropdownStatus();
      let _clock = clockStateFilter.slice();

      if (e.target.checked) {
        _clock.push(v);
      } else {
        _clock.splice(findIndexMemberState(v.value), 1);
      }

      getMemberList(_clock);

      setClockStateFilter(_clock);
    }
  }

  return (
    <React.Fragment>
      <div className="sectionWrap">
        <div className="member-list-status-title">
          <b>Member Status</b>

          {(!waitForResponseGetList && !retriveDataError.code) &&
            <>
              <span className="grey-font">
                Last Updated {HelperDate.formatToString(new Date(lastUpdate), "dd MMM yyyy, HH:mm")}
              </span>

              <ButtonWithLoadingOrIcon
                className="no-fill-button main-button-40 button-invite-member"
                text="Refresh"
                icon={{
                  type: "svg",
                  src: FiRotateCcw
                }}
                position="left"
                onClickAction={() => {
                  getMemberList(clockStateFilter);
                  clearInterval(autoRefreshRef.current);
                  timerAutoRefresh();
                }}
                isDisabled={waitForResponseGetList}
              />
            </>
          }
        </div>

        {!retriveDataError.code &&
          <div className="member-list-status-tool-wrapper">
            <CustomSearchBox
              placeholder={"Search for a full name, alias & phone number"}
              onChangeSearchSpecial={onChangeSearch}
              style={{ width: '343px' }}
              dataToSearch={members}
              onClearSearch={onClearSearch}
              search={searchVal}
              isDisabled={waitForResponseGetList}
            />

            <Dropdown isOpen={showStatus} toggle={() => toggleDropdownStatus()} className={`dropdown-member-status ${waitForResponseGetList ? "dropdown-isdisabled" : ""}`} isDisabled={true}>
              <DropdownToggle className="">
                <img src={waitForResponseGetList ? iconFilterGrey : iconFilterBlack} alt="" className="filter-black" />

                {clockStateFilter.length === 0 ?
                  "Filter: Status"
                  :
                  (clockStateFilter.map((v, i) => {
                    return (
                      v.label + `${i === (clockStateFilter.length - 1) ? "" : ", "}`
                    )
                  }))
                }

                <FiChevronDown className={"chevron-up"} />
              </DropdownToggle>
              <DropdownMenu className="custom-checkbox">
                {FILTER_CLOCK_STATUS.map((v, i) => {
                  return (
                    <div key={`filter-clock-${i}`} className={waitForResponseGetList ? "disabled-clock-filter" : ""}>
                      <input type="checkbox" checked={findIndexMemberState(v.value) !== -1} id={`checkbox-connect-${v.value}`} onChange={(e) => changeClockFilter(e, v)} />

                      <label htmlFor={`checkbox-connect-${v.value}`}>
                        {v.label}
                      </label>
                    </div>
                  )
                })}
              </DropdownMenu>
            </Dropdown>
          </div>
        }    

        {waitForResponseGetList || retriveDataError.code ?
          <RetrieveDataLoading
            isLoading={waitForResponseGetList}
            errorMessage={retriveDataError.message}
          />
          :
          <React.Fragment>
            <div className="member-list-status-table-wrapper">
              {members.length === 0 ?
                <div className="no-result-found">
                  <b>No result found.</b>
                </div>
                :
                <div className="custom-table">
                  <table className="custom-table-content table-member-list-status no-hover">
                    <thead>
                      <tr>
                        <td className="col_full_name">
                          Full Name &amp; Alias
                        </td>
                        <td className="col_email">Email</td>
                        <td className="col_role">Status</td>
                      </tr>
                    </thead>

                    <tbody>
                      {members.map((value, index) => {
                        return (
                          <tr key={`member-${index}`}>
                            <td className="col_fullname">
                              {value.accountName}
                              <p className="member-alias-field">
                                {value.memberAlias === "" ? "-" : value.memberAlias}
                              </p>
                            </td>
                            <td className="col_email">{value.accountEmail}</td>
                            <td className={`col_state`}>
                              {value.clockState === "online_connected" &&
                                <>
                                  <div className="status-badge green-background" />
                                  <b className="green-text">Online (Connected)</b>
                                </>
                              }

                              {value.clockState === "online_not_connected" &&
                                <>
                                  <div className="status-badge green-background" />
                                  <b className="green-text">Online <span className="grey-font">(Not Connected)</span></b>
                                </>
                              }

                              {value.clockState === "offline" &&
                                <>
                                  <div className="status-badge red-background" />
                                  <b className="red-text">Offline</b>
                                </>
                              }

                              {value.clockState === "away" &&
                                <>
                                  <div className="status-badge yellow-background" />
                                  <b className="yellow-text">Away</b>
                                </>
                              }
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              }

              {/* <Pagination total={100} 
                              page={1} 
                              listPerPage={8} 
                              step={3} 
                              onChangePage={onChangePage} 
                  /> */}

              {/* {showPopupRemove && */}
            </div>
          </React.Fragment>
        }
      </div>
    </React.Fragment>
  );
};

export default MemberStatus;
