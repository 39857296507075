import React, { useEffect, useState } from "react";
import "./ChatRoomPopupMessageInfo.scss";
import { taptalk } from "@taptalk.io/web-sdk";
import { connect } from "react-redux";
import { FiX, FiInfo } from 'react-icons/fi';
import CheckMarkDoubleBlue from '../../../assets/img/chatroom/icon-double-check-blue.svg';
import CheckMarkDoubleDark from '../../../assets/img/chatroom/icon-double-check-dark.svg';
import iconReplaceAvatar from "../../../assets/img/replace-avatar.svg";
import CheckMarkDark from '../../../assets/img/chatroom/icon-check-dark.svg';
import Helper from '../../../helper/HelperChat';
import { CHAT_TYPE } from '../../../helper/HelperConst';
import HelperDate from "../../../helper/HelperDate";
import { setPopupMessageInfo } from '../../../redux/actions/reduxActionPopupMessageInfo';
import ChatRoomMessageOut from '../chatRoomMain/chatRoomMessageOut/ChatRoomMessageOut';
import ChatRoomMessageOutFile from '../chatRoomMain/chatRoomMessageOutFile/ChatRoomMessageOutFile';
import ChatRoomMessageOutImage from '../chatRoomMain/chatRoomMessageOutImage/ChatRoomMessageOutImage';
import ChatRoomMessageOutVideo from '../chatRoomMain/chatRoomMessageOutVideo/ChatRoomMessageOutVideo';
import ChatRoomMessageOutLocation from '../chatRoomMain/chatRoomMessageOutLocation/ChatRoomMessageOutLocation';
import ChatRoomMessageOutRating from '../chatRoomMain/chatRoomMessageOutRating/ChatRoomMessageOutRating';
import ChatRoomMessageOutInteractive from '../chatRoomMain/chatRoomMessageOutInteractive/ChatRoomMessageOutInteractive';
import ChatRoomMessageOutWabaTemplate from '../chatRoomMain/chatRoomMessageOutWabaTemplate/ChatRoomMessageOutWabaTemplate';
import ChatRoomMessageOutWabaTemplateImage from '../chatRoomMain/chatRoomMessageOutWabaTemplateImage/ChatRoomMessageOutWabaTemplateImage';
import ChatRoomMessageOutWabaTemplateFile from '../chatRoomMain/chatRoomMessageOutWabaTemplateFile/ChatRoomMessageOutWabaTemplateFile';
import ChatRoomMessageOutWabaTemplateVideo from '../chatRoomMain/chatRoomMessageOutWabaTemplateVideo/ChatRoomMessageOutWabaTemplateVideo';
import ChatRoomMessageOutBroadcast from '../chatRoomMain/chatRoomMessageOutBroadcast/ChatRoomMessageOutBroadcast';
import ChatRoomMessageOutBroadcastImage from '../chatRoomMain/chatRoomMessageOutBroadcastImage/ChatRoomMessageOutBroadcastImage';
import ChatRoomMessageOutBroadcastVideo from '../chatRoomMain/chatRoomMessageOutBroadcastVideo/ChatRoomMessageOutBroadcastVideo';
import ChatRoomMessageOutBroadcastFile from '../chatRoomMain/chatRoomMessageOutBroadcastFile/ChatRoomMessageOutBroadcastFile';
import ChatRoomMessageOutImageInteractive from '../chatRoomMain/chatRoomMessageOutImageInteractive/ChatRoomMessageOutImageInteractive';
import ChatRoomMessageOutFileInteractive from '../chatRoomMain/chatRoomMessageOutFileInteractive/ChatRoomMessageOutFileInteractive';
import ChatRoomMessageOutVideoInteractive from '../chatRoomMain/chatRoomMessageOutVideoInteractive/ChatRoomMessageOutVideoInteractive';
import Loading from "../../reuseableComponent/loading/Loading";
import CaseService from "../../../services/chat/caseServices";

let ChatRoomPopupMessageInfo = (props) => {
    let [loadingMessageInfo, setLoadingMessageInfo] = useState(false);
    let [messageInfoData, setMessageInfoData] = useState(false);
    let [isError, setIsError] = useState(false);

    let getMessageInfo = () => {
        setLoadingMessageInfo(true);

        CaseService.postGetMessageStatus(props.match.params.orgID, { id: props.caseDetailData.case.id, localID: props.popupMessageInfo.message.localID }, (res) => {
            console.log(res);
            let dataRes = res.dataResult;

            if (dataRes.error.message !== "") {
                setIsError(true);
            } else {
                setMessageInfoData(dataRes.data);
            }

            setLoadingMessageInfo(false);
        })
    }

    useEffect(() => {
        if (props.popupMessageInfo.isShow) {
            getMessageInfo();
        }
    }, [props.popupMessageInfo])

    let renderBubble = (messageData) => {
        switch (messageData.type) {
            case CHAT_TYPE.TAPChatMessageTypeText:
                return (
                    <ChatRoomMessageOut
                        key={0}
                        singleChatDataProps={messageData}
                        // activeUserID={activeUser}
                        onReplyMessage={() => { }}
                        scrollToReply={() => { }}
                        medium={props.caseDetailData ? props.caseDetailData.case.medium : false}
                        onForwardMessage={() => { }}
                        caseData={props.caseDetailData ? props.caseDetailData.case : false}
                    />

                )
            // waba interactive
            case CHAT_TYPE.TAPChatMessageTypeTextInteractive:
                return (
                    <ChatRoomMessageOutInteractive
                        key={0}
                        singleChatDataProps={messageData}
                        // activeUserID={activeUser}
                        onReplyMessage={() => { }}
                        scrollToReply={() => { }}
                        medium={props.caseDetailData ? props.caseDetailData.case.medium : false}
                        onForwardMessage={() => { }}
                        caseData={props.caseDetailData ? props.caseDetailData.case : false}
                    />
                )
            case CHAT_TYPE.TAPChatMessageTypeImageInteractive:
                return (
                    <ChatRoomMessageOutImageInteractive
                        key={0}
                        singleChatDataProps={messageData}
                        // activeUserID={activeUser}
                        onReplyMessage={() => { }}
                        scrollToReply={() => { }}
                        medium={props.caseDetailData ? props.caseDetailData.case.medium : false}
                        onForwardMessage={() => { }}
                        caseData={props.caseDetailData ? props.caseDetailData.case : false}
                    />
                )
            case CHAT_TYPE.TAPChatMessageTypeVideoInteractive:
                return (
                    <ChatRoomMessageOutVideoInteractive
                        key={0}
                        singleChatDataProps={messageData}
                        // activeUserID={activeUser}
                        onReplyMessage={() => { }}
                        scrollToReply={() => { }}
                        medium={props.caseDetailData ? props.caseDetailData.case.medium : false}
                        onForwardMessage={() => { }}
                        caseData={props.caseDetailData ? props.caseDetailData.case : false}
                    />
                )
            case CHAT_TYPE.TAPChatMessageTypeDocumentInteractive:
                return (
                    <ChatRoomMessageOutFileInteractive
                        key={0}
                        singleChatDataProps={messageData}
                        isOnUpload={messageData.isOnUpload}
                        uploadFile={messageData.uploadFile}
                        // activeUserID={activeUser}
                        onReplyMessage={() => { }}
                        scrollToReply={() => { }}
                        medium={props.caseDetailData ? props.caseDetailData.case.medium : false}
                        onForwardMessage={() => { }}
                        caseData={props.caseDetailData ? props.caseDetailData.case : false}
                    />
                )
            // waba interactive
            case CHAT_TYPE.TAPChatMessageTypeLeaveReview:
                return (
                    <ChatRoomMessageOutRating
                        key={0}
                        singleChatDataProps={messageData}
                        // activeUserID={activeUser}
                        onReplyMessage={() => { }}
                        scrollToReply={() => { }}
                        medium={props.caseDetailData ? props.caseDetailData.case.medium : false}
                        onForwardMessage={() => { }}
                        caseDetailValProps={props.caseDetailValProps}
                        caseData={props.caseDetailData ? props.caseDetailData.case : false}
                    />
                )
            case CHAT_TYPE.TAPChatMessageTypeLeaveReviewDisabled:
                return (
                    <ChatRoomMessageOut
                        key={0}
                        singleChatDataProps={messageData}
                        // activeUserID={activeUser}
                        onReplyMessage={() => { }}
                        scrollToReply={() => { }}
                        medium={props.caseDetailData ? props.caseDetailData.case.medium : false}
                        onForwardMessage={() => { }}
                        caseData={props.caseDetailData ? props.caseDetailData.case : false}
                    />
                )
            case CHAT_TYPE.TAPChatMessageTypeImage:
                return (
                    <ChatRoomMessageOutImage
                        key={0}
                        singleChatDataProps={messageData}
                        // activeUserID={activeUser}
                        onReplyMessage={() => { }}
                        scrollToReply={() => { }}
                        medium={props.caseDetailData ? props.caseDetailData.case.medium : false}
                        onForwardMessage={() => { }}
                        caseData={props.caseDetailData ? props.caseDetailData.case : false}
                    />
                )
            case CHAT_TYPE.TAPChatMessageTypeVideo:
                return (
                    <ChatRoomMessageOutVideo
                        key={0}
                        singleChatDataProps={messageData}
                        // activeUserID={activeUser}
                        onReplyMessage={() => { }}
                        scrollToReply={() => { }}
                        medium={props.caseDetailData ? props.caseDetailData.case.medium : false}
                        onForwardMessage={() => { }}
                        caseData={props.caseDetailData ? props.caseDetailData.case : false}
                    />
                )

            case CHAT_TYPE.TAPChatMessageTypeLocation:
                return (
                    <ChatRoomMessageOutLocation
                        key={0}
                        singleChatDataProps={messageData}
                        isOnUpload={messageData.isOnUpload}
                        uploadFile={messageData.uploadFile}
                        // activeUserID={activeUser}
                        onReplyMessage={() => { }}
                        scrollToReply={() => { }}
                        medium={props.caseDetailData ? props.caseDetailData.case.medium : false}
                        onForwardMessage={() => { }}
                        caseData={props.caseDetailData ? props.caseDetailData.case : false}
                    />
                )
            case CHAT_TYPE.TAPChatMessageTypeFile:
                return (
                    <ChatRoomMessageOutFile
                        key={0}
                        singleChatDataProps={messageData}
                        isOnUpload={messageData.isOnUpload}
                        uploadFile={messageData.uploadFile}
                        // activeUserID={activeUser}
                        onReplyMessage={() => { }}
                        scrollToReply={() => { }}
                        medium={props.caseDetailData ? props.caseDetailData.case.medium : false}
                        onForwardMessage={() => { }}
                        caseData={props.caseDetailData ? props.caseDetailData.case : false}
                    />
                )
            case CHAT_TYPE.MessageTypeWhatsAppBATemplateText:
                return (
                    <ChatRoomMessageOutWabaTemplate
                        key={0}
                        singleChatDataProps={messageData}
                        onReplyMessage={() => { }}
                        scrollToReply={() => { }}
                        medium={props.caseDetailData ? props.caseDetailData.case.medium : false}
                        onForwardMessage={() => { }}
                        caseData={props.caseDetailData ? props.caseDetailData.case : false}
                    />
                )
            case CHAT_TYPE.MessageTypeBroadcastText:
                return (
                    <ChatRoomMessageOutBroadcast
                        key={0}
                        singleChatDataProps={messageData}
                        onReplyMessage={() => { }}
                        scrollToReply={() => { }}
                        medium={props.caseDetailData ? props.caseDetailData.case.medium : false}
                        onForwardMessage={() => { }}
                        caseData={props.caseDetailData ? props.caseDetailData.case : false}
                    />
                )
            case CHAT_TYPE.MessageTypeBroadcastImage:
                return (
                    <ChatRoomMessageOutBroadcastImage
                        key={0}
                        singleChatDataProps={messageData}
                        onReplyMessage={() => { }}
                        scrollToReply={() => { }}
                        medium={props.caseDetailData ? props.caseDetailData.case.medium : false}
                        onForwardMessage={() => { }}
                        caseData={props.caseDetailData ? props.caseDetailData.case : false}
                    />
                )
            case CHAT_TYPE.MessageTypeBroadcastFile:
                return (
                    <ChatRoomMessageOutBroadcastFile
                        key={0}
                        singleChatDataProps={messageData}
                        isOnUpload={messageData.isOnUpload}
                        uploadFile={messageData.uploadFile}
                        // activeUserID={activeUser}
                        onReplyMessage={() => { }}
                        scrollToReply={() => { }}
                        medium={props.caseDetailData ? props.caseDetailData.case.medium : false}
                        onForwardMessage={() => { }}
                        caseData={props.caseDetailData ? props.caseDetailData.case : false}
                    />
                )
            case CHAT_TYPE.MessageTypeBroadcastVideo:
                return (
                    <ChatRoomMessageOutBroadcastVideo
                        key={0}
                        singleChatDataProps={messageData}
                        // activeUserID={activeUser}
                        onReplyMessage={() => { }}
                        scrollToReply={() => { }}
                        medium={props.caseDetailData ? props.caseDetailData.case.medium : false}
                        onForwardMessage={() => { }}
                        caseData={props.caseDetailData ? props.caseDetailData.case : false}
                    />
                )
            case CHAT_TYPE.MessageTypeWhatsAppBATemplateImage:
                return (
                    <ChatRoomMessageOutWabaTemplateImage
                        key={0}
                        singleChatDataProps={messageData}
                        onReplyMessage={() => { }}
                        scrollToReply={() => { }}
                        medium={props.caseDetailData ? props.caseDetailData.case.medium : false}
                        onForwardMessage={() => { }}
                        caseData={props.caseDetailData ? props.caseDetailData.case : false}
                    />
                )
            case CHAT_TYPE.MessageTypeWhatsAppBATemplateFile:
                return (
                    <ChatRoomMessageOutWabaTemplateFile
                        key={0}
                        singleChatDataProps={messageData}
                        onReplyMessage={() => { }}
                        scrollToReply={() => { }}
                        medium={props.caseDetailData ? props.caseDetailData.case.medium : false}
                        onForwardMessage={() => { }}
                        caseData={props.caseDetailData ? props.caseDetailData.case : false}
                    />
                )
            case CHAT_TYPE.MessageTypeWhatsAppBATemplateVideo:
                return (
                    <ChatRoomMessageOutWabaTemplateVideo
                        key={0}
                        singleChatDataProps={messageData}
                        onReplyMessage={() => { }}
                        scrollToReply={() => { }}
                        medium={props.caseDetailData ? props.caseDetailData.case.medium : false}
                        onForwardMessage={() => { }}
                        caseData={props.caseDetailData ? props.caseDetailData.case : false}
                    />
                )

            default:
                return (
                    <ChatRoomMessageOut
                        key={0}
                        singleChatDataProps={messageData}
                        // activeUserID={activeUser}
                        onReplyMessage={() => { }}
                        scrollToReply={() => { }}
                        medium={props.caseDetailData ? props.caseDetailData.case.medium : false}
                        onForwardMessage={() => { }}
                        caseData={props.caseDetailData ? props.caseDetailData.case : false}
                    />
                )
        }
    }

    return (
        <div className={`popup-message-info-wrapper ${props.popupMessageInfo.isShow ? "active-popup-message-info" : ""}`}>
            <div className={`popup-message-info-main-wrapper ${props.popupMessageInfo.isShow ? "active-popup-message-info-main-wrapper" : ""}`}>
                <div
                    className="popup-message-info-close"
                    onClick={() => {
                        let _popup = { ...props.popupMessageInfo };
                        _popup.isShow = false;
                        props.setPopupMessageInfo(_popup);
                    }}
                >
                    <FiX />
                </div>

                <p className={`title-popup-message-info`}>
                    <FiInfo />
                    <b>Message Info</b>
                </p>

                {(props.popupMessageInfo.message !== null) &&
                    <div className="popup-message-info-body-wrapper">
                        <div className="popup-message-info-body-top">
                            <div className="popup-message-info-body-inner">
                                {renderBubble(props.popupMessageInfo.message)}
                            </div>
                        </div>

                        <div className="popup-message-info-body-bottom">
                            <div className="popup-message-info-name">
                                <div
                                    className="popup-message-info-avatar"
                                    style={{ background: taptalk.getRandomColor(props.caseDetailData ? props.caseDetailData.case.userFullName : "") }}
                                >
                                    {props.popupMessageInfo.message.user.imageURL.thumbnail !== "" ?
                                        <img src={props.popupMessageInfo.message.user.imageURL.thumbnail} alt="" onError={(e) => { e.target.onerror = null; e.target.src = iconReplaceAvatar; }} />
                                        :
                                        <b>{Helper.renderUserAvatarWord(props.caseDetailData ? props.caseDetailData.case.userFullName : "")}</b>
                                    }
                                </div>

                                <b>{props.caseDetailData ? props.caseDetailData.case.userFullName : ""}</b>
                            </div>

                            <div className="popup-message-status-wrapper">
                                {loadingMessageInfo ?
                                    <>
                                        <Loading />
                                    </>
                                    :
                                    <>
                                        <div className="popup-message-status-wrapper-list">
                                            <img src={CheckMarkDoubleBlue} alt="" />
                                            Read

                                            {(isError || !messageInfoData.isRead) ?
                                                <p className="status-list-time-empty">

                                                </p>
                                                :
                                                <p className="status-list-time">
                                                    <b>{HelperDate.formatToString(new Date(messageInfoData.readTime), "dd/MM/yyyy")} <div className="dot-time"></div> {HelperDate.formatToString(new Date(messageInfoData.readTime), "HH:mm")}</b>
                                                </p>
                                            }
                                        </div>

                                        <div className="popup-message-status-wrapper-list">
                                            <img src={CheckMarkDoubleDark} alt="" />
                                            Delivered

                                            {(isError || !messageInfoData.isDelivered) ?
                                                <p className="status-list-time-empty">

                                                </p>
                                                :
                                                <p className="status-list-time">
                                                    <b>{HelperDate.formatToString(new Date(messageInfoData.deliveredTime), "dd/MM/yyyy")} <div className="dot-time"></div> {HelperDate.formatToString(new Date(messageInfoData.deliveredTime), "HH:mm")}</b>
                                                </p>
                                            }
                                        </div>

                                        <div className="popup-message-status-wrapper-list">
                                            <img src={CheckMarkDark} alt="" />
                                            Acknowledge

                                            {(isError || !messageInfoData.isAcknowledged) ?
                                                <p className="status-list-time-empty">

                                                </p>
                                                :
                                                <p className="status-list-time">
                                                    <b>{HelperDate.formatToString(new Date(messageInfoData.acknowledgedTime), "dd/MM/yyyy")} <div className="dot-time"></div> {HelperDate.formatToString(new Date(messageInfoData.acknowledgedTime), "HH:mm")}</b>
                                                </p>
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        {/* <Scrollbars
                            autoHideTimeout={500}
                            renderThumbVertical={props => <div {...props} style={style.scrollStyle} />}
                        > */}

                        {/* </Scrollbars> */}
                    </div>
                }
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    popupMessageInfo: state.popupMessageInfo,
    inboxConfigsRedux: state.inboxConfigsRedux,
    caseDetailData: state.caseDetailData
});

const mapDispatchToProps = {
    setPopupMessageInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomPopupMessageInfo);