import React, { useState, useEffect } from 'react';
import './UserSegment.scss'
import SectionWrap from "../reuseableComponent/sectionWrap/SectionWrap";
import { FiPlus, FiInfo, FiEdit, FiTrash2, FiPieChart, FiDownload } from "react-icons/fi";
import SearchBox from "../reuseableComponent/searchBox/SearchBox";
import CustomSelectOption from '../reuseableComponent/customSelectOption/CustomSelectOption'
import Pagination from '../reuseableComponent/pagination/Pagination'
import RetrieveDataLoading from '../reuseableComponent/retrieveDataLoading/RetrieveDataLoading'
import PopupRemove from '../reuseableComponent/popupRemove/PopupRemove'
import ButtonWithLoadingOrIcon from '../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon'
import ErrorOrInfoComp from '../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp'
import { Modal, ModalBody } from 'reactstrap';
import ServiceOrganization from '../../services/organization/ServiceOrganization'
import UserSegmentServices from '../../services/newServices/UserSegmentServices'
import { autoFocusField, checkID, doToast } from '../../helper/HelperGeneral';
import HelperDate from '../../helper/HelperDate';
import { NavLink } from 'react-router-dom';

let UserSegment = (props) => {
  let { match, history } = props;
  let [orgID, setOrgID] = useState(false);
  // let [isSearchFocus, setIsSearchFocus] = useState(false)
  let [query, setQuery] = useState("")
  let [listPageVal, setListPageVal] = useState({
    label: '20',
    value: 20,
  })
  let [totalSegment, setTotalSegment] = useState(0)
  let [pageDisplay, setPageDisplay] = useState(1)
  let [listPage, setListPage] = useState(20)
  let [isSelect, setIsSelect] = useState(false);
  let [countRemoveSegment, setCountRemoveSegment] = useState(0);
  let [dataToBeRemoved, setDataToBeRemoved] = useState([]);
  let [showModalNewSegment, setShowModalNewSegment] = useState(false)
  let [isShowModalRemoveSegment, setIsShowModalRemoveSegment] = useState(false)
  let [isShowModalRemoveSegmentRow, setIsShowModalRemoveSegmentRow] = useState(false)
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });
  let [isLoadingTableSegment, setIsLoadingTableSegment] = useState(false)
  let [submitQuery, setSubmitQuery] = useState("")
  let [dataUserSegment, setDataUserSegment] = useState([])
  let [selectedUserSegmentIDs, setSelectedUserSegmentIDs] = useState([]);
  let [errorMessageData, setErrorMessageData] = useState({
    name: ""
  })

  let doMount = () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/operational`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  useEffect(() => {
    if (showModalNewSegment) {
      setTimeout(() => {
        autoFocusField("segment-name-input");
      }, 500)
    }
  }, [showModalNewSegment])

  useEffect(() => {
    doMount()
  }, [])

  let getSegmentList = (orgID, onload, dataSegment = {}) => {
    let _retriveDataError = { ...retriveDataError };
    setIsLoadingTableSegment(true);

    let data = {}
    if (Object.keys(dataSegment).length === 0) {
      data.pageDisplay = pageDisplay
      data.listPage = listPage
      data.submitQuery = submitQuery
    } else {
      data.pageDisplay = dataSegment.pageDisplay
      data.listPage = dataSegment.listPage
      data.submitQuery = dataSegment.submitQuery
    }

    UserSegmentServices.getSegmentList(orgID, data.pageDisplay, data.listPage, data.submitQuery, (response) => {
      if (response.dataResult.error.code === "") {
        setDataUserSegment(response.dataResult.data.segments)
        setSelectedUserSegmentIDs([]);
        setTotalSegment(response.dataResult.data.totalItems)
        setIsLoadingTableSegment(false);
      } else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
        // doToast(response.dataResult.error.message, 'fail');
        setIsLoadingTableSegment(false);
      }
    });
  }

  useEffect(() => {
    let paramID = checkID(match);
    getSegmentList(paramID, true)
  }, [])

  useEffect(() => {
    setErrorMessageData({
      name: ""
    })
  }, [showModalNewSegment])

  let onChangeSearch = (val) => {
    setQuery(val)
  }

  let onSubmitSearch = () => {
    let data = {
      pageDisplay: 1,
      listPage: listPage,
      submitQuery: query
    }
    let paramID = checkID(match);
    getSegmentList(paramID, false, data)
    setPageDisplay(1)
    setSubmitQuery(query)
  }

  let listPageOption = [
    {
      label: '20',
      value: 20,
    },
    {
      label: '50',
      value: 50,
    },
    {
      label: '100',
      value: 100,
    },
  ]

  let onSelectListPage = (val) => {
    let data = {
      pageDisplay: 1,
      listPage: val.value,
      submitQuery: submitQuery
    }
    let paramID = checkID(match);
    getSegmentList(paramID, false, data);
    setListPageVal(val)
    setListPage(val.value)
    setPageDisplay(1)
  }

  // let onClickDetail = (id) => {
  //   history.push(`/o/${orgID}/operational/user-segment/${id}`)
  // }

  let handleChangePage = (page) => {
    let data = {
      pageDisplay: page,
      listPage: listPage,
      submitQuery: submitQuery
    }
    let paramID = checkID(match);
    getSegmentList(paramID, false, data);
    setPageDisplay(page)
  }

  // let onClickCheckAll = (e) => {
  //   let targetCheckBox = document.querySelectorAll('.custom-table-content tbody input[type="checkbox"]');

  //   targetCheckBox.forEach(item => {
  //     item.checked = e.target.checked ? true : false
  //   })

  //   let _setDataToBeRemoved = [...dataUserSegment];

  //   let _dataToBeRemoved = _setDataToBeRemoved.map(data => data.id);



  //   if (e.target.checked) {
  //     let _dataToSelect = _dataToBeRemoved.filter((el) => {
  //       return !dataToBeRemoved.find(val => val === el)
  //     });
  //     let _dataSelect = [...dataToBeRemoved, ..._dataToSelect]
  //     setDataToBeRemoved(_dataSelect);
  //     setIsSelect(_dataSelect.length < 1 ? false : true);
  //     setCountRemoveSegment(_dataSelect.length);
  //   } else {
  //     let _dataUnSelect = dataToBeRemoved.filter((el) => !_dataToBeRemoved.includes(el));
  //     setDataToBeRemoved([..._dataUnSelect]);
  //     setIsSelect(_dataUnSelect.length < 1 ? false : true);
  //     setCountRemoveSegment(_dataUnSelect.length);
  //   }
  // }

  // let onClickSingleCheck = (e, id) => {
  //   let targetCheckBoxAll = document.querySelectorAll('#select-all');
  //   targetCheckBoxAll[0].checked = false;

  //   targetCheckBoxAll.checked = false;

  //   let _dataToBeRemoved = dataToBeRemoved;

  //   if (e.target.checked) {
  //     _dataToBeRemoved.push(id);
  //   } else {
  //     _dataToBeRemoved.splice(_dataToBeRemoved.indexOf(id), 1);
  //   }

  //   setIsSelect(_dataToBeRemoved.length < 1 ? false : true);
  //   setCountRemoveSegment(_dataToBeRemoved.length);
  //   setDataToBeRemoved(_dataToBeRemoved);
  // }

  // let onCheckedInputAll = () => {
  //   let isChecked = true;
  //   if (!isLoadingTableSegment) {
  //     let _data = [...dataToBeRemoved]
  //     let _dataSegment = [...dataUserSegment]

  //     _dataSegment.forEach(item => {
  //       if (_data.findIndex(val => val === item.id) === -1) {
  //         isChecked = false
  //       }
  //     })

  //     return isChecked
  //   }
  // }

  // let onChecked = (id) => {
  //   let _data = [...dataToBeRemoved]
  //   return _data.findIndex(val => val === id) !== -1;
  // }

  let closeModalSegment = () => {
    if (!isLoadingCreateSegment) {
      setSegmentName("")
      setShowModalNewSegment(false)
    }
  }

  let [segmentName, setSegmentName] = useState("")

  let onChangeSegmentName = (e) => {
    setErrorMessageData({
      name: ""
    })
    setSegmentName(e.target.value)
  }

  let [isLoadingCreateSegment, setIsLoadingCreateSegment] = useState(false)

  let createSegment = () => {
    let _errorMessageData = { ...errorMessageData };
    let errorCount = 0;

    if (segmentName.length === 0) {
      _errorMessageData.name = "This field is required";
      errorCount = errorCount + 1;
    }

    if (segmentName.length > 50) {
      _errorMessageData.name = "Characters exceed limit";
      errorCount = errorCount + 1;
    }

    if (errorCount === 0) {
      setIsLoadingCreateSegment(true)
      UserSegmentServices.createSegment(orgID, segmentName, (response) => {
        setIsLoadingCreateSegment(false)
        if (response.dataResult.status === 200) {
          if (!response.dataResult.data.success) {
            doToast(response.dataResult.data.message);
          } else {
            history.push(
              `/o/${orgID}/operational/user-segment/${response.dataResult.data.segment.id}`
            );
            doToast(response.dataResult.data.message);
          }
        } else {
          doToast(response.dataResult.error.message, 'fail');
        }
      });
    } else {
      setErrorMessageData(_errorMessageData);
    }
  }

  let renderModalNewSegment = () => {
    return (
      <Modal isOpen={showModalNewSegment} toggle={closeModalSegment} className="new-segment-modal">
        <ModalBody>
          <div className="new-segment-wrapper">
            <div className="segment-title-wrapper">
              <FiPieChart />
              <b className="new-segment-title">New Segment</b>
            </div>
            <label className='pos-relative'>
              <b>Segment Name</b>
              <span className={errorMessageData.name === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{segmentName.length}/50</span>
            </label>
            <input className={errorMessageData.name !== "" ? "border-red segment-name segment-name-input" : "segment-name segment-name-input"} disabled={isLoadingCreateSegment} type="text" placeholder="Type segment name here" value={segmentName} onChange={onChangeSegmentName} />

            {errorMessageData.name !== "" &&
              <ErrorOrInfoComp
                icon={<FiInfo />}
                _className="red-text"
                text={errorMessageData.name}
              />
            }

            <div className="new-segment-button-container">
              <ButtonWithLoadingOrIcon
                className="no-fill-button main-button-48"
                text="Cancel"
                onClickAction={closeModalSegment}
              />
              {isLoadingCreateSegment ?
                <ButtonWithLoadingOrIcon
                  isLoading
                  loadingColor="gray"
                  isDisabled
                  className="orange-button main-button-48"
                  text="Create Segment"
                  position="left"
                />
                :
                <ButtonWithLoadingOrIcon
                  className="orange-button main-button-48"
                  text="Create Segment"
                  onClickAction={createSegment}
                />
              }
            </div>
          </div>
        </ModalBody>
      </Modal>
    )
  }

  let onCancelRemove = () => {
    setIsSelect(false);
    setDataToBeRemoved([]);
    setCountRemoveSegment(0);

    let allCheckBox = document.querySelectorAll('.custom-table-content input[type="checkbox"]');

    for (let i = 0; i < allCheckBox.length; i++) {
      allCheckBox[i].checked = false;
    }
  }

  let toggleModalRemoveSegment = () => {
    setIsShowModalRemoveSegment(false)
  }

  let removeSegment = () => {
    deleteSegment(dataToBeRemoved)
  }

  let toggleModalRemoveSegmentRow = () => {
    setDataRowToBeRemoved([])
    setIsShowModalRemoveSegmentRow(false)
  }

  let [dataRowToBeRemoved, setDataRowToBeRemoved] = useState([])

  // let onClickDelete = (item) => {
  //   let _dataRowToBeRemoved = [item.id]
  //   setDataRowToBeRemoved(_dataRowToBeRemoved)
  //   setIsShowModalRemoveSegmentRow(true)
  // }

  let [waitForResponseRemoveSegment, setWaitForResponseRemoveSegment] = useState(false)

  let deleteSegment = (segmentsToBeRemoved) => {
    let paramID = checkID(match);
    setWaitForResponseRemoveSegment(true)
    UserSegmentServices.deleteSegment(orgID, segmentsToBeRemoved, (response) => {
      setWaitForResponseRemoveSegment(false)
      if (response.dataResult.status === 200) {
        if (!response.dataResult.data.success) {
          doToast(response.dataResult.data.message);
        } else {
          toggleModalRemoveSegmentRow()
          toggleModalRemoveSegment()
          setIsSelect(false);
          setDataToBeRemoved([]);
          setCountRemoveSegment(0);
          getSegmentList(paramID, false)
          doToast(response.dataResult.data.message);
        }
      } else {
        doToast(response.dataResult.error.message, 'fail');
      }
    })
  }

  let deleteSelectedSegments = () => {
    let paramID = checkID(match);
    setWaitForResponseRemoveSegment(true);
    UserSegmentServices.deleteSegment(orgID, selectedUserSegmentIDs, (response) => {
      setWaitForResponseRemoveSegment(false)
      if (response.dataResult.status === 200) {
        if (!response.dataResult.data.success) {
          doToast(response.dataResult.data.message);
        }
        else {
          toggleModalRemoveSegment();
          setSelectedUserSegmentIDs([]);
          getSegmentList(paramID, false);
          doToast(response.dataResult.data.message);
        }
      }
      else {
        doToast(response.dataResult.error.message, 'fail');
      }
    })
  }

  let removeSegmentRow = () => {
    deleteSegment(dataRowToBeRemoved)
  }

  let downloadReport = (data) => {
    // let id = orgID.split('-');
    // let timestamp = + new Date();
    let url = window.URL.createObjectURL(new Blob([data]));
    let link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `contacts-data-${HelperDate.formatToString(new Date(), "dd-MM-yyyy")}.csv`);
    document.body.appendChild(link);
    link.click();
  }

  let downloadContacts = (id) => {
    let data = {
      id: parseInt(id),
      format: "csv"
    };

    UserSegmentServices.exportContacts(orgID, data,
      (response) => {
        if (!response) {
          doToast("Download Failed", "fail")
        } else {
          downloadReport(response.dataResult)
        }
      }
    )
  }

  const onCheckAll = (e) => {
    let _selectedUserSegmentIDs = selectedUserSegmentIDs.slice();
    const _dataUserSegment = dataUserSegment.slice();
    if (_selectedUserSegmentIDs.length > 0) {
      _selectedUserSegmentIDs = [];
    }
    else {
      _dataUserSegment.map(item => {
        _selectedUserSegmentIDs.push(item.id);
      });
    }
    setSelectedUserSegmentIDs(_selectedUserSegmentIDs);
    let targetCheckBox = document.querySelectorAll('.custom-table-content tbody input[type="checkbox"]');
    for (let i = 0; i < targetCheckBox.length; i++) {
      targetCheckBox[i].checked = _dataUserSegment.length > i && _selectedUserSegmentIDs.includes(_dataUserSegment[i].id);
    }
  }

  const onSegmentSelected = (e, segment) => {
    const _selectedUserSegmentIDs = selectedUserSegmentIDs.slice();
    const index = _selectedUserSegmentIDs.indexOf(segment.id);
    if (index >= 0 && index < _selectedUserSegmentIDs.length) {
      _selectedUserSegmentIDs.splice(index, 1);
    }
    else {
      _selectedUserSegmentIDs.push(segment.id);
    }
    setSelectedUserSegmentIDs(_selectedUserSegmentIDs);
  }

  const isAllFilteredItemsChecked = () => {
    const _dataUserSegment = dataUserSegment.slice();
    const _selectedUserSegmentIDs = selectedUserSegmentIDs.slice();
    let count = 0;
    _dataUserSegment.map(item => {
      if (_selectedUserSegmentIDs.includes(item.id)) {
        count++;
      }
    });
    return count >= _dataUserSegment.length;
  }

  return (
    <SectionWrap
      {...props}
      orgID={orgID}
      withHeader
      withSideMenu
    >
      <div className="sectionWrap user-segment-main-wrapper">
        {renderModalNewSegment()}

        {/* tier blocking */}
        {retriveDataError.code === "49901" &&
          <RetrieveDataLoading
            isLoading={isLoadingTableSegment}
            errorMessage={retriveDataError.message}
            errorCode={retriveDataError.code}
          />
        }
        {/* tier blocking */}

        <>
          <div className="topContent user-segment-header">
            <div className="title">Contact Segments</div>

            {!isLoadingTableSegment &&
              <>
                {//!isSelect ?
                  selectedUserSegmentIDs.length == 0 ?
                    (!retriveDataError.code &&
                      <ButtonWithLoadingOrIcon
                        className="orange-button main-button-40"
                        text="New Segment"
                        position="left"
                        onClickAction={() => setShowModalNewSegment(true)}
                        icon={{
                          type: "svg",
                          src: FiPlus
                        }}
                      />
                    )
                    :
                    <div className="remove-segment-wrapper">
                      {/* <ButtonWithLoadingOrIcon
                        className="no-fill-button main-button-40 cancel-delete-segment-btn"
                        text="Cancel"
                        onClickAction={() => onCancelRemove()}
                      /> */}
                      <ButtonWithLoadingOrIcon
                        className="red-button main-button-40"
                        text={`Delete ${selectedUserSegmentIDs.length} Segment${selectedUserSegmentIDs.length > 1 ? 's' : ''}`}
                        onClickAction={() => setIsShowModalRemoveSegment(true)}
                        icon={{
                          type: "svg",
                          src: FiTrash2
                        }}
                        position="left"
                      />
                    </div>
                }
              </>
            }
          </div>

          {/* <form
            className={`user-segment-search ${isSearchFocus ? 'focus-search' : ''}`}
            onFocus={() => setIsSearchFocus(true)}
            onBlur={() => setIsSearchFocus(false)}
            onSubmit={(onSubmitSearch)}
          >
            <input type="text" placeholder="Search for segment" value={query} onChange={onChangeSearch} />
            <span onClick={onSubmitSearch}>
              <FiSearch />
            </span>
          </form> */}

          {
            (isLoadingTableSegment || retriveDataError.code) ?
              <RetrieveDataLoading
                isLoading={isLoadingTableSegment}
                errorMessage={retriveDataError.message}
              />
              :
              <React.Fragment>
                <SearchBox
                  onSubmitSearch={onSubmitSearch}
                  onChangeInputSearch={onChangeSearch}
                  searchValue={query}
                  placeholder="Search for a segment"
                  isWaitResponse={isLoadingTableSegment}
                  onClearSearch={() => {
                    setSubmitQuery("");
                    let data = {
                      pageDisplay: 1,
                      listPage: listPage,
                      submitQuery: ""
                    }
                    let paramID = checkID(match);
                    getSegmentList(paramID, false, data)
                  }}
                />

                <div className="user-segment-list-table-wrapper">
                  <div className="custom-table custom-table-show-overflow">
                    <table className="custom-table-content table-user-segment-list">
                      <thead>
                        <tr className="tr-user-segment-list-head">
                          {/* <td
                            className={`col_checkbox_all ${selectedUserSegmentIDs.length > 0 ? 'checked' : 'unchecked'}`}
                            onClick={(e) => {onCheckAll(e)}} 
                          >
                            {
                              isAllFilteredItemsChecked()
                              ?
                              <img src={iconCheck} />
                              :
                              <img src={iconMinus} />
                            }
                          </td> */}
                          <td className="col_segment_name">
                            <div className={isAllFilteredItemsChecked() ? "custom-checkbox" : "custom-checkbox-stripe"}>
                              <input
                                type="checkbox"
                                id="select-all"
                                onChange={(e) => onCheckAll(e)}
                                checked={selectedUserSegmentIDs.length > 0}
                              />
                              <label htmlFor="select-all"></label>
                            </div>
                            Segment Name
                          </td>
                          <td className="col_number_contact">Number of Contacts</td>
                          <td className="col_select_list">
                            <CustomSelectOption
                              optionListProps={listPageOption}
                              valueDropdownProps={listPageVal}
                              onClickDropDownListOptionProps={onSelectListPage}
                            />
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          dataUserSegment.length > 0 ?
                            dataUserSegment.map(item => {
                              return (
                                <tr
                                  className="tr-user-segment-list-body"
                                  key={`user-contact-${item.id}`}
                                >
                                  {/* <td
                                    className={`col_segment_checkbox ${selectedUserSegmentIDs.includes(item.id) ? 'checked' : 'unchecked'}`}
                                    onClick={(e) => {onSegmentSelected(e, item)}} 
                                  >
                                    <img src={iconCheck} />
                                  </td> */}
                                  <td className="col_segment_name">
                                    <div className="custom-checkbox">
                                      <input type="checkbox"
                                        id={`select-segment-${item.name}`}
                                        onChange={(e) => onSegmentSelected(e, item)}
                                      />
                                      <label htmlFor={`select-segment-${item.name}`}></label>
                                    </div>
                                    <p>{item.name}</p>
                                  </td>
                                  <td className="col_segment_count">
                                    {item.totalUsers}
                                  </td>
                                  <td className="col-action">
                                    <button className="no-fill-button detail-btn" onClick={() => downloadContacts(item.id)}>
                                      <FiDownload />
                                      Download Contacts
                                    </button>

                                    <NavLink to={`/o/${orgID}/operational/user-segment/${item.id}`}>
                                      <button className="no-fill-button detail-btn">
                                        <FiEdit />
                                        Details
                                      </button>
                                    </NavLink>

                                    {/* <button className="no-fill-button delete-btn" onClick={() => onClickDelete(item)}>
                                      <FiTrash2 />
                                      Delete
                                    </button> */}
                                  </td>
                                </tr>
                              )
                            })
                            :
                            <tr className="tr-user-segment-list-empty">
                              <td colSpan="6" className="no-data-column"><b className="no-result-found">{submitQuery ? `No results found with “${submitQuery}”. Please try another segment name.` : `No Contact Segments found. Start by clicking "+ New Segment".`}</b></td>
                            </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>

                {
                  dataUserSegment.length > 0 &&
                  <div className="pagination-wrapper">
                    <Pagination
                      total={totalSegment}
                      page={pageDisplay}
                      listPerPage={listPage}
                      step={1}
                      onChangePage={handleChangePage}
                    ></Pagination>
                  </div>
                }
              </React.Fragment>
          }

          <PopupRemove
            removeTitle={`Delete ${selectedUserSegmentIDs.length} Segment${selectedUserSegmentIDs.length > 1 ? 's' : ''}?`}
            removeDescription={`Are you sure you want to delete ${selectedUserSegmentIDs.length} Segment${selectedUserSegmentIDs.length > 1 ? 's' : ''}?`}
            onClickCancel={toggleModalRemoveSegment}
            onClickRemove={deleteSelectedSegments}
            waitForResponse={waitForResponseRemoveSegment}
            showModalProps={isShowModalRemoveSegment}
            submitText={`Delete Segment${selectedUserSegmentIDs.length > 1 ? 's' : ''}`}
            colorButton="red-button"
          />
          {/* <PopupRemove
            removeTitle={`Remove this Segment`}
            removeDescription={`Are you sure you want to remove this Segment?`}
            onClickCancel={toggleModalRemoveSegmentRow}
            onClickRemove={removeSegmentRow}
            waitForResponse={waitForResponseRemoveSegment}
            showModalProps={isShowModalRemoveSegmentRow}
            submitText={`Remove Segment`}
            colorButton="red-button"
          /> */}
        </>

      </div>
    </SectionWrap >
  );
};

export default UserSegment;