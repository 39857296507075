import React, { useState, useEffect } from "react";
import "./IntegrationChatGPTSetupLeadGeneration.scss";
import { FiInfo, FiTrash2, FiPlus, FiAlertCircle } from "react-icons/fi";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import CustomSelectOption from "../../../reuseableComponent/customSelectOption/CustomSelectOption";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import NextButton from "../../../reuseableComponent/NextButton/NextButton";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import IconDragDrop from '../../../../assets/img/icon-menu-dragdrop.svg';

import { doToast, scrollToClass } from "../../../../helper/HelperGeneral";
import { connect } from 'react-redux';
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import PopupDiscard from "../../../reuseableComponent/popupDiscard/PopupDiscard";

const IntegrationChatGPTSetupLeadGeneration = (props) => {

    const VARIABLE_LIST = [
        {
            label: "Name",
            value: "name"
        },
        {
            label: "Company",
            value: "company"
        },
        {
            label: "Job Role",
            value: "job_role"
        },
        {
            label: "User's Needs",
            value: "user_need"
        },
        {
            label: "Email",
            value: "email"
        },
        {
            label: "Phone Number",
            value: "phone_number"
        },
        {
            label: "Date",
            value: "date"
        }
    ];

    let [leadsGenerationOrigin, setLeadsGenerationOrigin] = useState("");
    let [isLoadingSavedData, setIsLoadingSavedData] = useState(false);
    let [errorMessageData, setErrorMessageData] = useState({
        variable: [
            {
                variable: "",
                description: "",
                question: ""
            }
        ]
    });
    let [draggingItem, setDraggingItem] = useState(null);
    let [isShowModalDiscard, setIsShowModalDiscard] = useState(false);

    useEffect(() => {
        fetchSavedLeadsGeneration();
    }, [props.match.params.orgID]);

    useEffect(() => {
        for (let i = 0; i < props.leadsGenerationSettings.variable.length; i++) {
            resizeQuestionTextArea(i);
        }
        let _errorMessageData = {...errorMessageData};
        while (_errorMessageData.variable.length < props.leadsGenerationSettings.variable.length) {   
            _errorMessageData.variable.push({
                variable: "",
                description: "",
                question: ""
            });
        }
        setErrorMessageData(_errorMessageData);
    }, [props.leadsGenerationSettings]);

    const fetchSavedLeadsGeneration = () => {
        setIsLoadingSavedData(true);

        // TODO: GET SAVED SETTINGS API
        setTimeout(() => {
            setIsLoadingSavedData(false);
            if (!leadsGenerationOrigin) {
                setLeadsGenerationOrigin(JSON.stringify(props.leadsGenerationSettings));
            }
        }, 1000);
    }

    const toggleModalDiscard = () => {
        setIsShowModalDiscard(!isShowModalDiscard);
    }

    const onChangeText = (e, i, id) => {
        let _data = { ...props.leadsGenerationSettings };
        _data.variable[i][id] = e.target.value;
        props.setLeadsGenerationSettings(_data);

        let _errorMessageData = { ...errorMessageData };
        _errorMessageData.variable[i][id] = "";
        setErrorMessageData(_errorMessageData);
    }

    const onSelectTopic = (e, i) => {
        let _data = { ...props.leadsGenerationSettings };
        _data.variable[i].variable = e;
        props.setLeadsGenerationSettings(_data);

        let _errorMessageData = { ...errorMessageData };
        _errorMessageData.variable[i].variable = "";
        setErrorMessageData(_errorMessageData);
    }

    const resizeQuestionTextArea = (index) => {
        const textarea = document.getElementById(`question-${index}`);
        if (!textarea) {
            return;
        }
        textarea.style.height = "1px"; // Reset height
        if (textarea.scrollHeight <= 48) {
            textarea.style.height = "48px";
        }
        else {
            textarea.style.height = "" + textarea.scrollHeight + "px";
        }
    }

    const validateContinue = () => {
        let _errorMessageData = { ...errorMessageData };
        let errorClass = false;

        if (props.leadsGenerationSettings.isEnable) {
            props.leadsGenerationSettings.variable.map((value, index) => {
                if (!value.variable.value) {
                    if (_errorMessageData && errorMessageData.variable && errorMessageData.variable.length > index) {
                        _errorMessageData.variable[index].variable = "This field is required"
                    }
                    if (!errorClass) {
                        errorClass = `variable-wrapper-${index}`;
                    }
                }
                if (!value.description) {
                    if (_errorMessageData && errorMessageData.variable && errorMessageData.variable.length > index) {
                        _errorMessageData.variable[index].description = "This field is required"
                    }
                    if (!errorClass) {
                        errorClass = `variable-wrapper-${index}`;
                    }
                }
                if (!value.question) {
                    if (_errorMessageData && errorMessageData.variable && errorMessageData.variable.length > index) {
                        _errorMessageData.variable[index].question = "This field is required"
                    }
                    if (!errorClass) {
                        errorClass = `variable-wrapper-${index}`;
                    }
                }
            });
        }

        setErrorMessageData(_errorMessageData);

        if (errorClass) {
            scrollToClass(`.${errorClass}`);
        }
        else {
            props.setActiveTab(3);
        }
    }

    const toggleConfig = () => {
        let _data = { ...props.leadsGenerationSettings };
        _data.isEnable = !_data.isEnable;
        props.setLeadsGenerationSettings(_data);

        if (!_data.isEnable) {
            let _errorMessageData = { ...errorMessageData };
            _errorMessageData.variable.map((value) => {
                value.variable = "";
                value.description = "";
                value.question = "";
            });
            setErrorMessageData(_errorMessageData);
        }
    }

    const addAnotherVariable = () => {
        let _data = { ...props.leadsGenerationSettings };
        _data.variable.push({
            variable: {
                label: "",
                value: ""
            },
            description: "",
            question: ""
        });
        props.setLeadsGenerationSettings(_data);

        let _errorMessageData = { ...errorMessageData };
        _errorMessageData.variable.push({
            variable: "",
            description: "",
            question: ""
        });
        setErrorMessageData(_errorMessageData);
    }

    const onDeleteVariable = (i) => {
        let _data = { ...props.leadsGenerationSettings };
        _data.variable.splice(i, 1);
        props.setLeadsGenerationSettings(_data);

        let _errorMessageData = { ...errorMessageData };
        _errorMessageData.variable.splice(i, 1);
        setErrorMessageData(_errorMessageData);
    }

    const filterVariableSelection = (i) => {
        let printVar = [];

        VARIABLE_LIST.map((val) => {
            let findIndexVar = props.leadsGenerationSettings.variable.findIndex(_v => _v.variable.value === val.value);

            if (findIndexVar === -1 || findIndexVar === i) {
                printVar.push(val);
            }
        })

        return printVar;
    }

    const onVariableDragStart = (e, item) => {
        setDraggingItem(item);
        e.dataTransfer.setData('text/plain', ''); 
    }
  
    const onVariableDragEnd = (e) => {
        setDraggingItem(null);
    }
  
    const onVariableDragOver = (e) => {
        e.preventDefault(); 
    }
  
    const onVariableDrop = (e, item) => {
        if (!draggingItem) {
            return; 
        }
    
        let _data = {...props.leadsGenerationSettings};
        const currentIndex = _data.variable.indexOf(draggingItem);
        const targetIndex = _data.variable.indexOf(item);
    
        if (currentIndex !== -1 && targetIndex !== -1) {
            _data.variable.splice(currentIndex, 1);
            _data.variable.splice(targetIndex, 0, draggingItem);
            props.setLeadsGenerationSettings(_data);

            let _errorMessageData = {...errorMessageData};
            const draggedErrorData = _errorMessageData.variable[currentIndex];
            _errorMessageData.variable.splice(currentIndex, 1);
            _errorMessageData.variable.splice(targetIndex, 0, draggedErrorData);
            setErrorMessageData(_errorMessageData);
        }
    }

    const printVariable = (v, i) => {
        return (
            <div 
                key={`variable-wrap-${i}`} 
                className={`variable-wrapper variable-wrapper-${i} form-content-wrapper`}
                draggable={props.leadsGenerationSettings.variable.length > 1 && props.leadsGenerationSettings.isEnable}
                onDragStart={(e) => onVariableDragStart(e, v)}
                onDragEnd={(e) => onVariableDragEnd(e)}
                onDragOver={(e) => onVariableDragOver(e)}
                onDrop={(e) => onVariableDrop(e, v)}
            >
                <label>
                    <b>Variable</b>
                </label>

                <CustomSelectOption
                    optionListProps={filterVariableSelection(i)}
                    valueDropdownProps={v.variable}
                    placeholderProps={'Select variable'}
                    onClickDropDownListOptionProps={(e) => onSelectTopic(e, i)}
                    specificClassNameProps="custom-select-variable-topic"
                    dropdownError={""}
                    _className={(errorMessageData.variable && errorMessageData.variable.length > i && errorMessageData.variable[i] && errorMessageData.variable[i].variable) ? "border-red" : ""}
                    isDisabled={!props.leadsGenerationSettings.isEnable}
                />
                {
                    (errorMessageData.variable && errorMessageData.variable.length > i && errorMessageData.variable[i] && errorMessageData.variable[i].variable) &&
                    <ErrorOrInfoComp
                        text={errorMessageData.variable[i].variable}
                        _className="red-text"
                        icon={<FiAlertCircle />}
                    />
                }

                <label>
                    <b>Description</b>

                    {/* <span className={`${i !== 0 ? "right-18" : ""}`}>{v.text.length}/25 character</span> */}
                </label>
                <input
                    type="text"
                    placeholder="Insert description here"
                    className={` ${(errorMessageData.variable && errorMessageData.variable.length > i && errorMessageData.variable[i] && errorMessageData.variable[i].description) ? "border-red" : ""}`}
                    value={v.description}
                    onChange={(e) => onChangeText(e, i, "description")}
                    disabled={!props.leadsGenerationSettings.isEnable}
                />
                {
                    (errorMessageData.variable && errorMessageData.variable.length > i && errorMessageData.variable[i] && errorMessageData.variable[i].description) ?
                    <ErrorOrInfoComp
                        text={errorMessageData.variable[i].description}
                        _className="red-text"
                        icon={<FiAlertCircle />}
                    />
                    :
                    <ErrorOrInfoComp
                        text="Description helps explain this variable's use."
                        icon={<FiInfo />}
                    />
                }

                <label>
                    <b>Question</b>

                    {/* <span className={`${i !== 0 ? "right-18" : ""}`}>{v.text.length}/25 character</span> */}
                </label>
                <textarea
                    placeholder="Insert question here"
                    id={`question-${i}`}
                    className={` ${(errorMessageData.variable && errorMessageData.variable.length > i && errorMessageData.variable[i] && errorMessageData.variable[i].question) ? "border-red" : ""}`}
                    value={v.question}
                    onChange={(e) => onChangeText(e, i, "question")}
                    disabled={!props.leadsGenerationSettings.isEnable}
                />
                {
                    (errorMessageData.variable && errorMessageData.variable.length > i && errorMessageData.variable[i] && errorMessageData.variable[i].question) ?
                    <ErrorOrInfoComp
                        text={errorMessageData.variable[i].question}
                        _className="red-text"
                        icon={<FiAlertCircle />}
                    />
                    :
                    <ErrorOrInfoComp
                        text="Questions will be sent to collect data from potential customers."
                        icon={<FiInfo />}
                    />
                }

                {
                    (props.leadsGenerationSettings.isEnable && props.leadsGenerationSettings.variable.length > 1) &&
                    <>
                        <FiTrash2
                            className='button-delete'
                            onClick={() => onDeleteVariable(i)}
                        />
                        <img
                          src={IconDragDrop}
                          className="drag-indicator"
                          alt=""
                          draggable={false}
                        />
                    </>
                }
            </div>
        )
    }

    return (
        <>
            <SectionWrap
                {...props}
                orgID={props.match.params.orgID}
                withHeader
                withSideMenu
                noWrap
                dataOrigin={leadsGenerationOrigin}
                dataAfterChange={JSON.stringify(props.leadsGenerationSettings)}
            >
                <div className="nav-button-wrapper">
                    <BackButton
                        onClick={() => {
                            if (leadsGenerationOrigin !== JSON.stringify(props.leadsGenerationSettings)) {
                                setIsShowModalDiscard(true);
                            }
                            else {
                                props.setActiveTab(1);
                            }
                        }}
                        text={"Back to Prompt Settings"}
                    />

                    <NextButton
                        onClick={validateContinue}
                        text={"Continue to Handover Settings"}
                    />
                </div>

                <div className="chatgpt-lead-page-wrapper sectionWrap">
                    <p className="top-title">
                        <b>Leads Generation</b>

                        <span className="top-subtitle">
                            Turning this on will allow chatbot to send Leads Generation question created below to gather data of potential clients.
                        </span>
                        
                        {
                            !isLoadingSavedData &&
                            <div className="gpt-lead-switch-wrapper">
                                <label className="custom-switch-wrapper">
                                    <input type="checkbox" checked={props.leadsGenerationSettings.isEnable} onChange={() => toggleConfig()} />
                                    <span className="custom-switch round"></span>
                                </label>
                            </div>
                        }
                    </p>     

                    {
                        isLoadingSavedData ?
                        <RetrieveDataLoading
                            isLoading={isLoadingSavedData}
                            errorMessage=""
                            errorCode=""
                        />
                        :
                        <>
                            {props.leadsGenerationSettings.variable.map((v, i) => {
                                return (
                                    printVariable(v, i)
                                )
                            })}
        
                            {props.leadsGenerationSettings.variable.length < VARIABLE_LIST.length &&
                                <ButtonWithLoadingOrIcon
                                    text="Add another variable"
                                    onClickAction={() => addAnotherVariable()}
                                    className="main-button-26 no-fill-button add-another-variable"
                                    icon={{
                                        type: "svg",
                                        src: FiPlus
                                    }}
                                    position="left"
                                />
                            }
                        </>
                    }
                </div>
            </SectionWrap>

            <PopupDiscard
                dataOrigin={leadsGenerationOrigin}
                dataAfterChange={JSON.stringify(props.leadsGenerationSettings)}
                isOpenDiscard={isShowModalDiscard}
                toggleDiscard={toggleModalDiscard}
                discardProgress={() => {
                    setIsShowModalDiscard(false);
                    props.setActiveTab(1);
                }}
                discardDesc={props.discardDesc}
            />
        </>
    )
}

const mapStateToProps = state => ({
    allowedModule: state.allowedModule
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationChatGPTSetupLeadGeneration)
