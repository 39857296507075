const INITIAL_STATE = {
    isShow: false,
    message: null
};

let reduxReducerPopupMessageInfo = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_POPUP_MESSAGE_INFO':
        return action.popupMessageInfo;
      case 'CLEAR_POPUP_MESSAGE_INFO':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerPopupMessageInfo;