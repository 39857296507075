import React, { useState, useEffect } from "react";
import "./IntegrationChatGPTSetup.scss";
import { FiCheck } from "react-icons/fi";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import BackButton from "../../reuseableComponent/BackButton/BackButton";
import IntegrationChatGPTSetupKnowledgeBase from "./IntegrationChatGPTSetupKnowledgeBase/IntegrationChatGPTSetupKnowledgeBase";
import IntegrationChatGPTSetupPromptSetting from "./IntegrationChatGPTSetupPromptSetting/IntegrationChatGPTSetupPromptSetting";
import IntegrationChatGPTSetupLeadGeneration from "./IntegrationChatGPTSetupLeadGeneration/IntegrationChatGPTSetupLeadGeneration";
import IntegrationChatGPTSetupHandoverSettings from "./IntegrationChatGPTSetupHandoverSetting/IntegrationChatGPTSetupHandoverSettings";
import IntegrationChatGPTSetupSubmission from "./IntegrationChatGPTSetupSubmission/IntegrationChatGPTSetupSubmission";
import { checkID, doToast } from "../../../helper/HelperGeneral";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import { connect } from 'react-redux';
import topicServices from "../../../services/newServices/TopicServices";
import ChatbotServices from "../../../services/newServices/ChatbotService";
import PopupLoading from "../../reuseableComponent/popupLoading/PopUpLoading";

const IntegrationChatGPTSetup = (props) => {
    let { match, history, location } = props;
    let [orgID, setOrgID] = useState(false);
    let [activeTab, setActiveTab] = useState(0);
    let [topicList, setTopicList] = useState([]);
    let [isLoadingSave, setIsLoadingSave] = useState(false);

    let [knowledgeBase, setKnowledgeBase] = useState({
        pendingChanges: [],
        trainedSources: []
    });

    let [promptSettings, setPromptSettings] = useState({
        model: {
            id: "",
            code: "",
            value: "",
            name: "",
            label: "",
        },
        tone: {
            id: "",
            code: "",
            value: "",
            name: "",
            label: "",
        },
        language: {
            id: "",
            code: "",
            value: "",
            name: "",
            label: "",
        },
        supportedLanguages: [],
        greetings: [""],
        systemPrompt: "",
    });
    
    let [leadsGenerationSettings, setLeadsGenerationSettings] = useState({
        isEnable: true,
        variable: [
            {
                variable: {
                    label: "",
                    value: ""
                },
                description: "",
                question: ""
            }
        ]
    });

    let [handoverSettings, setHandoverSettings] = useState({
        isEnable: true,
        criteria: [
            {
                criteria: "",
                topic: {
                    value: "",
                    label: ""
                }
            }
        ]
    });

    let [versionDescription, setVersionDescription] = useState("");

    let doMount = async () => {
        const paramID = checkID(match);

        if (paramID) {
            setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    history.push(`/o/${urlOrgID}/organization/members`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    useEffect(() => {
        doMount();
        getTopics();
    }, [location.pathname]);

    useEffect(() => {
        if (props.history.location.state) {
            if (props.history.location.state.tab) {
                setActiveTab(props.history.location.state.tab);
            }
        }
    }, [props.history]);

    const getTopics = () => {
        topicServices.getTopic(props.match.params.orgID, "list", null, (response) => {
            if (response.dataResult.error.message === "") {
                const data = response.dataResult.data.topics;
                const createList = () => {
                    let newData = [];
                    data.map(val => {
                        let _data = {};
                        _data.value = val.id;
                        _data.label = val.name;
                        newData.push(_data);
                        return null;
                    });

                    setTopicList(newData.slice());
                };

                createList();
            }
        });
    }

    const printFormStep = () => {
        return (
            <div className="chat-gpt-form-step">
                {Object.keys(TABS_LIST).map((value, index) => {
                    return (
                        <React.Fragment key={`step-${index}`}>
                            <div className={`chat-gpt-form-step-content ${index === activeTab ? "active-chat-gpt-form-step" : ""}`}>
                                {index < activeTab ?
                                    <div className={`value-number value-check`}>
                                        <FiCheck />
                                    </div>
                                    :
                                    <div className={`value-number ${index !== activeTab ? "not-active-value-wrapper" : ""}`}>
                                        <b>{index + 1}</b>
                                    </div>
                                }

                                <b>{TABS_LIST[value].tab}</b>
                            </div>

                            {index < (Object.keys(TABS_LIST).length - 1) && <div className="line-separator" />}
                        </React.Fragment>
                    )
                })}
            </div>
        )
    }

    const backToChatbotDetails = () => {
        props.history.push(`/o/${props.match.params.orgID}/integration/chatbots/detail/${props.match.params.id}`)
    }

    const toggleModalLoading = () => {
        setIsLoadingSave(!isLoadingSave);
    }

    const savePromptSettings = () => {
        setIsLoadingSave(true);
        let supportedLanguageCodes = [];
        if (promptSettings.supportedLanguages && promptSettings.supportedLanguages.length > 0) {
            promptSettings.supportedLanguages.map((language) => {
                supportedLanguageCodes.push(language.code);
            });
        }
        let data = {
            "chatbotID": Number(props.match.params.id),
            "modelCode": promptSettings.model.code,
            "toneCode": promptSettings.tone.code,
            "defaultLanguage": promptSettings.language.code,
            "supportedLanguages": supportedLanguageCodes,
            "greetings": promptSettings.greetings,
            "systemPrompt": promptSettings.systemPrompt,
        };
        ChatbotServices.saveChatGptPromptSettings(props.match.params.orgID, data, (response) => {
            let dataResult = response.dataResult;
            if (dataResult.error.message === "") {
                setActiveTab(2);
            }
            else {
                doToast(`Failed to saved prompt settings: ${dataResult.error.message}`, 'fail');
            }
            setIsLoadingSave(false);
        });
    }

    const TABS_LIST = {
        knowledgeBase: {
            id: "knowledgeBase",
            tab: "Knowledge Base",
            component: 
                <IntegrationChatGPTSetupKnowledgeBase 
                    {...props} 
                    setActiveTab={setActiveTab} 
                    knowledgeBase={knowledgeBase}
                    setKnowledgeBase={setKnowledgeBase}
                />
        },
        prompSetting: {
            id: "prompSetting",
            tab: "Prompt Settings",
            component: 
                <IntegrationChatGPTSetupPromptSetting
                    {...props}
                    setActiveTab={setActiveTab} 
                    promptSettings={promptSettings}
                    setPromptSettings={setPromptSettings}
                    isLoadingSave={isLoadingSave}
                    savePromptSettings={savePromptSettings}
                />
        },
        // leadsGeneration: {
        //     id: "leadsGeneration",
        //     tab: "Leads Generation",
        //     component: 
        //         <IntegrationChatGPTSetupLeadGeneration 
        //             {...props} 
        //             setActiveTab={setActiveTab} 
        //             leadsGenerationSettings={leadsGenerationSettings}
        //             setLeadsGenerationSettings={setLeadsGenerationSettings}
        //         />
        // },
        // handoverSettings: {
        //     id: "handoverSettings",
        //     tab: "Handover Settings",
        //     component: 
        //         <IntegrationChatGPTSetupHandoverSettings 
        //             {...props} 
        //             setActiveTab={setActiveTab} 
        //             topicList={topicList}
        //             setTopicList={setTopicList}
        //             getTopics={getTopics}
        //             handoverSettings={handoverSettings}
        //             setHandoverSettings={setHandoverSettings}
        //         />
        // },
        submission: {
            id: "submission",
            tab: "Submission",
            component: 
                <IntegrationChatGPTSetupSubmission 
                    {...props} 
                    setActiveTab={setActiveTab} 
                    versionDescription={versionDescription}
                    setVersionDescription={setVersionDescription}
                    backToChatbotDetails={backToChatbotDetails}
                />
        },
    }

    return (
        <>
            <SectionWrap
                {...props}
                orgID={orgID}
                withHeader
                withSideMenu
            >
                <BackButton
                    onClick={backToChatbotDetails}
                    text={"Back to Chatbot Settings"}
                />
                <div className="chatbot-setup-page-wrapper">
                    <>
                        {printFormStep()}

                        {TABS_LIST[Object.keys(TABS_LIST)[activeTab]].component}
                    </>
                </div>
            </SectionWrap>

            <PopupLoading
                isOpen={isLoadingSave}
                toggle={toggleModalLoading}
                title="Saving..."
            />
        </>
    )
}

const mapStateToProps = state => ({
    allowedModule: state.allowedModule
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationChatGPTSetup)
