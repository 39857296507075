import React from 'react';
import './OverviewUTM.scss';
import { NavLink } from 'react-router-dom';
import { FiArrowUpRight } from 'react-icons/fi';
import HelperDate from '../../../../helper/HelperDate';

const OverviewUTM = (props) => {
    let { fetchUTM, referrerUTMReport, orgID, dateVal } = props;

    let generalBoxTable = (title, key, url) => {
        return (
            !fetchUTM ?
                <div className='overview-utm-table-wrapper'>
                    <p className='overview-utm-table-title'>
                        <b>{title}</b>

                        <NavLink to={`/o/${orgID}/${url}`}>
                            <FiArrowUpRight />
                            <b>View More</b>
                        </NavLink>
                    </p>

                    <p className='of-cases'>
                        <b># of Cases</b>
                    </p>

                    {referrerUTMReport[key].length === 0 ?
                        <p className='no-overview-data'>
                            No data was found.
                    </p>
                        :
                        (referrerUTMReport[key].map((v, i) => {
                            return (
                                <p className='utm-val' key={`utm-key-${i}`}>
                                    {v.value === "" ? <span className='grey-font'>(not set)</span> : v.value}

                                    <span className='utm-total'>{v.totalCases}</span>
                                </p>
                            )
                        }))
                    }
                </div>
                :
                <div className='overview-utm-table-wrapper'>
                    <p className='overview-utm-table-title'>
                        <b>{title}</b>

                        <p className="view-more grey-font">
                            <FiArrowUpRight />
                            <b>View More</b>
                        </p>
                    </p>

                    <p className='of-cases'>
                        <b># of Cases</b>
                    </p>

                    <p className='utm-val onetalk-shine-1'>
                        <div className="onetalk-shine onetalk-shine-left" />

                        <div className="onetalk-shine onetalk-shine-right" />
                    </p>

                    <p className='utm-val onetalk-shine-2'>
                        <div className="onetalk-shine onetalk-shine-left" />

                        <div className="onetalk-shine onetalk-shine-right" />
                    </p>

                    <p className='utm-val onetalk-shine-3'>
                        <div className="onetalk-shine onetalk-shine-left" />

                        <div className="onetalk-shine onetalk-shine-right" />
                    </p>

                    <p className='utm-val onetalk-shine-4'>
                        <div className="onetalk-shine onetalk-shine-left" />

                        <div className="onetalk-shine onetalk-shine-right" />
                    </p>

                    <p className='utm-val onetalk-shine-5'>
                        <div className="onetalk-shine onetalk-shine-left" />

                        <div className="onetalk-shine onetalk-shine-right" />
                    </p>

                    <p className='utm-val onetalk-shine-6'>
                        <div className="onetalk-shine onetalk-shine-left" />

                        <div className="onetalk-shine onetalk-shine-right" />
                    </p>
                </div>
        )
    }

    return (
        <div className="overview-box-wrapper overview-utm-report-wrapper">
            <p className="overview-box-title">
                <b>Referrer & UTM Report</b>
            </p>

            <div className='overview-utm-value-wrapper'>
                {generalBoxTable("From URL", "referrers", `report/referrer-utm?start_date=${HelperDate.formatToString(new Date(dateVal.from), "yyyy-MM-dd")}&end_date=${HelperDate.formatToString(new Date(dateVal.to), "yyyy-MM-dd")}`)}
                {generalBoxTable("UTM Source", "utmSources", `report/referrer-utm?start_date=${HelperDate.formatToString(new Date(dateVal.from), "yyyy-MM-dd")}&end_date=${HelperDate.formatToString(new Date(dateVal.to), "yyyy-MM-dd")}&tab=utm&group_by=source`)}
                {generalBoxTable("UTM Medium", "utmMediums", `report/referrer-utm?start_date=${HelperDate.formatToString(new Date(dateVal.from), "yyyy-MM-dd")}&end_date=${HelperDate.formatToString(new Date(dateVal.to), "yyyy-MM-dd")}&tab=utm&group_by=medium`)}
                {generalBoxTable("UTM Campaign", "utmCampaigns", `report/referrer-utm?start_date=${HelperDate.formatToString(new Date(dateVal.from), "yyyy-MM-dd")}&end_date=${HelperDate.formatToString(new Date(dateVal.to), "yyyy-MM-dd")}&tab=utm&group_by=campaign`)}
                {generalBoxTable("UTM Term", "utmTerms", `report/referrer-utm?start_date=${HelperDate.formatToString(new Date(dateVal.from), "yyyy-MM-dd")}&end_date=${HelperDate.formatToString(new Date(dateVal.to), "yyyy-MM-dd")}&tab=utm&group_by=term`)}
                {generalBoxTable("UTM Content", "utmContents", `report/referrer-utm?start_date=${HelperDate.formatToString(new Date(dateVal.from), "yyyy-MM-dd")}&end_date=${HelperDate.formatToString(new Date(dateVal.to), "yyyy-MM-dd")}&tab=utm&group_by=content`)}
            </div>
        </div>
    );
};

export default OverviewUTM;
