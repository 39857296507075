import React, { useState, useEffect } from 'react';
import './ChatRoomMessageInFile.scss';
// import { FaReply } from 'react-icons/fa';
import { MdInsertDriveFile } from 'react-icons/md';
// import { MdClose } from 'react-icons/md';
import { FiDownload } from 'react-icons/fi';
import Helper from '../../../../helper/HelperChat';
import { printLastMessageName } from '../../../../helper/HelperGeneral';
import { taptalk, tapCoreChatRoomManager } from '@taptalk.io/web-sdk';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import iconAgent from '../../../../assets/img/chatroom/icon-agent.svg';
import iconChatbot from '../../../../assets/img/chatroom/icon-chatbot.svg';
import iconReplaceAvatar from "../../../../assets/img/replace-avatar.svg";
import ChatRoomReplyInMessage from "../chatRoomReplyInMessage/chatRoomReplyInMessage/ChatRoomReplyInMessage";
import MessageAction from '../../../reuseableComponent/messageAction/MessageAction';
import { connect } from 'react-redux';

const ROLE_LIST = {
    agent: {
        value: "agent",
        label: "Agent"
    },
    organization: {
        value: "organization",
        label: "Organization"
    },
    chatbot: {
        value: "chatbot",
        label: "Chatbot"
    }
}

var ChatRoomMessageInFile = (props) => {
    let {
        singleChatDataProps,
        activeUserID,
        onReplyMessage,
        onForwardMessage,
        caseData
        // activeRoom
    } = props;

    let [percentageDownload, setPercentageDownload] = useState(0);
    let [bytesDownload, setBytesDownload] = useState(0);
    let [fileSrc, setFileSrc] = useState('');
    let [isFileExistInDB, setIsFileExistInDB] = useState(false);
    let [onFileDownloadProgress, setOnFileDownloadProgress] = useState(false);
    let [fileExtension, setFileExtension] = useState("");
    let [fileFromUrl, setFileFromUrl] = useState(false);

    useEffect(() => {
        let splitFileName = singleChatDataProps.data.fileName ? singleChatDataProps.data.fileName.split('.') : singleChatDataProps.body.split('.');
        let isUnmounted = false;

        if (singleChatDataProps.data.url) {
            setFileFromUrl(true);
            setFileSrc(singleChatDataProps.data.url);
        } else if (singleChatDataProps.data.fileURL) {
            if (singleChatDataProps.data.fileURL !== "") {
                setFileFromUrl(true);
                setFileSrc(singleChatDataProps.data.fileURL);
            } else {
                setFileFromUrl(false);

                if (singleChatDataProps.data.fileID) {
                    tapCoreChatRoomManager.getFileFromDB(singleChatDataProps.data.fileID, function (data) {
                        if (data) {
                            if (!isUnmounted) {
                                setFileSrc(`data:${data.type};base64,${data.file}`);
                                setIsFileExistInDB(true);
                            }

                        } else {
                            if (!isUnmounted) {
                                setIsFileExistInDB(false);
                            }
                        }
                    })
                }
            }
        }

        if (!isUnmounted) {
            setFileExtension(splitFileName[splitFileName.length - 1].toUpperCase());
        }

        return () => {
            isUnmounted = true;
        }

    }, [singleChatDataProps])

    let downloadFile = () => {
        setOnFileDownloadProgress(true);

        tapCoreChatRoomManager.downloadMessageFile(singleChatDataProps, {
            onSuccess: (data) => {
                setOnFileDownloadProgress(false);
                setFileSrc(`data:${data.contentType};base64,${data.base64}`);
                setIsFileExistInDB(true);
            },

            onProgress: (message, percentage, bytes) => {
                setPercentageDownload(percentage);
                setBytesDownload(Helper.bytesToSize(bytes));
            },

            onError: (errorCode, errorMessage) => {
                setOnFileDownloadProgress(false);
                console.log(errorCode, errorMessage);
            }
        })
    }

    // let getFileBase64 = () => {
    //     tapCoreChatRoomManager.getFileFromDB(singleChatDataProps.data.fileID, function(data) {
    //         if(data) {
    //             setFileSrc(`data:${data.type};base64,${data.file}`);
    //             setIsFileExistInDB(true);
    //         }else {
    //             setIsFileExistInDB(false);
    //         }
    //     })
    // }

    // let messageActionView = (message) => {
    //     let onClickReply = () => {
    //         onReplyMessage(message)
    //     }

    //     return (
    //         <div className={`message-action-wrapper message-action-right with-forward`}>
    //             <div className="message-action-button-wrapper reply-button" title="Reply" onClick={() => onClickReply()}>
    //                 <FaReply />
    //             </div>

    //             <div className="message-action-button-wrapper reply-button forward-button" title="Forward" onClick={() => onForwardMessage(message)}>
    //                 <FaReply />
    //             </div>
    //         </div>
    //     )
    // }

    // let downloadFileToStorage = (file, fileName) => {
    //     fetch(file)
    //         .then(resp => resp.blob())
    //         .then(blob => {
    //             const url = window.URL.createObjectURL(blob);
    //             const a = document.createElement('a');
    //             const fileID = `file-${new Date().valueOf()}`;

    //             a.style.display = 'none';
    //             a.href = url;
    //             a.id = fileID;

    //             // the filename you want
    //             a.download = fileName;

    //             document.body.appendChild(a);
    //             a.click();
    //             window.URL.revokeObjectURL(url);
    //             a.remove();
    //         });
    // }

    let clickBubble = () => {
        if (isFileExistInDB || fileFromUrl) {
            window.open(fileSrc, "_blank")
        }
    }

    return (
        <div className="chat-room-message-file-in-wrapper" id={`message-${singleChatDataProps.localID}`}>
            {/* {singleChatDataProps.room.type === 2 && */}
            <div className="group-sender-avatar-wrapper"
                style={{ background: taptalk.getRandomColor(printLastMessageName(singleChatDataProps, caseData, true, true, props.inboxConfigsRedux)) }}
            >
                {singleChatDataProps.user.imageURL.thumbnail !== "" ?
                    <img src={singleChatDataProps.user.imageURL.thumbnail} alt="" onError={(e) => { e.target.onerror = null; e.target.src = iconReplaceAvatar; }} />
                    :
                    <b>{Helper.renderUserAvatarWord(printLastMessageName(singleChatDataProps, caseData, true, true, props.inboxConfigsRedux))}</b>
                }

                {(singleChatDataProps.user.userRole.code === ROLE_LIST.agent.value ||
                    singleChatDataProps.user.userRole.code === ROLE_LIST.organization.value
                ) &&
                    <img src={iconAgent} alt="" className="avatar-icon-badge" />
                }

                {(singleChatDataProps.user.userRole.code === ROLE_LIST.chatbot.value) &&
                    <img src={iconChatbot} alt="" className="avatar-icon-badge" />
                }
            </div>
            {/* } */}

            <div className="message-in-bubble-file">
                <div className="click-area-file" onClick={clickBubble}>
                    {/* <div className="replied-file-message">
                        <div className="file-icon-wrapper">
                            <MdInsertDriveFile />
                        </div>

                        <div className="file-detail-wrapper">
                            <div className="filename-wrapper">
                                <b>file.docx</b>
                            </div>
                            285 KB
                        </div>
                    </div> */}

                    {/* {props.forwarded &&
                        <div className="forwarded-message">
                            <b>Forwarded</b>
                            <br />
                            From: <b>Keju Manis</b>
                        </div>
                    } */}

                    {/* {singleChatDataProps.replyTo.localID !== "" &&
                        <div className="reply-message reply-file">
                            {singleChatDataProps.quote.fileType !== "" &&  
                                <div className="reply-file-thumbnail">
                                    {singleChatDataProps.quote.fileType === "file" ? 
                                        <MdInsertDriveFile />
                                        :
                                        <img src={''} alt="" />
                                    }
                                </div>
                            }

                            <div className="reply-text-wrapper">
                                <p className="reply-from">
                                    <b> 
                                        {singleChatDataProps.replyTo.userID === taptalk.getTaptalkActiveUser().userID ?
                                            "You"
                                            :
                                            singleChatDataProps.replyTo.fullname
                                        }
                                    </b>
                                </p>
                                <p className="reply-text">{singleChatDataProps.quote.content}</p>
                            </div>
                        </div>
                    } */}

                    <p className="group-sender-name-wrapper">
                        <b>
                            {singleChatDataProps.user.userRole.code === ROLE_LIST.agent.value &&
                                <span className="agent-span">{ROLE_LIST.agent.label} - </span>
                            }

                            {singleChatDataProps.user.userRole.code === ROLE_LIST.organization.value &&
                                <span className="agent-span">{ROLE_LIST.organization.label} - </span>
                            }

                            {singleChatDataProps.user.userRole.code === ROLE_LIST.chatbot.value &&
                                <span className="agent-span">{ROLE_LIST.chatbot.label} - </span>
                            }

                            {printLastMessageName(singleChatDataProps, caseData, true, false, props.inboxConfigsRedux)}
                        </b>
                    </p>

                    {singleChatDataProps.replyTo.localID !== "" &&
                        <ChatRoomReplyInMessage
                            message={singleChatDataProps}
                            activeUserID={activeUserID}
                        />
                    }

                    <div className="file-inner-wrapper">
                        <div className="file-icon-wrapper">
                            {/* {props.status === 'onprogress' ?
                                <div className="onprocess-wrapper">
                                    <MdClose />
                                </div>

                                :

                                props.status === 'success' ?
                                    <MdInsertDriveFile />
                                    :
                                    <MdFileDownload />
                            } */}
                            {(!isFileExistInDB && !onFileDownloadProgress && !fileFromUrl) &&
                                <div className="icon-status-wrapper">
                                    <FiDownload onClick={() => downloadFile()} />
                                </div>
                            }

                            {onFileDownloadProgress &&
                                <div className="icon-status-wrapper">
                                    <div className="onprocess-wrapper">
                                        <CircularProgressbar value={percentageDownload} />
                                        {/* <MdClose onClick={() => console.log('cancel')} /> */}
                                        <FiDownload />
                                    </div>
                                </div>
                            }

                            {(isFileExistInDB || fileFromUrl) &&
                                // <a href={fileSrc} target="_blank" rel="noopener noreferrer" download={singleChatDataProps.data.fileName}>
                                // <MdInsertDriveFile onClick={() => downloadFileToStorage(fileFromUrl ? singleChatDataProps.data.url : fileSrc, singleChatDataProps.data.fileName ? singleChatDataProps.data.fileName : singleChatDataProps.body)} />
                                <a href={fileSrc} target="_blank" rel="noopener noreferrer">
                                    <MdInsertDriveFile />
                                </a>
                            }
                        </div>

                        <div className="message-bubble-file-wrapper">
                            <p>
                                <b>
                                    {singleChatDataProps.data.fileName ?
                                        singleChatDataProps.data.fileName.split('.').slice(0, -1).join('.')
                                        :
                                        singleChatDataProps.body.replace(fileExtension.toLowerCase(), '').replace('.', '')
                                    }
                                </b>
                            </p>
                            <div className={`file-size-and-extension ${onFileDownloadProgress ? 'file-size-and-extension-download-progress' : ''}`}>
                                {onFileDownloadProgress && `${bytesDownload} / `}{singleChatDataProps.data.size !== 0 && Helper.bytesToSize(singleChatDataProps.data.size)}{" " + fileExtension}
                            </div>
                        </div>
                    </div>

                    {(singleChatDataProps.data.caption && singleChatDataProps.data.caption !== '') &&
                        <p className="caption-text" dangerouslySetInnerHTML={{ __html: Helper.lineBreakChatRoom(singleChatDataProps.data.caption) }} />
                    }

                    <p className="message-info">
                        {Helper.getDateMonthYear(singleChatDataProps.created)} <span className="centered-dot" /> {Helper.getHourMinute(singleChatDataProps.created)}
                    </p>

                </div>

                {/* {messageActionView(singleChatDataProps)} */}
                <MessageAction
                    singleChatDataProps={singleChatDataProps}
                    _className="message-action-dropdown-left"
                    replyAction={() => onReplyMessage(singleChatDataProps)}
                    forwardAction={() => onForwardMessage(singleChatDataProps)}
                    hideCopy
                />
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    replyMessage: state.replyMessage,
    activeRoom: state.activeRoom,
    inboxConfigsRedux: state.inboxConfigsRedux
});

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomMessageInFile);
