import React, { useState, useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import './ChatRoomMessageOutImageInteractive.scss';
import { IoIosClose } from "react-icons/io";
import Helper from '../../../../helper/HelperChat';
import { printLastMessageName, isOtherAgent } from '../../../../helper/HelperGeneral';
import { setActiveInteractiveList } from '../../../../redux/actions/reduxActionActiveInteractiveList';
import { connect } from 'react-redux';
import { FiDownload, FiUpload, FiList } from 'react-icons/fi';
import CheckMarkDoubleWhite from '../../../../assets/img/chatroom/icon-double-check-white.svg';
import CheckMarkDoubleWhiteReal from '../../../../assets/img/chatroom/icon-double-check-white-real.svg';
import CheckMarkDoubleDark from '../../../../assets/img/chatroom/icon-double-check-dark.svg';
import CheckMarkDark from '../../../../assets/img/chatroom/icon-check-dark.svg';
import AirplaneDark from '../../../../assets/img/chatroom/icon-airplane-dark.svg';
import CheckMarkDoubleLight from '../../../../assets/img/chatroom/icon-double-check-light.svg';
import CheckMarkLight from '../../../../assets/img/chatroom/icon-check-light.svg';
import AirplaneLight from '../../../../assets/img/chatroom/icon-airplane-light.svg';
import { tapCoreChatRoomManager } from '@taptalk.io/web-sdk';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ChatRoomReplyOutMessage from "../chatRoomReplyOutMessage/chatRoomReplyOutMessage/ChatRoomReplyOutMessage";
import MessageAction from '../../../reuseableComponent/messageAction/MessageAction';

const ROLE_LIST = {
    agent: {
        value: "agent",
        label: "Agent"
    },
    organization: {
        value: "organization",
        label: "Organization"
    },
    chatbot: {
        value: "chatbot",
        label: "Chatbot"
    }
}

var ChatRoomMessageOutImageInteractive = (props) => {
    let [percentageDownload, setPercentageDownload] = useState(0);
    let [imageSrc, setImageSrc] = useState('');
    let [isImageExistInDB, setIsImageExistInDB] = useState(false);
    let [onDownloadImageProgress, setOnImageDownloadProgress] = useState(false);
    let [imageFromUrl, setImageFromUrl] = useState(false);
    let [isShowModalImage, setIsShowModalImage] = useState(false);
    let {
        singleChatDataProps,
        activeUserID,
        onReplyMessage,
        onForwardMessage,
        caseData
    } = props;

    useEffect(() => {
        let isUnmounted = false;
        let fetchFromDB = () => {
            if (singleChatDataProps.data.fileID) {
                tapCoreChatRoomManager.getFileFromDB(singleChatDataProps.data.fileID, function (data) {
                    if (data) {
                        if (!isUnmounted) {
                            setImageSrc(data.file);
                            setIsImageExistInDB(true);
                        }
                    } else {
                        if (!isUnmounted) {
                            setImageSrc(singleChatDataProps.data.thumbnail);
                            setIsImageExistInDB(false);
                        }
                    }
                })
            }
        }

        if (singleChatDataProps.data.url) {
            setImageFromUrl(true);
            setImageSrc(singleChatDataProps.data.url);
        } else if (singleChatDataProps.data.fileURL) {
            if (singleChatDataProps.data.fileURL !== "") {
                setImageFromUrl(true);
                setImageSrc(singleChatDataProps.data.fileURL);
            } else {
                setImageFromUrl(false);
                fetchFromDB();
            }
        } else {
            fetchFromDB();
        }

        return () => {
            isUnmounted = true;
        }
    }, [singleChatDataProps])

    let downloadFile = () => {
        setOnImageDownloadProgress(true);

        tapCoreChatRoomManager.downloadMessageFile(singleChatDataProps, {
            onSuccess: (data) => {
                setOnImageDownloadProgress(false);
                setImageSrc(data.base64);
                setIsImageExistInDB(true);
            },

            onProgress: (message, percentage, bytes) => {
                setPercentageDownload(percentage);
            },

            onError: (errorCode, errorMessage) => {
                setOnImageDownloadProgress(false);
                console.log(errorCode, errorMessage);
            }
        })
    }

    // let getImageBase64 = () => {
    //     tapCoreChatRoomManager.getFileFromDB(singleChatDataProps.data.fileID, function(data) {
    //         if(data) {
    //             setImageSrc(data.file);
    //             setIsImageExistInDB(true);
    //         }else {
    //             setImageSrc(singleChatDataProps.data.thumbnail);
    //             setIsImageExistInDB(false);
    //         }
    //     })
    // }

    // let deleteMessageAction = (message) => {
    //     let messageIDs = [];
    //     messageIDs.push(message.messageID);
    //     tapCoreMessageManager.markMessageAsDeleted(message.room.roomID, messageIDs, true);
    // }

    let toggleModalImage = () => {
        if (isImageExistInDB || imageFromUrl) {
            setIsShowModalImage(!isShowModalImage);
        }
    }

    let generateModalImage = () => {
        let zoomImage = (e) => {
            var zoomer = e.currentTarget;
            zoomer.style.backgroundSize = '200%';
            var offsetX = e.nativeEvent.offsetX;
            var offsetY = e.nativeEvent.offsetY;

            var x = offsetX / zoomer.offsetWidth * 100
            var y = offsetY / zoomer.offsetHeight * 100
            zoomer.style.backgroundPosition = x + '% ' + y + '%';
        }

        let zoomImageOut = (e) => {
            var zoomer = e.currentTarget;
            zoomer.style.backgroundSize = '0%';
        }

        return (
            <div>
                <Modal isOpen={isShowModalImage} className={'modal-image'} toggle={toggleModalImage}>
                    <ModalBody onClick={() => toggleModalImage()}>
                        <IoIosClose className="close-modal-image" onClick={() => toggleModalImage()} />
                        <div className="image-wrapper">
                            <figure
                                className="zoom"
                                style={{ "backgroundImage": `url(${imageSrc})` }}
                                onMouseMove={(e) => zoomImage(e, imageSrc)}
                                onMouseLeave={(e) => zoomImageOut(e)}
                            >
                                <img
                                    src={imageSrc}
                                    alt=""
                                    className="image-preview-val"
                                />
                            </figure>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    // let messageActionView = (message) => {
    //     let onClickReply = () => {
    //         onReplyMessage(message)
    //     }

    //     return (
    //         <div 
    //             className={`message-action-wrapper with-forward`}
    //         >
    //             {/* <div className="message-action-button-wrapper" title="Copy to clipboard" onClick={() => Helper.copyToClipBoard(message.body)}>
    //                 <FiCopy />
    //             </div> */}

    //             <div className="message-action-button-wrapper reply-button" title="Reply" onClick={onClickReply}>
    //                 <FaReply />
    //             </div>

    //             <div className="message-action-button-wrapper reply-button forward-button" title="Forward"  onClick={() => onForwardMessage(message)}>
    //                 <FaReply />
    //             </div>

    //             {/*<div className="message-action-button-wrapper forward-message" title="Forward">
    //                 <FaReply />
    //             </div> */}


    //             {/* <div className="message-action-button-wrapper" title="Delete" onClick={() => deleteMessageAction(message)}>
    //                 <FiTrash2 />
    //             </div> */}
    //         </div>
    //     )
    // }

    //   let renderChatStatus = (message) => {
    //     let messageStatus;

    //     if(message.isSending) {
    //         messageStatus = "sending";
    //     }

    //     if(!message.isSending && message.isDelivered) {
    //         messageStatus = "sent";
    //     }

    //     if(!message.isSending && message.isDelivered && !message.isRead) {
    //         messageStatus = "receive";
    //     }

    //     if(message.isRead) {
    //         messageStatus = "read";
    //     }

    //     if(!message.isDelivered) {
    //         messageStatus = "not delivered";
    //     }

    //     switch(messageStatus) {
    //         case "sending":
    //             return AirplaneDark;
    //         case "sent":
    //             return CheckMarkDark;
    //         case "receive":
    //             return CheckMarkDoubleDark;
    //         case "read":
    //             return CheckMarkDoubleWhite;
    //         case "not delivered":
    //             return CheckMarkDark;
    //     }
    //   }

    return (
        <div className="chat-room-message-image-interactive-out-wrapper" id={`message-${singleChatDataProps.localID}`}>
            <div className={`message-out-bubble-image ${isOtherAgent(singleChatDataProps.user.xcUserID.replace("agent:", ""), props.myAgentData) ? "message-bubble-blue" : ""} ${singleChatDataProps.user.userRole.code === "chatbot" || singleChatDataProps.user.userRole.code === "organization" ? "message-bubble-organization" : ""} ${props.status !== 'uploaded' ? 'not-sent-message-bubble' : ''}`}>
                {/* {singleChatDataProps.replyTo.localID !== "" &&
                <div className="reply-message reply-file">
                    {singleChatDataProps.quote.fileType !== "" &&  
                        <div className="reply-file-thumbnail">
                            {singleChatDataProps.quote.fileType === "file" ? 
                                <MdInsertDriveFile />
                                :
                                <img src={''} alt="" />
                            }
                        </div>
                    }

                    <div className="reply-text-wrapper">
                        <p className="reply-from">
                            <b> 
                                {singleChatDataProps.replyTo.userID === taptalk.getTaptalkActiveUser().userID ?
                                    "You"
                                    :
                                    singleChatDataProps.replyTo.fullname
                                }
                            </b>
                        </p>
                        <p className="reply-text">{singleChatDataProps.quote.content}</p>
                    </div>
                </div>
            } */}

                {/* ${singleChatDataProps.data.caption !== "" && singleChatDataProps.replyTo.localID !== "" ? "with-reply-caption" : ""}
            ${singleChatDataProps.data.caption === "" && singleChatDataProps.replyTo.localID !== "" ? "with-reply-no-caption" : ""}
            ${singleChatDataProps.data.caption === "" ? "bubble-wrapper-without-caption" : ""} */}
                <div className={`
                bubble-image-wrapper 
                ${singleChatDataProps.data.caption !== "" ? "bubble-wrapper-with-caption" : ""}
            `}>
                    {(isOtherAgent(singleChatDataProps.user.xcUserID.replace("agent:", ""), props.myAgentData) || (singleChatDataProps.user.userRole.code === "chatbot" || singleChatDataProps.user.userRole.code === "organization")) &&
                        <p className="group-sender-name-wrapper">
                            <b>
                                {singleChatDataProps.user.userRole.code === ROLE_LIST.agent.value &&
                                    <span className="agent-span">{ROLE_LIST.agent.label} - </span>
                                }

                                {singleChatDataProps.user.userRole.code === ROLE_LIST.organization.value &&
                                    <span className="agent-span">{ROLE_LIST.organization.label} - </span>
                                }

                                {singleChatDataProps.user.userRole.code === ROLE_LIST.chatbot.value &&
                                    <span className="agent-span">{ROLE_LIST.chatbot.label} - </span>
                                }

                                {printLastMessageName(singleChatDataProps, caseData, true)}
                            </b>
                        </p>
                    }

                    {singleChatDataProps.replyTo.localID !== "" &&
                        <ChatRoomReplyOutMessage
                            message={singleChatDataProps}
                            activeUserID={activeUserID}
                        />
                    }

                    {imageFromUrl ?
                        <React.Fragment>
                            <img src={imageSrc} className={`image-from-url`} alt="" onClick={() => { toggleModalImage() }} />
                        </React.Fragment>
                        :
                        <React.Fragment>
                            {isImageExistInDB ?
                                <React.Fragment>
                                    <img src={'data:image/png;base64, ' + imageSrc} alt="" className="main-image-message" onClick={() => { toggleModalImage() }} />
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <img src={'data:image/png;base64, ' + (imageSrc === "" && singleChatDataProps.data.thumbnail ? singleChatDataProps.data.thumbnail : imageSrc)} alt="" className="thumbnail-image-message" />
                                </React.Fragment>
                            }

                            {((!isImageExistInDB && !onDownloadImageProgress) && singleChatDataProps.percentageUpload === undefined && !imageFromUrl) &&
                                <div className="icon-status-wrapper">
                                    <FiDownload onClick={() => downloadFile()} />
                                </div>
                            }

                            {onDownloadImageProgress &&
                                <div className="icon-status-wrapper">
                                    <div className="onprocess-wrapper">
                                        <CircularProgressbar value={percentageDownload} />
                                        {/* <MdClose onClick={() => console.log('cancel download')} /> */}
                                        <FiDownload />
                                    </div>
                                </div>
                            }

                            {singleChatDataProps.bytesUpload !== undefined &&
                                <div className="icon-status-wrapper">
                                    <div className="onprocess-wrapper">
                                        <CircularProgressbar value={singleChatDataProps.percentageUpload} />
                                        {/* <MdClose onClick={() => console.log('cancel upload')} /> */}
                                        <FiUpload />
                                    </div>
                                </div>
                            }
                        </React.Fragment>
                    }
                </div>

                {/* <div className="dark-cover">
                {(!isImageExistInDB && !onDownloadImageProgress) &&
                    <div className="icon-status-wrapper">
                        <MdFileDownload onClick={() => downloadFile()} />
                    </div>
                }
                    
                {onDownloadImageProgress && 
                    <div className="icon-status-wrapper">
                        <div className="onprocess-wrapper">
                            <CircularProgressbar value={percentageDownload} />
                            <MdClose onClick={() => console.log('cancel')} />
                        </div>
                    </div>
                }
            </div> */}

                {(singleChatDataProps.data.whatsappbaInteractive.header && singleChatDataProps.data.whatsappbaInteractive.header.type === "text") &&
                    <>
                        <p className='header-text'>
                            <b dangerouslySetInnerHTML={{ __html: Helper.lineBreakChatRoom(singleChatDataProps.data.whatsappbaInteractive.header.text) }} />
                        </p>
                    </>
                }

                <p className='body-text' dangerouslySetInnerHTML={{ __html: Helper.lineBreakChatRoom(singleChatDataProps.data.whatsappbaInteractive.bodyText) }} />

                {singleChatDataProps.data.whatsappbaInteractive.footerText &&
                    <p className='footer-text' dangerouslySetInnerHTML={{ __html: Helper.lineBreakChatRoom(singleChatDataProps.data.whatsappbaInteractive.footerText) }} />
                }

                {(singleChatDataProps.data.whatsappbaInteractive.listAction) &&
                    <div className='button-text'>
                        <FiList />
                        <b>{singleChatDataProps.data.whatsappbaInteractive.listAction.buttonText}</b>
                    </div>
                }

                {(singleChatDataProps.data.whatsappbaInteractive.buttonAction && singleChatDataProps.data.whatsappbaInteractive.buttonAction.buttons) &&
                    <div className={`button-list-wrapper buttons-${singleChatDataProps.data.whatsappbaInteractive.buttonAction.buttons.length}`}>
                        {singleChatDataProps.data.whatsappbaInteractive.buttonAction.buttons.map((v, i) => {
                            return (
                                <div className={`button-text button-text-${i} no-hover`} key={`button-text-${i}`}>
                                    <b>{v.title}</b>
                                </div>
                            )
                        })}
                    </div>
                }

                {singleChatDataProps.data.caption === '' ?
                    <p className={`message-info message-info-dark`}>
                        {Helper.getDateMonthYear(singleChatDataProps.created)} <span className="centered-dot" /> {Helper.getHourMinute(singleChatDataProps.created)}

                        {(singleChatDataProps.isSending && !singleChatDataProps.isRead) &&
                            <img src={AirplaneLight} alt="" />
                        }

                        {(!singleChatDataProps.isSending && !singleChatDataProps.isDelivered && !singleChatDataProps.isRead && singleChatDataProps.data.caption === '') &&
                            <img src={CheckMarkLight} alt="" />
                        }

                        {(!singleChatDataProps.isSending && singleChatDataProps.isDelivered && !singleChatDataProps.isRead && singleChatDataProps.data.caption === '') &&
                            <img src={CheckMarkDoubleLight} alt="" />
                        }

                        {singleChatDataProps.isRead &&
                            <img src={CheckMarkDoubleWhiteReal} alt="" />
                        }
                    </p>
                    :
                    <p className={`message-info`}>
                        {Helper.getDateMonthYear(singleChatDataProps.created)} <span className="centered-dot" /> {Helper.getHourMinute(singleChatDataProps.created)}

                        {(singleChatDataProps.isSending && !singleChatDataProps.isRead) &&
                            <img src={AirplaneDark} alt="" />
                        }


                        {(!singleChatDataProps.isSending && !singleChatDataProps.isDelivered && !singleChatDataProps.isRead) &&
                            <img src={CheckMarkDark} alt="" />
                        }

                        {(!singleChatDataProps.isSending && singleChatDataProps.isDelivered && !singleChatDataProps.isRead) &&
                            <img src={CheckMarkDoubleDark} alt="" />
                        }

                        {singleChatDataProps.isRead &&
                            <img src={CheckMarkDoubleWhite} alt="" />
                        }
                    </p>
                }

                {/* {messageActionView(singleChatDataProps)} */}
                <MessageAction
                    singleChatDataProps={singleChatDataProps}
                    _className="message-action-dropdown-right"
                    replyAction={() => onReplyMessage(singleChatDataProps)}
                    forwardAction={() => onForwardMessage(singleChatDataProps)}
                    hideCopy
                />
            </div>

            {props.status === 'fail' &&
                <React.Fragment>
                    <br />
                    <b className="failed-sending-file-warning">Failed to send tap to retry</b>
                </React.Fragment>
            }

            {(isImageExistInDB || imageFromUrl) && generateModalImage()}
        </div>
    );
}

const mapStateToProps = state => ({
    activeInteractiveList: state.activeInteractiveList,
    myAgentData: state.myAgentData
});

const mapDispatchToProps = {
    setActiveInteractiveList
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomMessageOutImageInteractive);
