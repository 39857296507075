import BaseService from "../BaseService";
import { API_KEY } from "../../config";

const BroadcastMessageServices = {
  getBroadcastMessageList(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_BROADCAST_MESSAGE_LIST, data, headers, callback);  
  },

  getBroadcastChannelList(idOrTime, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_BROADCAST_CHANNEL_LIST, {}, headers, callback);  
  },

  getAllBroadcastChannelList(idOrTime, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_ALL_BROADCAST_CHANNEL_LIST, {}, headers, callback);  
  },

  getAllSmeWabaChannelList(idOrTime, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_ALL_SME_WABA_CHANNEL_LIST, {}, headers, callback);  
  },

  sendBroadcastMessage(idOrTime, data,callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.SEND_BROADCAST_MESSAGE, data, headers, callback);  
  },

  sendBroadcastMessageMulti(idOrTime, data,callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.SEND_BROADCAST_MESSAGE_MULTI, data, headers, callback);  
  },

  sendBroadcastMessageWaba(idOrTime, data,callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.SEND_BROADCAST_MESSAGE_WABA, data, headers, callback);  
  },

  sendBroadcastMessageWabaMulti(idOrTime, data,callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.SEND_BROADCAST_MESSAGE_WABA_MULTI, data, headers, callback);  
  },

  sendBroadcastMessageWabaCustomPerRecipient(idOrTime, data,callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.SEND_BROADCAST_MESSAGE_WABA_CUSTOM, data, headers, callback);  
  },

  getBroadcastDetails(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_BROADCAST_DETAILS, data, headers, callback);  
  },

  exportBroadcastDetail(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessTokenNoWebWorker(API.EXPORT_BROADCAST, data, headers, callback, 'blob');
  },

  getBroadcastScheduled(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_SCHEDULED, data, headers, callback);  
  },

  edittBroadcastScheduled(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.EDIT_SCHEDULED, data, headers, callback);  
  },

  deleteBroadcastScheduled(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.DELETE_SCHEDULED, data, headers, callback);  
  },
};

const API = {
  GET_BROADCAST_MESSAGE_LIST: "/organization/broadcast/get_list",
  GET_BROADCAST_CHANNEL_LIST: "/organization/channel/whatsapp/get_authenticated_list",
  GET_ALL_BROADCAST_CHANNEL_LIST: "/organization/channel/get_active_authenticated_list",
  GET_ALL_SME_WABA_CHANNEL_LIST: "/organization/channel/get_list_for_new_message",
  // SEND_BROADCAST_MESSAGE: "/organization/broadcast/send_whatsapp_chatapicom"
  // SEND_BROADCAST_MESSAGE: "/organization/broadcast/send_whatsapp",
  SEND_BROADCAST_MESSAGE: "/organization/broadcast/send_whatsapp_multiple",
  SEND_BROADCAST_MESSAGE_MULTI: "/organization/broadcast/send_whatsapp_multiple_targets",
  EXPORT_BROADCAST: "/organization/broadcast/export_details",
  SEND_BROADCAST_MESSAGE_WABA: "/organization/broadcast/send_whatsappba",
  SEND_BROADCAST_MESSAGE_WABA_CUSTOM: "/organization/broadcast/send_whatsappba_custom_per_recipient",
  SEND_BROADCAST_MESSAGE_WABA_MULTI: "/organization/broadcast/send_whatsappba_multiple_targets",
  GET_BROADCAST_DETAILS: "/organization/broadcast/get_details",

  GET_SCHEDULED: "/organization/broadcast/get_scheduled_list",
  EDIT_SCHEDULED: "/organization/broadcast/edit_scheduled",
  DELETE_SCHEDULED: "/organization/broadcast/delete_scheduled",
}

export default BroadcastMessageServices;
